import axios from "axios";

import url, { deviceUrl, usersUrl, soundUrl, soundsUrl } from "./url";
import { message } from "antd";

export const getToken = () => localStorage.getItem("admtoken");

const showLoading = () => {
  document.getElementById("actions-loading-style").style.display = "flex";
};

export const jsonToGetUrl = (body) => {
  let query = "?";
  let objectLength = Object.keys(body).length;
  let iterator = 0;
  for (let key in body) {
    iterator++;
    if (iterator == objectLength) {
      query += key + "=" + body[key];
    } else {
      query += key + "=" + body[key] + "&";
    }
  }

  return query;
};

let loadingTimeout = null;
let loadingTimeoutError = null;

const request = (
  method,
  url,
  params,
  cb,
  errorCallback,
  timeoutError,
  haveTimeout = true
) => {
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };

  params.token = getToken();

  let myAxios = axios.create();
  if (haveTimeout) {
    if (
      url === `${soundsUrl}download/youtube` ||
      url === `${soundUrl}?token=${getToken()}`
    ) {
      myAxios.defaults.timeout = 120000;
    } else {
      myAxios.defaults.timeout = 20000;
    }
  }

  myAxios[method](url, method === "delete" ? { data: params } : params, headers)
    .then((response) => {
      if (response.data.message === "Forbidden") {
        window.location.href = "#";
        localStorage.clear();
        window.document.location.reload();
        return;
      }
      clearTimeout(loadingTimeout);
      loadingTimeout = setTimeout(() => {
        document.getElementById("actions-loading-style").style.display = "none";
      }, 500);

      if (response.data.error === true) {
        message.error(response.data.message);

        if (response.data.message) {
          if (errorCallback) {
            errorCallback(response.data.message[0]?.msg || response.data.message);
          }
        }
      } else {
        if (cb) {
          if (response.data.message) {
            cb(response.data.message);
          } else {
            cb(response.data);
          }
        }
      }
    })

    .catch((error) => {
      message.error(error.message);

      if (errorCallback) {
        errorCallback(error.message);
      }

      clearTimeout(loadingTimeoutError);
      loadingTimeoutError = setTimeout(() => {
        document.getElementById("actions-loading-style").style.display = "none";
      }, 1000);

      if (timeoutError) timeoutError(error.message);
    });
};

// ----- User -----

export const rGetUserCount = (cb) =>
  request("post", usersUrl + "counts", {}, (data) => cb(data));

export const rGetUserByTariff = (cb) =>
  request("post", usersUrl + "byTariff", {}, (data) => cb(data));

export const rSearchUsers = (body, cb) =>
  request("post", usersUrl + "search", body, (data) => cb(data));

// ----- ADMIN -----

export const requestGetDeviceCount = (cb) => request("post", deviceUrl + "count", {}, cb);

// ----- DOCUMENT -----

export const rValidateToken = (cb) =>
  request("post", `${url.admin}validate`, {}, (data) => cb(data));

// * * * * * * *  M E D I A S  * * * * * * *

export const requestGetMediasList = (body, callback, errorCallback) => {
  body.token = getToken();
  request("get", url.movie + jsonToGetUrl(body), {}, callback, errorCallback);
};

export const requestGetMediaById = (id, callback) => {
  request("get", `${url.movie}?token=${getToken()}&id=${id}`, {}, callback);
};

export const requestDeleteMediaFile = (id, callback) => {
  showLoading();
  request("delete", url.movie, { id }, callback);
};

// * * * * * * *  COLLECTIONS  * * * * * * *

const requests = {
  // a
  archive: {
    get: (callback) => {
      request("post", `${url.archive}get?append=all`, {}, callback);
    },

    add: (body, callback) => {
      showLoading();
      request("post", `${url.archive}add`, body, callback);
    },

    edit: (body, callback) => {
      showLoading();
      request("post", `${url.archive}update`, body, callback);
    },

    delete: (id, callback) => {
      showLoading();
      request("post", `${url.archive}delete`, { id }, callback);
    },

    server: {
      get: (callback) => {
        request("post", `${url.archive}get`, {}, callback);
      },
    },
  },

  // b
  // c
  chat: {
    conversation: {
      get: (callback, errorCallback) => {
        request(
          "get",
          `${
            url.conversation
          }?token=${getToken()}&query={"sort": ["last_message_date", "DESC"]}`,
          {},
          callback,
          errorCallback
        );
      },

      delete: (conversation_id, callback, errorCallback) => {
        request("delete", url.conversation, { conversation_id }, callback, errorCallback);
      },
    },

    quick_messages: {
      get: (callback, errorCallback) => {
        request(
          "get",
          `${url.quick_messages}?token=${getToken()}`,
          {},
          callback,
          errorCallback
        );
      },

      add: (body, callback, errorCallback) => {
        request("post", `${url.quick_messages}`, body, callback, errorCallback);
      },

      edit: (body, callback, errorCallback) => {
        request("put", `${url.quick_messages}`, body, callback, errorCallback);
      },

      delete: (id, callback) => {
        request("delete", `${url.quick_messages}`, { id }, callback);
      },
    },
  },

  countries: {
    get: (callback) => {
      request("get", `${url.admin}/country?token=${getToken()}`, {}, callback);
    },
  },

  //d

  // e

  // f
  // g
  // h
  // i

  // j
  // k

  // l
  languages: {
    get: (query, callback) => {
      request(
        "get",
        `${url.languages}${jsonToGetUrl(query)}&token=${getToken()}`,
        {},
        callback
      );
    },
  },

  // m
  monitoring: {
    get: (_url, ids, callback) => {
      let _ids = ids.join(",");

      request("get", `${_url}?id=${_ids}`, {}, callback);
    },
  },
  movie_country: (callback, errorCallback) => {
    request("get", `${url.movie_country}?token=${getToken()}`, callback, errorCallback);
  },
  // n
  // o
  // p
  payments: {
    monthly: (callback) => {
      request("post", `${url.payment}monthly`, {}, callback);
    },

    history: (body, callback, errorCallback) => {
      request("post", `${url.payment}payments`, body, callback, errorCallback);
    },
  },

  permission_menu: (callback, errorCallback) => {
    request("get", `${url.permission_menu}?token=${getToken()}`, callback, errorCallback);
  },
  // q
  // r

  // s

  statistics: {
    users_by_groups: (callback, errorCallback) => {
      request(
        "get",
        `${url.statistics}/users_by_groups?token=${getToken()}`,
        {},
        callback,
        errorCallback
      );
    },
    view_per_movie: (callback, errorCallback) => {
      request(
        "get",
        `${url.statistics}/views_per_movie?token=${getToken()}`,
        {},
        callback,
        errorCallback
      );
    },
    views_duration_movie: (callback, errorCallback) => {
      request(
        "get",
        `${url.statistics}/views_duration_movie?token=${getToken()}`,
        {},
        callback,
        errorCallback
      );
    },
    online_users_statistics: (callback, errorCallback) => {
      request(
        "get",
        `${url.statistics}/online_users_statistics?token=${getToken()}`,
        {},
        callback,
        errorCallback
      );
    },
    payments_total: (callback, errorCallback) => {
      request(
        "get",
        `${url.statistics}/payment/total?token=${getToken()}`,
        {},
        callback,
        errorCallback
      );
    },
    payments_count: (callback, errorCallback) => {
      //date
      request(
        "get",
        `${url.statistics}/payment/count?token=${getToken()}`,
        {},
        callback,
        errorCallback
      );
    },
    user_statistics: (callback, errorCallback) => {
      request(
        "get",
        `${url.statistics}/users_statistics?token=${getToken()}`,
        {},
        callback,
        errorCallback
      );
    },
    total: (callback, errorCallback) => {
      request(
        "get",
        `${url.statistics}/total?token=${getToken()}`,
        {},
        callback,
        errorCallback
      );
    },
    top_watched: (query, callback, errorCallback) => {
      request(
        "get",
        `${url.statistics}/top_watched?token=${getToken()}`,
        query,
        callback,
        errorCallback
      );
    },

    country: (callback, errorCallback) => {
      request(
        "get",
        `${url.statistics}/country?token=${getToken()}`,
        {},
        callback,
        errorCallback
      );
    },
    platform: (callback, errorCallback) => {
      request(
        "get",
        `${url.statistics}/platform?token=${getToken()}`,
        {},
        callback,
        errorCallback
      );
    },
  },

  // t

  // u
  // v

  vod: {
    statistics: {
      totals: (callback) => {
        request("get", `${url.movie_statistics}totals?token=${getToken()}`, {}, callback);
      },

      nowWatching: (callback) => {
        request(
          "get",
          `${url.movie_statistics}now_watching?token=${getToken()}`,
          {},
          callback
        );
      },

      mostWatchedMovie: (callback) => {
        request(
          "get",
          `${url.movie_statistics}most_watched_movie?token=${getToken()}`,
          {},
          callback
        );
      },

      mostWatchedList: (callback) => {
        request(
          "get",
          `${url.movie_statistics}most_watched_list?token=${getToken()}`,
          {},
          callback
        );
      },
    },

    cast: {
      add: (actor, callback) => {
        showLoading();

        let newActor = new FormData();

        newActor.append("name", actor.name);
        newActor.append("image", actor.image);
        newActor.append("bio", actor.bio);
        newActor.append("birthday", actor.birthday);
        newActor.append("gender", actor.gender.value);
        newActor.append("country", actor.country.value);
        newActor.append("role", actor.role);

        newActor.append("deathday", actor.deathday);

        request("post", `${url.cast}?token=${getToken()}`, newActor, callback);
      },

      edit: (actor, callback) => {
        showLoading();

        let updatedActor = new FormData();
        updatedActor.append("id", actor.id);
        updatedActor.append("name", actor.name);
        updatedActor.append("bio", actor.bio);
        updatedActor.append("birthday", actor.birthday);
        updatedActor.append("gender", actor.gender.value);
        updatedActor.append("country", actor.country.value);
        updatedActor.append("role", actor.role);

        updatedActor.append("deathday", actor.deathday);
        if (typeof actor.image === "object") updatedActor.append("image", actor.image);

        request("put", `${url.cast}?token=${getToken()}`, updatedActor, callback);
      },

      delete: (id, callback) => {
        showLoading();
        request("delete", url.cast, { id }, callback);
      },

      get: (body, callback) => {
        body.token = getToken();
        request("get", `${url.cast}${jsonToGetUrl(body)}`, body, callback);
      },

      galery: {
        add: (castId, galery, callback) => {
          showLoading();
          let newGalery = new FormData();
          newGalery.append("castId", castId);

          for (let i = 0; i < galery.length; i++) {
            newGalery.append("source", galery[i]);
          }

          request("post", `${url.cast}/galery?token=${getToken()}`, newGalery, callback);
        },

        delete: (id, callback) => {
          showLoading();
          request("delete", `${url.cast}/galery`, { id }, callback);
        },
      },

      fetcher: {
        state: (callback) => {
          request("get", `${url.cast_fetcher}/state?token=${getToken()}`, {}, callback);
        },

        fetch: (callback, errorCallback) => {
          request(
            "post",
            `${url.cast_fetcher}/fetch?token=${getToken()}`,
            { type: "tv_show" },
            callback,
            errorCallback
          );
        },
      },
    },

    media: {
      TARIFF: (id, callback) => {
        request(
          "get",
          `${url.movie}/tariff?movieId=${id}&token=${getToken()}`,
          {},
          callback
        );
      },

      getById: (id, callback) => {
        request("get", `${url.movie}?token=${getToken()}&id=${id}`, {}, callback);
      },

      cast: {
        add: (id, actors, callback) => {
          showLoading();
          let casts = new FormData();
          casts.append("id", id);
          casts.append("casts", JSON.stringify(actors));
          request("post", `${url.movie}/cast?token=${getToken()}`, casts, callback);
        },
      },

      addOrDeleteCast: (id, actors, callback) => {
        showLoading();
        let casts = new FormData();
        casts.append("id", id);
        casts.append("casts", JSON.stringify(actors));
        // request("put", `${url.movie}`, casts, callback);
      },

      galery: {
        add: (movieId, images, callback) => {
          showLoading();
          let galery = new FormData();
          galery.append("movieId", movieId);

          for (let i = 0; i < images.length; i++) {
            galery.append("source", images[i]);
          }

          request("post", `${url.movie}/galery?token=${getToken()}`, galery, callback);
        },

        delete: (id, callback) => {
          showLoading();
          request("delete", `${url.movie}/galery`, { id }, callback);
        },
      },

      soundtrack: {
        search: (name, callback, errorCallback) => {
          request(
            "get",
            `${url.soundtrack}youtube/search?token=${getToken()}&query=${name}`,
            {},
            callback,
            errorCallback
          );
        },

        download: (soundtrack, callback, errorCallback) => {
          showLoading();
          request(
            "post",
            `${url.soundtrack}download/youtube`,
            soundtrack,
            callback,
            errorCallback
          );
        },

        delete: (trackId, callback) => {
          showLoading();
          request("delete", `${url.sound}?token=${getToken()}`, { trackId }, callback);
        },
      },

      fetcher: {
        state: (callback) => {
          request("get", `${url.movie_fetcher}/state?token=${getToken()}`, {}, callback);
        },

        fetch: (callback, errorCallback) => {
          request(
            "post",
            `${url.movie_fetcher}/fetch?token=${getToken()}`,
            { type: "tv_show" },
            callback,
            errorCallback
          );
        },
      },

      transcode: {
        movie: (id, callback, errorCallback) => {
          request("post", url.transcode_movie, { id }, callback, errorCallback);
        },
      },

      edit: (formData, callback, errorCallback) => {
        showLoading();

        request(
          "put",
          `${url.movie}?token=${getToken()}`,
          formData,
          callback,
          errorCallback,
          null,
          false
        );
      },

      add: (formData, callback, errorCallback) => {
        showLoading();

        request(
          "post",
          `${url.movie}?token=${getToken()}`,
          formData,
          callback,
          errorCallback,
          null,
          false
        );
      },

      enable: (id, callback, errorCallback) => {
        request("put", `${url.movie}/enable`, { id }, callback, errorCallback);
      },

      disable: (id, callback, errorCallback) => {
        request("put", `${url.movie}/disable`, { id }, callback, errorCallback);
      },

      highlighted: (body, callback, errorCallback) => {
        request("post", `${url.movie}/highlighted`, body, callback, errorCallback);
      },
    },
  },
  // w

  // x
  // y
  // z
};

export default requests;
