import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    LANGUAGES: `${host}v2/admin/languages`,
};

const _languagesApi = {
    GET: (query) => request("GET", URL.LANGUAGES, query),

    ENABLE: (query) => request("PUT", `${URL.LANGUAGES}/enable`, query),

    DISABLE: (query) => request("PUT", `${URL.LANGUAGES}/disable`, query),
};

export default _languagesApi;
