import { useEffect, useState } from "react";

import {
  getColumnSearchProps,
  parseDate
} from "../../../../../../config/config";

import TableComponent from "../../../../../../_components/table/TableComponent";
import { Image, Space } from "antd";

import defaultImage from "../../../../../../img/movie.jpeg";

export default function FilteredMoviesTable({ getDataSource, filterData }) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState({
    name: null
  });

  const imageError = (e) => (e.target.src = defaultImage);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      }
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record, index) => {
        return (
          <Space>
            <Image
              src={record.poster || defaultImage}
              onError={imageError}
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                objectFit: "cover"
              }}
            />

            <p>{record.name}</p>
          </Space>
        );
      },

      ...getColumnSearchProps()
    }
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage
      };

      if (search.name) {
        query.search = {
          name: search.name[0]
        };
      }

      function onSuccess(data) {
        if (data.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }
        setLoading(false);
        setDataSource(data.rows);
        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, filterData]);

  return (
    <TableComponent
      style={{ marginTop: 20 }}
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total
      }}
    />
  );
}
