import { useEffect, useState } from "react";

import { Button, Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../api/requests";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import icons from "../../../../../config/icons";

export default function ScriptDrawer({ onClose, open, editable, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      name: values.name,
      script: values.script
    };

    if (editable) {
      body.id = editable.id;

      REQUESTS.SCRIPTS.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.SCRIPTS.ADD(body)
        .then((response) => {
          setIsLoading(false);
          if (response.error) {
            notification.error({
              description: response.message
            });
            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setIsLoading(false);
    }

    if (editable) {
      form.setFields([
        { name: "name", value: editable.name },
        { name: "script", value: editable.script }
      ]);
    }
  }, [open, editable]);

  return (
    <Drawer
      title={`${editable ? "Edit" : "Add"} script`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        name="scripts"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Script"
          name="script"
          rules={[
            {
              required: true,
              message: "Please input script"
            }
          ]}
        >
          <Input.TextArea rows={10} />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            type="primary"
            loading={isLoading}
            icon={icons.CHECK}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
