import { useEffect, useState } from "react";
import { Form, Input, Modal, message } from "antd";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

import REQUESTS from "../../../../api/requests";

export default function SetupAppsModal({ isOpen, onClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    REQUESTS.PROFILE.COMPANY.SETUP_APPS({
      name: values.name
    })
      .then((response) => {
        if (response.error) {
          message.error(response.message);
          setIsLoading(false);
        } else {
          onClose();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err.message);
      });
  };

  useEffect(() => {
    if (isOpen) {
    } else {
      setIsLoading(false);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Setup apps"
      open={isOpen}
      width={500}
      footer={false}
      maskClosable={true}
      closable={false}
      destroyOnClose={true}
      maskStyle={{
        backgroundColor: "#000000db"
      }}
    >
      <Form
        form={form}
        name="setup-apps"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name"
            }
          ]}
        >
          <Input placeholder="Please input name" />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <ButtonComponent
            isLoading={isLoading}
            title="Save"
            actionType="save"
            style={{ width: 100 }}
            onClick={() => form.submit()}
          />
        </div>
      </Form>
    </Modal>
  );
}
