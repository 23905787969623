import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"}admin/`;

const URL = {
    FAQ: `${host}faq`,
};

export default {
    GET: (query) => request("GET", URL.FAQ, query),

    ADD: (query) => request("POST", URL.FAQ, query),

    EDIT: (query) => request("PUT", URL.FAQ, query),

    DELETE: (query) => request("DELETE", URL.FAQ, query),
};
