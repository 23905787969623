import { Divider, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import REQUESTS from "../../../api/requests";

import PATHS from "../../../config/paths";

import TabsList from "./components/tabs/TabsList";
import Biography from "./components/biography/Biography";
import PageComponent from "../../../_components/page/PageComponent";

export default function UserPage() {
    const params = useParams();

    const [user, setUser] = useState(null);

    const getUser = () => {
        const id = params?.id;

        if (id) {
            REQUESTS.USERS.USERS.GET({
                query: JSON.stringify({
                    filter: {
                        id,
                    },
                }),
            })
                .then((response) => {
                    if (response?.message?.rows && response?.message?.rows[0]) {
                        setUser(response.message.rows[0]);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        getUser();
    }, []);
    return (
        <PageComponent routes={["Users", "List", "User"]} backPath={PATHS.USERS}>
            {user ? <Biography user={user} getUser={getUser} /> : <Skeleton active />}

            <Divider />

            <TabsList user={user} getUser={getUser} />
        </PageComponent>
    );
}
