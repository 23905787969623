import { useEffect, useState } from "react";

import { Form, Input, message } from "antd";

import * as actions from "../../redux-store/actions";

import REQUESTS from "../../api/requests";

import ImageUpload from "../../components/ImageUpload";

import ButtonComponent from "../../_components/ButtonComponent";

import styles from "./account_page.module.scss";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";

export default function ProfileTab({}) {
  const dispatch = useDispatch();

  const { setProfile } = bindActionCreators(actions, dispatch);

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [image, setImage] = useState({
    url: null,
    file: null
  });

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    REQUESTS.PROFILE.GET().then((response) => {
      if (response.error) {
        message.error(response.message);
        return;
      }

      const profile = response.message;

      setProfile(profile);
      form.setFields([
        {
          name: "name",
          value: profile.name
        },

        {
          name: "surname",
          value: profile.surname
        },

        {
          name: "email",
          value: profile.email
        },

        {
          name: "phone",
          value: profile.phone
        },

        {
          name: "password",
          value: ""
        }
      ]);

      setImage({
        url: profile.image,
        file: null
      });
    });
  };

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("surname", values.surname);
    formData.append("phone", values.phone);
    formData.append("email", values.email);

    if (values.password) {
      formData.append("password", values.password);
    }

    if (image.file) {
      formData.append("image", image.file);
    }

    REQUESTS.PROFILE.EDIT(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          message.error(response.message);

          return;
        }

        message.success("Profile updated successfully");

        getProfile();
      })
      .catch((err) => {
        message.error(err.message);
        setIsLoading(false);
      });
  };

  return (
    <Form form={form} name="profile" layout="vertical" onFinish={onFinish}>
      <div className={styles["profile-form"]}>
        <div className={styles["image-block"]}>
          <Form.Item name="image">
            <ImageUpload image={image} setImage={setImage} />
          </Form.Item>
        </div>
        <div className={styles["input-fields"]}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input name"
              }
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Surname"
            name="surname"
            rules={[
              {
                required: true,
                message: "Please input surname"
              }
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Phone" name="phone" style={{ marginBottom: 0 }}>
            <Input type="phone" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input email"
              }
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            style={{ marginBottom: 0 }}
          >
            <Input.Password />
          </Form.Item>

          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </div>
      </div>
    </Form>
  );
}
