import request from "../../api/request";
import { DEMO_API_HOST } from "../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    ADVERTISEMENT: `${host}admin/advertisement`,

    CONFIGS: `${host}admin/advertisement/settings`,
};

const adsApi = {
    GET: (query) => request("GET", URL.ADVERTISEMENT, query),

    ADD: (query) => request("POST", URL.ADVERTISEMENT, query),

    EDIT: (query) => request("PUT", URL.ADVERTISEMENT, query),

    DELETE: (query) => request("DELETE", URL.ADVERTISEMENT, query),

    CONFIGS: {
        GET: (query) => request("GET", URL.CONFIGS, query),

        EDIT: (query) => request("PUT", URL.CONFIGS, query),
    },
};

export default adsApi;
