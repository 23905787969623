import { useState, useEffect } from "react";

import { Form, Button, Input, notification, Divider } from "antd";

import REQUESTS from "../../../../api/requests";

import style from "../style.module.scss";
import icons from "../../../../config/icons";

export default function GeneralTab({ settings }) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState();

  const onFinish = (values) => {
    setIsLoading(true);

    const query = {
      email: values.email,
      phone_number: values.phone_number
    };

    REQUESTS.WEB_PAGE.SETTINGS.EDIT(query)
      .then((data) => {
        setIsLoading(false);

        if (data.error) {
          notification.error({
            message: "Error",
            description: data.message
          });

          return;
        }

        notification.success({
          message: "Success",
          description: data.message
        });
      })

      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (settings) {
      form.setFields([
        {
          name: "email",
          value: settings.email
        },

        {
          name: "phone_number",
          value: settings.phone_number
        }
      ]);
    }
  }, [settings]);

  return (
    <div className={style["tab"]}>
      <Divider orientation="left">General</Divider>

      <Form
        form={form}
        name="web-about-us"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>

        <Form.Item label="Phone number" name="phone_number">
          <Input type="phone" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            icon={icons.CHECK}
            style={{ width: "100%" }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
