import request from "../request";

const host = `${
  process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"
}admin/`;

const URL = {
  LOGIN: `${host}login`,

  REGISTER: `${host}register`,

  VALIDATE: `${host}validate`,

  PROFILE: `${host}profile`,

  NOTIFICATIONS: `${process.env.REACT_APP_API_HOST}v2/admin/admin_notifications`,

  PERMISSIONS: `${host}menu/permission`
};

const PROFILE = {
  LOGIN: (query) => request("POST", URL.LOGIN, query),

  REGISTER: (query) => request("POST", URL.REGISTER, query),

  VALIDATE: (query) => request("POST", URL.VALIDATE, query),

  GET: (query) => request("GET", URL.PROFILE, query),

  EDIT: (query) => request("PUT", URL.PROFILE, query),

  REMOVE: (query) => request("DELETE", URL.PROFILE, query),

  NOTIFICATIONS: {
    GET: (query) => request("GET", URL.NOTIFICATIONS, query),

    ENABLE: (id) => request("PUT", `${URL.NOTIFICATIONS}/enable`, { id }),

    DISABLE: (id) => request("PUT", `${URL.NOTIFICATIONS}/disable`, { id })
  },

  COMPANY: {
    GET: (query) => request("GET", `${host}company`, query),

    SETUP_APPS: (query) => request("POST", `${host}company/setup_apps`, query),

    EDIT_DOMAIN: (query) => request("PUT", `${host}company/edit_domain`, query)
  },

  PERMISSIONS: () => request("GET", URL.PERMISSIONS)
};

export default PROFILE;
