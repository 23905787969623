import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import requests from "../../server/requests";

export default function MessagesMenu() {
  const [count, setCount] = useState(0);

  const { isChangedNotReadMessagesCount } = useSelector(
    (state) => state.globalState
  );

  const getCount = () => {
    function callback(data) {
      let _count = 0;

      for (let i = 0; i < data.rows.length; i++) {
        _count += data.rows[i].not_read_count_admin;
      }

      setCount(_count);
    }

    function errorCallback(err) {
      console.log(err, "err");
    }

    requests.chat.conversation.get(callback, errorCallback);
  };

  useEffect(() => {
    getCount();
  }, [isChangedNotReadMessagesCount]);

  return (
    <div style={{ position: "relative" }}>
      <span>Chat</span>

      {count ? <div className="not-read-messages-count">{count}</div> : ""}
    </div>
  );
}
