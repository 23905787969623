import { Button } from "antd";
import icons from "../config/icons";
import REQUESTS from "../api/requests";
import { DEMO_API_HOST } from "../config/statics";

export default function GoogleButton({ title }) {
  const onClick = () => {
    const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

    const url = `${host}admin/auth/google`;

    window.open(`${url}`, "_self");
  };

  return (
    <Button
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        gap: 10,
        fontWeight: "bold"
      }}
      size="large"
      onClick={onClick}
    >
      {icons.GOOGLE}
      {title}
    </Button>
  );
}
