import { useNavigate } from "react-router";
import PageComponent from "../../_components/page/PageComponent";

import styles from "./welcome_page.module.scss";
import { Divider, Button, Space } from "antd";
import icons from "../../config/icons";
import { useSelector } from "react-redux";
import PATHS from "../../config/paths";

const steps = [
  {
    id: 1,
    title: "Setup subscription plans",
    buttonText: "Subscrip",
    link: PATHS.PAYMENT_SUBSCRIPTION
  },

  {
    id: 2,
    title: "Setup your branding",
    buttonText: "Branding",
    link: PATHS.APPLICATION_BASIC
  },

  {
    id: 3,
    title: "Upload your first video",
    buttonText: "Upload video",
    link: PATHS.NEW_VIDEO
  },

  {
    id: 4,
    title: "Contact us to get your apps",
    buttonText: "Contact us",
    link: PATHS.MORE_APPS
  }
];

export default function WelcomePage({}) {
  const navigate = useNavigate();

  const { profile } = useSelector((state) => state.globalState);

  const onClickScheduleCall = () => {
    window.open("https://calendly.com/armenhakobyan/streamifyer", "_blank");
  };

  return (
    <PageComponent routes={["Welcome"]}>
      <div className={styles["welcome-page"]}>
        <div className={styles["container"]}>
          <p className={styles["title"]}>
            Welceome {`${profile?.name} ${profile?.surname}`}
          </p>
          <p className={styles["description"]}>
            We are sharing with you our CMS panel with 100 of good functionality
            to manage your content and payments{" "}
          </p>

          <p>Follow this steps to setup your account</p>

          <div className={styles["steps-list"]}>
            {steps.map((step, index) => (
              <div
                key={step.id}
                className={styles["step"]}
                onClick={() => {
                  if (step.id === 3) {
                    const createVideoId =
                      document.getElementById("create-video-id");
                    if (createVideoId) {
                      createVideoId.click();
                    }
                  } else {
                    navigate(step.link);
                  }
                }}
              >
                <Space>
                  <span>{index + 1}.</span>
                  <p>{step.title}</p>
                </Space>
                <div className={styles["button"]}>{icons.ARROW_LEFT}</div>
              </div>
            ))}
          </div>

          <Divider style={{ color: "#1f2125" }}>or</Divider>

          <p>
            Schedule a call with our specialist it will take 30 minutes to learn
            and setup all
          </p>

          <Button
            icon={icons.PHONE}
            type="primary"
            size="large"
            style={{ marginTop: 20 }}
            onClick={onClickScheduleCall}
          >
            Schedule a call
          </Button>
        </div>
      </div>
    </PageComponent>
  );
}
