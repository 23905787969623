import PageComponent from "../../_components/page/PageComponent";

import { useState, useEffect } from "react";
import { Col, Row } from "antd";

import requests from "../../server/requests";

import TopMovies from "./components/TopMovies";
import PlatformAnalytics from "./components/PlatformsAnalytics";
import MapChart from "./components/MapChart";
import BestMovie from "./components/BestMovie";
import Revenue from "./components/Revenue";
import OnlineUser from "./components/OnlineUsers";
import UserStatistics from "./components/UserStatistics";
import VideoViews from "./components/VideoViews";
import CardChart from "./components/CardChart";

import customers_icon from "./assets/user.svg";
import revenue_icon from "./assets/money.svg";
import videos from "./assets/play.svg";
import VideoDurations from "./components/VideoDurations";
import UsersByGroups from "./components/UsersByGroup";

export default function AnalyticsPage({}) {
  var [revenue, setRevenue] = useState(null);
  var [videos_count, setVideosCount] = useState(null);
  var [customers_count, setCustomerCount] = useState(null);

  const getCardsData = () => {
    requests.statistics.total(
      (data) => {
        setRevenue(data.revenue);
        setVideosCount(data.videos);
        setCustomerCount(data.customers);
      },
      (err) => {
        setRevenue("N/A");
        setVideosCount("N/A");
        setCustomerCount("N/A");
      }
    );
  };

  useEffect(() => {
    getCardsData();
  }, []);

  return (
    <PageComponent routes={["Analytics"]}>
      <div>
        <Row className="cards-parent mb">
          <Col
            className={"analytics-block neo secondary mt"}
            order={1}
            md={{ span: 24 }}
            lg={{ span: 7, offset: 1 }}
          >
            <CardChart
              description={"Total videos you have uploaded to streamy platform"}
              icon={videos}
              title={"Total Videos"}
              value={videos_count}
            />
          </Col>
          <Col
            className={"analytics-block neo secondary"}
            order={2}
            md={{ span: 24 }}
            lg={{ span: 7, offset: 1 }}
          >
            <CardChart
              description={"Total customers how registered to watch your videos"}
              icon={customers_icon}
              title={"Customers"}
              value={customers_count}
            />
          </Col>
          <Col
            className={"analytics-block neo secondary"}
            order={3}
            md={{ span: 24 }}
            lg={{ span: 7, offset: 1 }}
          >
            <CardChart
              description={"Total earned revenue for all time"}
              icon={revenue_icon}
              title={"Revenue"}
              value={revenue}
            />
          </Col>
        </Row>

        <Row className="mb">
          <Col
            className={"analytics-block neo secondary"}
            order={1}
            xs={{ span: 11, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
          >
            <BestMovie />
          </Col>

          <Col
            className={"analytics-block neo secondary"}
            order={2}
            xs={{ span: 11, offset: 1 }}
            lg={{ span: 11, offset: 1 }}
          >
            <MapChart />
          </Col>
        </Row>

        <Row className="mb">
          <PlatformAnalytics />
        </Row>

        <Row className="mb">
          <Col className={"analytics-block neo secondary"} order={1} span={11} offset={1}>
            <OnlineUser />
          </Col>
          <Col className={"analytics-block neo secondary"} order={2} span={11} offset={1}>
            <UserStatistics />
          </Col>
        </Row>

        <Row className="mb">
          <Col className={"analytics-block neo secondary"} order={1} span={11} offset={1}>
            <Revenue />
          </Col>
          <Col className={"analytics-block neo secondary"} order={2} span={11} offset={1}>
            <UsersByGroups />
          </Col>
        </Row>

        <div className={"analytics-block neo secondary ml mb"}>
          <TopMovies />
        </div>

        <Row className="mb">
          <Col className={"analytics-block neo secondary"} order={1} span={11} offset={1}>
            <VideoViews />
          </Col>
          <Col className={"analytics-block neo secondary"} order={2} span={11} offset={1}>
            <VideoDurations />
          </Col>
        </Row>
      </div>
    </PageComponent>
  );
}
