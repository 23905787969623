import React from "react";
import { Layout, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import style from "./header.module.scss";
import Profile from "./components/profile/Profile";
import CreateVideo from "./components/create-video/CreateVideo";
import SetupAppStatus from "./components/setup-apps/SetupAppStatus";
import GoLive from "./components/go-live/GoLive";

export default function Header({ collapsed, setCollapsed }) {
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  return (
    <>
      <Layout.Header
        className={style["header"]}
        style={{
          width: `calc(100% - ${collapsed ? 100 : 200}px)`
        }}
      >
        <div className={style["container"]}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed)
            }
          )}

          <div className={style["block"]}>
            <SetupAppStatus />
            <GoLive />
            <CreateVideo />
            <Profile />
          </div>
        </div>
        <hr />
      </Layout.Header>
    </>
  );
}
