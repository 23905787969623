import { Line } from "@ant-design/plots";
import { useState, useEffect } from "react";

import requests from "../../../server/requests";
import ItemDescription from "./ItemDescription";

export default function OnlineUser() {
  let [onlineUser, setOnlineUsers] = useState({
    data: [],
    xField: "date",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  });

  const getOnlineUsers = () => {
    requests.statistics.online_users_statistics((data) => {
      console.log(data);
      onlineUser.data = data.data;
      setOnlineUsers(onlineUser);
    });
  };

  useEffect(() => {
    getOnlineUsers();
  }, []);

  return (
    <div>
      <ItemDescription
        title={"Online users"}
        content={
          "Under this section you can find your users activity across all the platforms"
        }
      />

      <hr />
      <Line style={{ width: "100%" }} {...onlineUser} />
    </div>
  );
}
