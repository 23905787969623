import { useEffect, useState } from "react";
import { Space, Switch, Tag, Tooltip } from "antd";

import icons from "../../../../config/icons";
import { getColumnSearchProps, getQueryDate } from "../../../../config/config";

import TableButtons from "../../../../_components/table/TableButtons";
import TableComponent from "../../../../_components/table/TableComponent";
import { useVodGenresOptions } from "../../../../hooks/selectOptions";
import VideoInfo from "../../../../_components/VideoInfo";
import { SyncOutlined } from "@ant-design/icons";

export default function MediasTable({
  getAgain,
  getDataSource,
  handleMenuClick,
  onEnabled,
  onHighlighted,
}) {
  const genresOptions = useVodGenresOptions("text");

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [date, setDate] = useState(null);

  const [search, setSearch] = useState({
    name: null,
    type: null,
  });

  const columns = [
    {
      width: 30,
      title: "#",
      key: "index",
      dataIndex: `id`,
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Enable",
      dataIndex: "enabled",
      align: "center",
      key: "enabled",
      width: 70,
      sorter: true,
      render: (text, record, index) => {
        return (
          <Switch
            size="small"
            checked={record.enabled}
            onChange={(e) => onEnabled(e, record.id)}
          />
        );
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "start",
      width: 180,

      render: (text, record, index) => {
        return (
          <VideoInfo
            name={record.name}
            image={record.poster}
            description={record.description}
          />
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: "Status",
      dataIndex: "transcoding_status",
      align: "center",
      key: "transcoding_status",
      width: 80,
      render: (text, record, index) => {
        if (
          (record.transcoding_status === 0 && record.transcoding_id) ||
          (record.trailer_transcoding_status === 0 && record.trailer_transcoding_id)
        ) {
          return (
            <Tag icon={<SyncOutlined spin />} color="processing">
              Whating
            </Tag>
          );
        }

        if (
          (record.transcoding_status === 1 && record.transcoding_id) ||
          (record.trailer_transcoding_status === 1 && record.trailer_transcoding_id)
        ) {
          return <Tag color="volcano">Error</Tag>;
        }

        if (
          record.transcoding_status === 2 &&
          record.transcoding_id &&
          record.trailer_transcoding_status === 2 &&
          record.trailer_transcoding_id
        ) {
          return <Tag color="green">Success</Tag>;
        }

        if (
          record.transcoding_status === 2 &&
          record.transcoding_id &&
          record.trailer_transcoding_id === null
        ) {
          return <Tag color="green">Success</Tag>;
        }
      },
    },

    {
      title: "Like",
      dataIndex: "like",
      key: "like",
      width: 50,
      sorter: true,

      render: (text, record, index) => {
        return (
          <Space>
            <div style={{ marginTop: 5 }}>{icons.LIKE}</div>
            {record.like}
          </Space>
        );
      },
    },

    {
      title: "Dislike",
      dataIndex: "dislike",
      key: "dislike",
      width: 50,
      sorter: true,

      render: (text, record, index) => {
        return (
          <Space>
            <div style={{ marginTop: 5 }}>{icons.DISLIKE}</div>
            {record.dislike}
          </Space>
        );
      },
    },
    {
      title: "Tags",
      dataIndex: "genres",
      key: "genres",
      align: "center",
      ellipsis: true,
      filters: genresOptions,
      filterMultiple: false,
      width: 150,
      render: (text, record, index) => {
        const genresNames = record.genres?.map((genre) => {
          return genre.name;
        });

        const list = genresNames.join(", ");

        return (
          <Tooltip placement="topLeft" title={list}>
            <span>{list}</span>
          </Tooltip>
        );
      },
    },

    {
      title: "Highlighted",
      dataIndex: "highlighted",
      align: "center",
      key: "highlighted",
      width: 60,
      sorter: true,
      render: (text, record, index) => {
        return (
          <Switch
            size="small"
            checked={record.highlighted}
            onChange={(e) => onHighlighted(e, record.id)}
          />
        );
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "gallery",
              name: "Gallery",
              icon: icons.GALLERY,
            },

            // {
            //     key: "soundtrack",
            //     name: "Soundtrack",
            //     icon: icons.SOUNDTRACKS,
            // },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this media?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);

    const videoTablePage = {
      limit: data.limit,
      page: data.page,
      sort: data.sort,
    };

    sessionStorage.setItem("video_table_page", JSON.stringify(videoTablePage));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const videoTablePage = JSON.parse(sessionStorage.getItem("video_table_page"));

      const query = {
        sort: videoTablePage ? videoTablePage.sort : sort,
        limit: videoTablePage ? videoTablePage.limit : limit,
        page: videoTablePage ? videoTablePage.page : currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name[0],
        };
      }

      if (search.type) {
        query.filter = {
          type: search.type[0],
        };
      }

      if (search.genres) {
        query.filter = {
          genre_id: search.genres[0],
        };
      }

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      function onSuccess(data) {
        if (data.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);
        setDataSource(data.rows);
        setTotal(data?.count);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date, getAgain]);

  return (
    <TableComponent
      // header={
      //   <Space>
      //     <ButtonComponent
      //       icon={icons.ADD}
      //       title="Add Video"
      //       onClick={addNewVideo}
      //     />
      //   </Space>
      // }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      rowClassName={(row) => {
        if (row.archive) return "red-row";
      }}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 1200,
      }}
    />
  );
}
