import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"}`;

const URL = {
    HISTORY: `${host}payment/admin/history`,
};

export default {
    GET: (query) => request("GET", URL.HISTORY, query),
};
