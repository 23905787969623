export default function createSubscriptionData(
  values,
  vodService,
  editable,
  force,
  permissions
) {
  const body = {
    name: values.name,
    description: values.description,
    price: values.price,
    device_service: values.device_service,

    scheduling_time: values.scheduling_time,
    scheduling_type: values.scheduling_type,
    payment_type: values.payment_type,
    vod_service: JSON.stringify(vodService)
  };

  if (permissions?.payments?.paypal) {
    body.paypal_plan_id = values.paypal_plan_id;
  }

  if (permissions?.payments?.stripe) {
    body.stripe_plan_id = values.stripe_plan_id;
  }

  if (permissions?.payments?.apple) {
    body.apple_subscription_id = values.apple_subscription_id;
  }

  if (permissions?.payments?.google) {
    body.google_subscription_id = values.google_subscription_id;
  }

  if (permissions?.payments?.authorize) {
    body.is_supported_authorize = values.is_supported_authorize;
  }

  if (force) {
    vodService.force = true;
  }

  if (editable) {
    body.id = editable.id;
  }

  return body;
}
