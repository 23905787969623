import { Form, Input } from "antd";

const UrlImport = ({ onChange }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} layout="vertical" onChange={() => onChange(form.getFieldsValue())}>
      <Form.Item label="URL" name="url">
        <Input placeholder="Enter URL" />
      </Form.Item>
    </Form>
  );
};

export default UrlImport;
