import { Modal } from "antd";
import ButtonComponent from "../../../../_components/button/ButtonComponent";

const ModalComponent = ({ children, open, close, title, finish }) => {
  return (
    <Modal
      open={open}
      onCancel={close}
      title={title}
      footer={[
        <ButtonComponent key="cancel" type="default" title={"Cancel"} onClick={close} />,

        finish && (
          <ButtonComponent
            key="submit"
            type="primary"
            title={"Ok"}
            onClick={() => finish()}
          />
        ),
      ]}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;
