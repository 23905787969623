export default function createFormValues({ values, editable }) {
  const body = {
    type: values.type
  };

  if (values.type === "text") {
    body.repeat_counts = values.repeat_counts;
    body.url = "";
  } else {
    body.url = values.url;
    body.tex = "";
  }

  if (values.tariffs_id) {
    body.tariffs_id = values.tariffs_id.join(",");
  }

  body.all_movies = values.all_movies;

  if (values.movies_id && !values.all_movies) {
    body.movies_id = values.movies_id.join(",");
  }

  if (editable) {
    body.id = editable.id;
  }

  return body;
}
