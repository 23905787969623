import React, { useEffect, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Tooltip } from "antd";

import icons from "../../../config/icons";
import { parseDateFormat } from "../../../config/config";

import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

export default function VodCollectionsTable({
  getAgain,
  changePosition,
  onVisible,
  handleMenuClick,
  openDrawer,
  getDataSource
}) {
  const [dataSource, setDataSource] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);

  const columns = [
    {
      key: "sort"
    },

    {
      title: "Name",
      dataIndex: "name",
      align: "center",

      render: (text, reacord, index) => {
        return (
          <Tooltip placement="topLeft" title={reacord.name}>
            <p style={{ margin: 0, marginLeft: 10 }}>{reacord.name}</p>
          </Tooltip>
        );
      }
    },

    {
      title: `Created date`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (text, record, index) => {
        return parseDateFormat(text);
      }
    },

    {
      title: `Visible`,
      dataIndex: "visible",
      key: "visible",
      align: "center",

      render: (text, record, index) => {
        return (
          <div>
            {record.visible ? (
              <ButtonComponent
                type="text"
                onClick={() => onVisible(record.id, false)}
                icon={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: '<i class="fa fa-eye"></i>'
                    }}
                  />
                }
              />
            ) : (
              <ButtonComponent
                type="text"
                danger={true}
                onClick={() => onVisible(record.id, true)}
                icon={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: '<i class="fa fa-eye-slash"></i>'
                    }}
                  />
                }
              />
            )}
          </div>
        );
      }
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          disabled={record.default}
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT
            },

            {
              key: "manage_movies",
              name: "Manage videos",
              icon: icons.MOVE
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this collection?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              }
            }
          ]}
        />
      )
    }
  ];

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        setIsUpdated(true);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);

    function onSuccess(data) {
      setIsLoading(false);
      setDataSource(data);
    }

    function onError(data) {
      setIsLoading(false);
    }

    getDataSource(onSuccess, onError);
  }, [getAgain]);

  useEffect(() => {
    if (dataSource.length > 0) {
      if (isUpdated) {
        let ids = dataSource.map((item) => item.id);

        ids = ids.join(",");

        changePosition(ids, () => {
          setIsUpdated(false);
        });
      }
    }
  }, [dataSource, isUpdated]);

  return (
    <div>
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <TableComponent
            header={
              <>
                <ButtonComponent
                  icon={icons.ADD}
                  title="Add Collection"
                  onClick={openDrawer}
                />
              </>
            }
            isLoading={isLoading}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            components={
              dataSource.length > 0 && {
                body: {
                  row: Row
                }
              }
            }
          />
        </SortableContext>
      </DndContext>
    </div>
  );
}
