import { Tabs } from "antd";
import PaymentsTab from "./payments/PaymentsTab";
import DevicesTab from "./devices/DevicesTab";

export default function TabsList({ user, getUser }) {
  const tabs = [
    {
      label: "Devices",
      key: "devices",
      children: <DevicesTab user={user} />
    },

    {
      label: "Payments",
      key: "payments",
      children: <PaymentsTab user={user} />
    }
  ];

  return <Tabs tabPosition="top" items={tabs} />;
}
