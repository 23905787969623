import { useEffect } from "react";

import { useNavigate } from "react-router";

import { Dropdown, Menu, Modal } from "antd";

import { QuestionOutlined } from "@ant-design/icons";

import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../../../redux-store/actions";

import { rValidateToken } from "../../../../server/requests";

import PATHS from "../../../../config/paths";
import icons from "../../../../config/icons";

import profileImage from "../../../../img/user.png";

import style from "./profile.module.scss";

export default function Profile({}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.globalState);

  const { setProfile } = bindActionCreators(actions, dispatch);

  const validate = () => {
    rValidateToken((data) => {
      let user = data.user;

      setProfile(user);

      localStorage.setItem("username", user.name + " " + user.surname);
    });
  };

  const onClickAccount = () => {
    navigate(PATHS.ACCOUNT);
  };

  const onClickManageTeam = () => {
    navigate(PATHS.TEAM_MEMBERS);
  };

  useEffect(() => {
    validate();
  }, []);

  const logout = () => {
    localStorage.clear();

    navigate("/");
  };

  const showConfirm = () => {
    Modal.confirm({
      title: "Do you Want to logout?",
      icon: <QuestionOutlined />,
      content: "",
      okText: "Logout",
      onOk() {
        logout();
      }
    });
  };

  const errorImage = (event) => {
    event.target.src = profileImage;
  };

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu style={{ marginTop: -10 }}>
          <Menu.Item
            key="account"
            onClick={onClickAccount}
            icon={icons.PROFILE}
          >
            Account
          </Menu.Item>

          <Menu.Item
            key="team-member"
            onClick={onClickManageTeam}
            icon={icons.TEAM_MEMBERS}
          >
            Team members
          </Menu.Item>

          <Menu.Item key="logout" onClick={showConfirm} icon={icons.LOGOUT}>
            Logout
          </Menu.Item>
        </Menu>
      }
    >
      <div className={style["profile"]}>
        {/* {profile && ( */}
        <>
          <img alt="User" src={profile?.image} onError={errorImage} />

          {`${profile ? profile?.name + " " + profile?.surname : ""}`}
        </>
        {/* )} */}
      </div>
    </Dropdown>
  );
}
