import { useState } from "react";
import { Divider, notification } from "antd";

import REQUESTS from "../../../../../api/requests";

import KeywordsTable from "./KeywordsTable";
import KeywordDrawer from "./KeywordDrawer";

import style from "../../style.module.scss";

export default function KeywordsTab() {
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [editable, setEditable] = useState(null);

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "delete":
        REQUESTS.KEYWORDS.DELETE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error
            });
          });

      default:
        break;
    }
  };

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.KEYWORDS.GET({ query: JSON.stringify(query) })

      .then((response) => {
        onSuccess(response.message);
      })
      .catch((error) => {
        onError(error);
      });
  };

  return (
    <div className={style["tab"]} style={{ width: "100%" }}>
      <Divider orientation="left">Keywords</Divider>

      <KeywordsTable
        getDataSource={getDataSource}
        getAgain={getAgain}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <KeywordDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />
    </div>
  );
}
