import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    DOCUMENT: `${host}v2/admin/document`,
};

const documentsApi = {
    GET: (query) => request("GET", URL.DOCUMENT, query),

    EDIT: (query) => request("PUT", URL.DOCUMENT, query),
};

export default documentsApi;
