import request from "../request";

const host = `${process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"}admin/`;

const URL = {
    CONVERSATION: `${host}conversation`,

    CHAT: {
        READ: `${host}chat/read`,
        GET: `${host}chat/message`,
        SEND: `${host}chat/send/`,
    },
};

const MESSAGES = {
    CHAT: {
        READ: (query) => {
            return request("PUT", URL.CHAT.READ, query);
        },

        GET: (conversation_id) => {
            return request(
                "GET",
                `${URL.CHAT.GET}?conversation_id=${conversation_id}&query=${JSON.stringify({
                    limit: 100000,
                })}`
            );
        },

        SEND: {
            MESSAGE: (query) => {
                return request("POST", `${URL.CHAT.SEND}message`, query);
            },

            FILE: (query) => {
                return request("POST", `${URL.CHAT.SEND}file`, query);
            },
        },
    },

    CONVERSATIONS: {
        GET: () => {
            return request("GET", URL.CONVERSATION);
        },

        DELETE: (query) => {
            return request("DELETE", URL.CONVERSATION, query);
        },
    },
};

export default MESSAGES;
