
import { Skeleton } from 'antd';

import { Col, Row } from 'antd';


import { useState, useEffect } from 'react';

import requests from '../../../server/requests';


export default function PlatformAnalytics() {

    let [platforms, setPlatforms] = useState([]);

    const getPlatforms = () => {
        requests.statistics.platform((data) => {
            console.log("DATA", data);

            let arr = [];

            for (let key in data) {
                arr.push(data[key])
            }

            setPlatforms(arr);

        })
    }

    useEffect(() => {
        getPlatforms();
    }, [])



    return (

        <>
            {platforms.map((item) => {
                return <Col xs={{ span: 12 }} lg={{ span: 5, offset: 1 }} className='analytics-block neo secondary fixed-height-card'>

                    <img src={item.icon} onError={(e) => {
                        e.target.src = "https://api.streamifyer.com/devices/medion.svg"
                    }} alt={item.name} />
                    <h2>{item.count}</h2>
                    <h3>{item.name}</h3>

                </Col>
            })}
        </>


    );
}
