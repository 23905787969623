import { useState, useEffect } from "react";

import { Form, Button, Input, notification, Divider } from "antd";

import REQUESTS from "../../../../api/requests";

import style from "../style.module.scss";
import icons from "../../../../config/icons";

export default function SocialLinksTab({ settings }) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState();

  const onFinish = (values) => {
    setIsLoading(true);

    const query = {
      facebook: values.facebook,
      instagram: values.instagram,
      linkedin: values.linkedin,
      youtube: values.youtube,
      twitter: values.twitter
    };

    REQUESTS.WEB_PAGE.SETTINGS.EDIT(query)
      .then((data) => {
        setIsLoading(false);

        if (data.error) {
          notification.error({
            message: "Error",
            description: data.message
          });

          return;
        }

        notification.success({
          message: "Success",
          description: data.message
        });
      })

      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (settings) {
      form.setFields([
        {
          name: "facebook",
          value: settings.facebook
        },

        {
          name: "instagram",
          value: settings.instagram
        },

        {
          name: "linkedin",
          value: settings.linkedin
        },

        {
          name: "youtube",
          value: settings.youtube
        },

        {
          name: "twitter",
          value: settings.twitter
        }
      ]);
    }
  }, [settings]);

  return (
    <div className={style["tab"]}>
      <Divider orientation="left">Social links</Divider>

      <Form
        form={form}
        name="web-about-us"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item label="Facebook" name="facebook">
          <Input />
        </Form.Item>

        <Form.Item label="Instagram" name="instagram">
          <Input />
        </Form.Item>

        <Form.Item label="Linkedin" name="linkedin">
          <Input />
        </Form.Item>

        <Form.Item label="Youtube" name="youtube">
          <Input />
        </Form.Item>

        <Form.Item label="Twitter" name="twitter">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            style={{ width: "100%" }}
            icon={icons.CHECK}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
