import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Space, Tag } from "antd";

import { useUsersOptions } from "../../../hooks/selectOptions";

import icons from "../../../config/icons";
import { getColumnDateProps, getQueryDate, parseDate } from "../../../config/config";

import TableComponent from "../../../_components/table/TableComponent";

export default function PaymentHistoryTable({ getDataSource }) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [amount, setAmount] = useState(null);

  const [search, setSearch] = useState({
    user: null,
  });

  const usersOptions = useUsersOptions("text");

  const columns = [
    {
      width: 60,
      title: "#",
      key: "index",
      dataIndex: `id`,
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "User",
      dataIndex: "user",
      key: "user",
      align: "center",
      filters: usersOptions,
      filterSearch: true,
      filterMultiple: false,
      onFilter: (value, record) => true,

      render: (text, record, index) => {
        return record.user ? (
          <Link
            to={`/dashboard/user/${record?.user?.id}`}
            style={{ color: "#007bff" }}
            target="_blank"
          >
            {record.user?.name}
          </Link>
        ) : (
          "N/A"
        );
      },
    },

    {
      title: "Amount",
      key: "amount",
      dataIndex: `amount`,
      align: "center",
      sorter: true,
      width: 120,
    },

    {
      title: "Payment type",
      key: "payment_type",
      dataIndex: `payment_type`,
      align: "center",
    },

    {
      title: "Date",
      key: "createdAt",
      dataIndex: `createdAt`,
      align: "center",
      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "Status",
      key: "status",
      dataIndex: `status`,
      align: "center",
      render: (text, record, index) => {
        return record?.status ? (
          <div style={{ color: "red" }}>{icons.ARROW_DOWN}</div>
        ) : (
          <div style={{ color: "green" }}>{icons.ARROW_UP}</div>
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);

    const paymentHistoryParams = {
      sort: data.sort,
      limit: data.limit,
      page: data.page,
    };

    sessionStorage.setItem("paymentParams", JSON.stringify(paymentHistoryParams));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);
      const paymentHistoryPage = JSON.parse(sessionStorage.getItem("paymentParams"));
      const query = {
        sort: paymentHistoryPage ? paymentHistoryPage.sort : sort,
        limit: paymentHistoryPage ? paymentHistoryPage.limit : limit,
        page: paymentHistoryPage ? paymentHistoryPage.page : currentPage,
      };

      if (search.user) {
        query.filter = {
          user_id: search.user[0],
        };
      }

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      function onSuccess(data) {
        if (data.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);
        setDataSource(data.rows);
        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);

        setAmount(data?.amount);
      }

      function onError() {
        setLoading(false);
      }

      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, search, date]);

  return (
    <TableComponent
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      footer={() => (
        <Space>
          {/* <Tag style={{ fontSize: 14 }}>
            Total: {amount?.total ? amount?.total?.toFixed(2) : 0}
          </Tag>
          <Tag style={{ fontSize: 14 }}>
            Spent: {amount?.spent ? amount?.spent?.toFixed(2) : 0}
          </Tag>
          <Tag style={{ fontSize: 14 }}>
            Unspent:
            {amount?.unspent ? amount?.unspent?.toFixed(2) : 0}
          </Tag> */}
        </Space>
      )}
    />
  );
}
