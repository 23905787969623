import { Button, Divider, Form, Input, Tag } from "antd";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router";

import PATHS from "../../config/paths";

import REQUESTS from "../../api/requests";

import { saveTokenToLocalStorage } from "../../config/config";

import style from "./style.module.scss";
import IMAGES from "../../config/images";
import { Link } from "react-router-dom";
import GoogleButton from "../../_components/GoogleButton";

export default function LoginPage({}) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    REQUESTS.PROFILE.LOGIN(values)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          setErrorMessage(response.message);

          return;
        }

        saveTokenToLocalStorage(response.message);
        // setPushTokenToServer()

        navigate(PATHS.WELCOME);
      })
      .catch((err) => {
        setErrorMessage(err.message);

        setIsLoading(false);
      });
  };

  const onClickLogin = () => {
    setErrorMessage(null);

    form.submit();
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className={style["page"]}>
      <div className={style["body"]}>
        <div className={style["container"]}>
          <div className={style["form-block"]}>
            {/* <img alt="logo" src={IMAGES.LOGO} /> */}
            <p className={style["title"]}>Sign in</p>

            <Form
              form={form}
              name="geolocation"
              layout="vertical"
              onFinish={onFinish}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  form.submit();
                }
              }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input email"
                  }
                ]}
              >
                <Input size="large" placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input password"
                  }
                ]}
              >
                <Input.Password size="large" placeholder="Password" />
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }} noStyle>
                <Button
                  type="primary"
                  size="large"
                  onClick={onClickLogin}
                  loading={isLoading}
                  style={{ width: "100%" }}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            <div
              className={style["error"]}
              style={{
                height: errorMessage ? "auto" : 0
              }}
            >
              {errorMessage && <Tag color="red">{errorMessage}</Tag>}
            </div>

            <Divider>or</Divider>

            <GoogleButton title="Sign in with google" />

            <p className={style["footer"]}>
              Don't have an account?{" "}
              <Link to={PATHS.REGISTER}>Get started</Link>
            </p>
          </div>

          <div className={style["right-block"]}></div>
        </div>

        <div>
          <h2 className={style["title"]}>
            Focus on the work that <span>Matters</span>
          </h2>
        </div>
      </div>
    </div>
  );
}
