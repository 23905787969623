import { useState } from "react";

import { Button, Tooltip } from "antd";

import icons from "../../../../config/icons";

import GoLiveModal from "./GoLiveModal";

import styles from "./go_live.module.scss";

export default function GoLive() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Tooltip title="Go Live">
        <div className={styles["live-section"]}>
          <Button
            size="large"
            type="primary"
            danger={true}
            shape="circle"
            className={styles["live-button"]}
            icon={icons.LIVE}
            onClick={setIsOpen}
          ></Button>
        </div>
      </Tooltip>

      <GoLiveModal open={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
}
