import { useState } from "react";

import { notification } from "antd";

import ImageUpload from "../../../../components/ImageUpload";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

import styles from "../_cast.module.scss";
import REQUESTS from "../../../../api/requests";

export default function GaleryUpload({ castId, addGalery }) {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({
    url: null,
    file: null
  });

  const onUpload = () => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append("castId", castId);
    formData.append("source", image.file);

    REQUESTS.VOD.CAST.GALERY.ADD(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message
          });

          return;
        }

        notification.success({
          message: "Success",
          description: "Icon is added"
        });

        addGalery(response.message);

        setImage({
          url: null,
          file: null
        });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles["galery-upload"]}>
      <ImageUpload image={image} setImage={setImage} />

      <ButtonComponent
        title="Save"
        isLoading={isLoading}
        disabled={!image.file}
        onClick={onUpload}
      />
    </div>
  );
}
