import { useState, useEffect } from "react";

import { Form, Button, Input, notification, Divider } from "antd";

import REQUESTS from "../../../../api/requests";

import style from "../style.module.scss";
import icons from "../../../../config/icons";

export default function AboutUsTab({ about_us }) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState();

  const onFinish = (values) => {
    setIsLoading(true);

    const query = {
      about_us: values.about_us
    };

    REQUESTS.WEB_PAGE.SETTINGS.EDIT(query)
      .then((data) => {
        setIsLoading(false);

        if (data.error) {
          notification.error({
            message: "Error",
            description: data.message
          });

          return;
        }

        notification.success({
          message: "Success",
          description: data.message
        });
      })

      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (about_us) {
      form.setFields([
        {
          name: "about_us",
          value: about_us
        }
      ]);
    }
  }, [about_us]);

  return (
    <div className={style["tab"]}>
      <Divider orientation="left">About Us</Divider>

      <Form
        form={form}
        name="web-about-us"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item name="about_us">
          <Input.TextArea rows={16} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            style={{ width: "100%" }}
            icon={icons.CHECK}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
