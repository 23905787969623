import {
  MenuOutlined,
  DeleteOutlined,
  QuestionOutlined
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Image, Table, Tooltip, Button, notification, Modal } from "antd";

import React, { useEffect, useState } from "react";
import REQUESTS from "../../../api/requests";
import WebSliderDrawer from "./WebSliderDrawer";
import defaultImage from "../../../img/photo.png";
import PageComponent from "../../../_components/page/PageComponent";
import VideoInfo from "../../../_components/VideoInfo";
import icons from "../../../config/icons";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props["data-row-key"]
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999
        }
      : {})
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move"
                }}
                {...listeners}
              />
            )
          });
        }
        return child;
      })}
    </tr>
  );
};

export default function WebSliderPage() {
  const [dataSource, setDataSource] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);

  const columns = [
    {
      key: "sort"
    },

    {
      title: "Name",
      dataIndex: "name",
      align: "center",

      render: (text, record, index) => {
        return (
          <VideoInfo
            textWidth={"100%"}
            name={record?.movie?.name}
            image={record?.movie?.poster}
            description={record?.movie?.description}
          />
        );
      }
    },

    {
      title: "",
      dataIndex: "delete",
      align: "center",
      render: (record, item, index) => {
        return (
          <Button
            danger
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => onClickDelete(item)}
          />
        );
      }
    }
  ];

  const onClickDelete = (item) => {
    Modal.confirm({
      title: "Do you want to delete this item",

      icon: <QuestionOutlined />,
      content: "",
      okText: "Delete",
      okButtonProps: {
        style: {
          background: "#d88484"
        }
      },
      onOk() {
        REQUESTS.WEB_PAGE.SLIDER.DELETE({ id: item.id })
          .then(() => {
            getSlider();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err
            });
          });
      }
    });
  };

  const getSlider = () => {
    setIsLoading(true);
    setIsUpdated(false);

    const query = {
      pagination: 0
    };

    REQUESTS.WEB_PAGE.SLIDER.GET({ query: JSON.stringify(query) })
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          return;
        }

        let list = response?.message.map((item) => ({ ...item, key: item.id }));

        setDataSource(list);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);

        setIsUpdated(true);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    getSlider();
  }, []);

  const updatePositions = () => {
    const list = dataSource.map((item) => item.id);

    const query = {
      position: JSON.stringify(list)
    };

    REQUESTS.WEB_PAGE.SLIDER.EDIT(query)
      .then((response) => {
        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message
          });
          return;
        }

        getSlider();

        notification.success({
          message: "Success",
          description: response.message
        });
      })

      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      if (isUpdated) {
        updatePositions();
      }
    }
  }, [dataSource]);

  return (
    <PageComponent routes={["Web Page", "Slider"]}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: 20
        }}
      >
        <Button
          type="primary"
          icon={icons.ADD}
          onClick={() => setIsOpenDrawer(true)}
        >
          Add
        </Button>
      </div>

      <DndContext onDragEnd={onDragEnd} style={{ zIndex: 0 }}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={
              dataSource.length > 0 && {
                body: {
                  row: Row
                }
              }
            }
            loading={isLoading}
            bordered
            size="small"
            rowKey="key"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </SortableContext>
      </DndContext>

      <WebSliderDrawer
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
        getData={getSlider}
      />
    </PageComponent>
  );
}
