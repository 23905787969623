import request from "../../../api/request";

import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  USERS: `${host}admin/users`,
  SEARCH: `${host}admin/users/search`
};

const usersApi = {
  GET: (query) => request("GET", URL.USERS, query),

  ADD: (query) => request("POST", URL.USERS, query),

  EDIT: (query) => request("PUT", URL.USERS, query),

  SEARCH: (query) => request("POST", URL.SEARCH, query),

  ARCHIVE: (query) => request("POST", `${URL.USERS}/delete`, query),

  RESTORE: (query) => request("POST", `${URL.USERS}/restore`, query),

  LOCK: (query) => request("POST", `${URL.USERS}/block_user`, query),

  UNLOCK: (query) => request("POST", `${URL.USERS}/activate_user`, query),

  TARIFF: (query) => request("POST", `${URL.USERS}/update_tariff`, query),

  UNIQUE_USERNAME: (query) =>
    request("POST", `${URL.USERS}/getUniqueName`, query)
};

export default usersApi;
