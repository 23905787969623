export default function initValues({ form, editable }) {
  form.setFields([
    { name: "url", value: editable.url },
    { name: "type", value: editable.type },
    { name: "repeat_counts", value: editable.repeat_counts }
  ]);

  if (editable.tariffs) {
    const tariffsIds = editable.tariffs.map((item) => item.id);

    form.setFields([{ name: "tariffs_id", value: tariffsIds }]);
  }

  form.setFields([{ name: "all_movies", value: editable.all_movies }]);

  if (editable.movies) {
    const moviesIds = editable.movies.map((item) => item.id);
    form.setFields([{ name: "movies_id", value: moviesIds }]);
  }
}
