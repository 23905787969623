import { Divider, Form, Radio, Select, Space, Switch } from "antd";

export default function Restrictions({ form, countriesOptions }) {
  return (
    <div>
      <Divider orientation="left">Restrictions</Divider>

      {/* <Form.Item label="Age restrictions" name="pg" style={{ marginBottom: 0 }}>
        <Radio.Group name="pg" defaultValue={18}>
          <Radio value={12}>Yes, it's made for kids +6</Radio>
          <Radio value={18}>No, it's not made for kids +18</Radio>
        </Radio.Group>
      </Form.Item> */}

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { is_location_restriction } = form.getFieldsValue();

          return (
            <Form.Item
              label={
                <Space>
                  Available countries
                  <Form.Item
                    name="is_location_restriction"
                    style={{ marginBottom: 0 }}
                    valuePropName="checked"
                  >
                    <Switch size="small" />
                  </Form.Item>
                </Space>
              }
              name="available_countries"
              style={{ marginBottom: 10 }}
            >
              <Select
                mode="multiple"
                options={countriesOptions}
                disabled={!is_location_restriction}
                maxTagCount="responsive"
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </div>
  );
}
