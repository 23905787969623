import request from "../../../api/request";

import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  BASICS: `${host}v2/admin/basics`,

  SETTINGS: `${host}admin/settings/`
};

const basicInfoApi = {
  GET: (query) => request("GET", URL.BASICS, query),

  EDIT: (query) => request("PUT", URL.BASICS, query),

  COLORS: {
    GET: () => request("POST", `${URL.SETTINGS}get`),

    UPDATE: (query) => request("PUT", `${URL.SETTINGS}color`, query)
  }
};

export default basicInfoApi;
