


import { Line } from '@ant-design/plots';
import { useState, useEffect } from 'react';

import requests from '../../../server/requests';
import ItemDescription from './ItemDescription';


export default function VideoDurations() {

    let [durations, setDurations] = useState({
        data: [],
        xField: 'date',
        yField: 'value',
        seriesField: 'type',
        legend: {
            position: 'top',
        },
    });

    const getVideoDurations = () => {
        requests.statistics.views_duration_movie((data) => {
            durations.data = data.data;
            setDurations(durations)
        })
    }

    useEffect(() => {
        getVideoDurations();
    }, [])


    return (

        <>
            <ItemDescription content={"This chart is displaying the watched time of the videos per month in seconds "} title={"Videos watch time"} />
            <Line style={{ width: "100%" }} {...durations} />
        </>

    );
}



























