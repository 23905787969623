import { Line } from "@ant-design/plots";
import { useState, useEffect } from "react";

import requests from "../../../server/requests";
import ItemDescription from "./ItemDescription";

export default function VideoViews() {
  let [video_views, setVideoViews] = useState({
    data: [],
    xField: "date",
    yField: "value",
    seriesField: "type",
    legend: {
      position: "top"
    }
  });

  const getVideoViews = () => {
    requests.statistics.view_per_movie((data) => {
      video_views.data = data.data;
      setVideoViews(video_views);
    });
  };

  useEffect(() => {
    getVideoViews();
  }, []);

  return (
    <>
      <ItemDescription
        content={
          "This chart is displaying per month how many times your all videos was watched"
        }
        title={"Videos views count"}
      />
      <Line style={{ width: "100%" }} {...video_views} />
    </>
  );
}
