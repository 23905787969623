import request from "../../../api/request";

import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    FEATURE: `${host}admin/feature`,
};

const webFeaturesApi = {
    GET: (query) => request("GET", URL.FEATURE, query),

    ADD: (query) => request("POST", URL.FEATURE, query),

    EDIT: (query) => request("PUT", URL.FEATURE, query),

    DELETE: (query) => request("DELETE", URL.FEATURE, query),
};

export default webFeaturesApi;
