import request from "../../api/request";
import { DEMO_API_HOST } from "../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    ADMINS: `${host}admin/admins`,
    GET: `${host}admin/get/admins`,
    DELETE: `${host}admin/remove`,
    ROLES: `${host}admin/roles`,
};

const adminsApi = {
    GET: (query) => request("GET", URL.ADMINS, query),

    ADD: (query) => request("POST", URL.ADMINS, query),

    EDIT: (query) => request("PUT", URL.ADMINS, query),

    DELETE: (query) => request("POST", URL.DELETE, query),

    ROLES: (query) => request("POST", URL.ROLES, query),
};

export default adminsApi;
