import { useEffect, useState } from "react";

import { Divider, Form, Input, message } from "antd";

import { useDispatch, useSelector } from "react-redux";

import { bindActionCreators } from "redux";

import REQUESTS from "../../../../api/requests";

import * as actions from "../../../../redux-store/actions";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

import styles from "../_basic-info.module.scss";

export default function AppDomainsTab({}) {
  const dispatch = useDispatch();

  const { setCompany } = bindActionCreators(actions, dispatch);

  const { company } = useSelector((state) => state.globalState);

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const ensureHttps = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };
  const onFinish = (values) => {
    let checkValues =
      values.web_app_host === company?.web_app_host &&
      values.web_host === company?.web_host;

    if (checkValues) {
      message.warning("Your data has not been modified");

      return;
    }

    const webHost = ensureHttps(values?.web_host);
    const webAppHost = ensureHttps(values?.web_app_host);

    const body = {
      web_host: webHost,
      web_app_host: webAppHost,
    };

    setIsLoading(true);

    REQUESTS.PROFILE.COMPANY.EDIT_DOMAIN(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          message.error(response.message);

          return;
        }

        getData();

        message.success(response.message);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getData = () => {
    REQUESTS.PROFILE.COMPANY.GET().then((response) => {
      setCompany(response?.message);

      form.setFields([
        {
          name: "web_host",
          value: response?.message?.web_host,
        },

        {
          name: "web_app_host",
          value: response?.message?.web_app_host,
        },
      ]);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={styles["container"]}>
      <Divider orientation="left">App Domains</Divider>

      <Form
        form={form}
        name="store-links"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
      >
        <Form.Item label="Business page" name="web_host">
          <Input />
        </Form.Item>

        <Form.Item label="Web app" name="web_app_host">
          <Input />
        </Form.Item>

        <ButtonComponent
          style={{ width: "100%" }}
          title="Save"
          actionType="save"
          isLoading={isLoading}
          onClick={() => form.submit()}
        />
      </Form>
    </div>
  );
}
