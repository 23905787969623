import { useEffect, useState } from "react";

import { Button, Tag, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";

import { bindActionCreators } from "redux";

import { SyncOutlined, GlobalOutlined } from "@ant-design/icons";

import * as actions from "../../../../redux-store/actions";

import SetupAppsModal from "./SetupAppsModal";

import REQUESTS from "../../../../api/requests";

export default function SetupAppStatus({}) {
  const dispatch = useDispatch();

  const { setCompany } = bindActionCreators(actions, dispatch);

  const { company } = useSelector((state) => state.globalState);

  const [isOpen, setIsOpen] = useState(false);

  const getCompany = () => {
    REQUESTS.PROFILE.COMPANY.GET().then((response) => {
      setCompany(response?.message);
    });
  };

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    let processingInterval = null;

    switch (company?.web_deployed_status) {
      case "uncertain":
        setIsOpen(true);
        break;

      case "pending":
        processingInterval = setInterval(() => {
          getCompany();
        }, 10000);
        break;

      case "deployed":
        break;

      default:
        break;
    }

    return () => {
      clearInterval(processingInterval);
    };
  }, [company]);

  const onClose = () => {
    setIsOpen(false);

    getCompany();
  };

  const returnBlock = () => {
    switch (company?.web_deployed_status) {
      case "pending":
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            Processing
          </Tag>
        );

      case "deployed":
        return (
          <Tooltip placement="topLeft" title="Open your web page">
            <Button
              icon={<GlobalOutlined />}
              size="large"
              type="link"
              shape="circle"
              color="#55acee"
              onClick={() => {
                window.open(company?.web_host, "_blank");
              }}
            ></Button>
          </Tooltip>
        );

      default:
        break;
    }
  };

  return (
    <div>
      {returnBlock()}

      <SetupAppsModal isOpen={isOpen} onClose={onClose} />
    </div>
  );
}
