import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    TRANSLATE: `${host}v2/admin/languages/translate`,
};

const _translationsApi = {
    GET: (query) => request("GET", URL.TRANSLATE, query),

    ADD: (query) => request("POST", URL.TRANSLATE, query),

    EDIT: (query) => request("PUT", URL.TRANSLATE, query),

    DELETE: (query) => request("DELETE", URL.TRANSLATE, query),
};

export default _translationsApi;
