import { useState } from "react";
import { notification } from "antd";
import { useNavigate } from "react-router";

import PATHS from "../../../config/paths";

import REQUESTS from "../../../api/requests";

import VideosTable from "./components/VideosTable";
import GalleryDrawer from "./components/gallery/GalleryDrawer";
import PageComponent from "../../../_components/page/PageComponent";

export default function VideosPage() {
  const navigate = useNavigate();

  const [getAgain, setGetAgain] = useState(false);

  const [itemForGalery, setItemForGalery] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.VOD.VIDEOS.GET({ query: JSON.stringify(query) })
      .then((response) => {
        onSuccess(response.message);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const onEnabled = (value, id) => {
    const type = value ? "enable" : "disable";

    REQUESTS.VOD.VIDEOS.ENABLE_OR_DISABLE(type, { id })
      .then((response) => {
        setGetAgain((prev) => !prev);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const onHighlighted = (value, id) => {
    const body = {
      id: id,
      highlighted: value,
    };

    REQUESTS.VOD.VIDEOS.HIGHLIGHTED(body)
      .then((response) => {
        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message,
          });

          return;
        }

        notification.success({
          message: "Success",
          description: response.message,
        });

        setGetAgain((prev) => !prev);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error,
        });
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        navigate(`${PATHS.EDIT_VIDEO}${item.id}`);
        break;

      case "gallery":
        setItemForGalery(item);
        break;

      case "delete":
        REQUESTS.VOD.VIDEOS.DELETE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error,
            });
          });

      default:
        break;
    }
  };

  return (
    <PageComponent routes={["Contents", "Videos"]}>
      <VideosTable
        getAgain={getAgain}
        setGetAgain={setGetAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        onHighlighted={onHighlighted}
        onEnabled={onEnabled}
      />

      <GalleryDrawer
        open={itemForGalery ? true : false}
        onClose={() => setItemForGalery(null)}
        movie={itemForGalery}
      />
    </PageComponent>
  );
}
