import REQUESTS from "../api/requests";

const returnFileId = (file) => {
  const lastModified = file?.lastModified;

  const name = `${lastModified}_${file.name}`;

  return name.replace(/\//g, "");
};

const uploadFile = (file, companyId, callback, errorCallback, onPorgress) => {
  if (file) {
    REQUESTS.VIDEO.STATUS({
      file_id: returnFileId(file),
      company_id: companyId
    })
      .then((response) => {
        const total_chunk_uploaded = response?.total_chunk_uploaded;

        const headers = {
          "starting-byte": total_chunk_uploaded,
          "file-id": returnFileId(file),
          "chunk-size": file.size - total_chunk_uploaded,
          "file-size": file.size,
          "company-id": companyId
        };

        file = file.slice(total_chunk_uploaded, file.size);

        const formData = new FormData();
        formData.append("file", file, file.name);

        REQUESTS.VIDEO.START(formData, headers, (progress) => {
          if (onPorgress) onPorgress(progress);
        })
          .then((response) => {
            if (
              response.data.message === "Finish" ||
              response.data.message === "This file already exists"
            ) {
              callback(response.data);
              return;
            }

            errorCallback(response.data);
          })
          .catch((error) => {
            errorCallback(error);
          });
      })
      .catch((error) => {
        errorCallback(error?.message);
      });
  }
};

export default uploadFile;
