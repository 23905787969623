export default function colorByHex(color) {
  if (typeof color == "string") {
    return color.includes("#") ? color : `#${color}`;
  } else {
    if (color?.metaColor?.originalInput) {
      if (typeof color?.metaColor?.originalInput == "string") {
        return color.metaColor?.originalInput.includes("#")
          ? color?.metaColor?.originalInput
          : `#${color?.metaColor?.originalInput}`;
      }

      let { h, s, v } = color?.metaColor?.originalInput;

      // Ensure h is between 0 and 360
      h = h < 0 ? 0 : h > 360 ? 360 : h;

      // Ensure s and v are between 0 and 1
      s = s < 0 ? 0 : s > 1 ? 1 : s;
      v = v < 0 ? 0 : v > 1 ? 1 : v;

      // Calculate RGB values
      const c = v * s;
      const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
      const m = v - c;
      let r, g, b;

      if (h >= 0 && h < 60) {
        r = c;
        g = x;
        b = 0;
      } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
        b = 0;
      } else if (h >= 120 && h < 180) {
        r = 0;
        g = c;
        b = x;
      } else if (h >= 180 && h < 240) {
        r = 0;
        g = x;
        b = c;
      } else if (h >= 240 && h < 300) {
        r = x;
        g = 0;
        b = c;
      } else {
        r = c;
        g = 0;
        b = x;
      }

      // Convert RGB to HEX
      const rHex = Math.round((r + m) * 255)
        .toString(16)
        .padStart(2, "0");
      const gHex = Math.round((g + m) * 255)
        .toString(16)
        .padStart(2, "0");
      const bHex = Math.round((b + m) * 255)
        .toString(16)
        .padStart(2, "0");

      return `#${rHex}${gHex}${bHex}`;
    } else {
      return color.includes("#") ? color : `#${color}`;
    }
  }
}
