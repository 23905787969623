import { useEffect, useState } from "react";

import { Button, Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ImageUpload from "../../../components/ImageUpload";
import icons from "../../../config/icons";

export default function AvailableDevicesDrawer({
  onClose,
  open,
  current,
  getData
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [image, setImage] = useState({
    url: null,
    file: null
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();
    if (image.file) {
      formData.append("image", image.file);
    }
    formData.append("name", values.name);

    if (current) {
      formData.append("id", current.id);

      REQUESTS.WEB_PAGE.AVAILABLE_DEVICES.EDIT(formData)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.WEB_PAGE.AVAILABLE_DEVICES.ADD(formData)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message
            });

            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();

      setImage({
        url: null,
        file: null
      });
    }

    if (current) {
      form.setFields([{ name: "name", value: current.name }]);

      setImage({
        url: current.image,
        file: null
      });
    }
  }, [open, current]);

  useEffect(() => {
    if (image.url) {
      form.setFieldsValue({
        image: image.url
      });
    }
  }, [image]);

  return (
    <Drawer
      width={500}
      title={`${current ? "Edit" : "Add"} device`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        name="available-devices"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Image"
          name="image"
          rules={[
            {
              required: current ? false : true,
              message: "Please choose image"
            }
          ]}
        >
          <ImageUpload image={image} setImage={setImage} />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name"
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            icon={icons.CHECK}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
