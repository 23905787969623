import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router";

import PATHS from "../../config/paths";

import { saveTokenToLocalStorage } from "../../config/config";

export default function InitialWelcomePage() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search.length > 0) {
      const searchParams = new URLSearchParams(location.search);

      const token = searchParams.get("token");

      if (token) {
        saveTokenToLocalStorage(token);
        navigate(PATHS.WELCOME);
      }
    }
  }, [location]);
  return <div></div>;
}
