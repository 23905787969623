import { useState } from "react";
import { Form, Input, Button, Switch } from "antd";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";

const ImportUrl = ({ open, disabled, isLoading, onClose, getValue }) => {
  const [form] = Form.useForm();

  const [inputValue, setInputValue] = useState({
    trailer_id: "",
    video_id: "",
  });

  const handleFinish = (value) => {
    getValue(value);
  };

  const handleCHange = ({ target }) => {
    console.log(target.id);
    const value = form.getFieldValue(target.id);

    setInputValue({
      ...inputValue,
      [target.id]: value,
    });
  };
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleFinish}
      onChange={handleCHange}
      initialValues={{
        auto_enable: true,
      }}
    >
      <Form.Item
        label="Name: (Leave blank if you want to get it from youtube)"
        name="video_name"
        style={{ marginBottom: 5 }}
        rules={[
          {
            required: false,
            message: "Please video id",
          },
        ]}
      >
        <Input placeholder="Please enter name" />
      </Form.Item>
      <Form.Item
        label="Video"
        name="video_id"
        style={{ marginBottom: 10 }}
        rules={[
          {
            required: inputValue.trailer_id?.length ? false : true,
            message: "Please video id",
          },
        ]}
      >
        <Input placeholder="Please enter trailer id" />
      </Form.Item>
      <Form.Item
        label="Trailer"
        name="trailer_id"
        style={{ marginBottom: 10 }}
        rules={[
          {
            required: inputValue.video_id?.length ? false : true,
            message: "Please input trailer id",
          },
        ]}
      >
        <Input placeholder="Please enter trailer id" />
      </Form.Item>
      <Form.Item
        label="Auto enable"
        name="auto_enable"
        valuePropName="checked"
        style={{ marginBottom: 10 }}
      >
        <Switch />
      </Form.Item>
      <Form.Item labelAlign="left" style={{ marginBottom: 0 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 10,
            marginTop: 5,
          }}
        >
          <Button
            type="default"
            danger={true}
            disabled={disabled}
            style={{ width: 100 }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <ButtonComponent
            isLoading={isLoading}
            disabled={disabled}
            title="Save"
            actionType="save"
            style={{ width: 100 }}
            // onClick={() => navigate("/dashboard/edit-video/321")}
            onClick={() => form.submit()}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export default ImportUrl;
