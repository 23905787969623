import React, { useState } from "react";
import { Button, Form, Input, Switch } from "antd";
import VideoTranscode from "../../../../../_components/video-transcode/VideoTranscode";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";

const UploadFile = ({
  form,
  open,
  onFinish,
  setVideoData,
  setTrailerData,
  setDisabled,
  onChangeNameFromFile,
  check,
  isLoading,
  disabled,
  onClose,
}) => {
  console.log("mount", open);
  return (
    <>
      <Form
        form={form}
        name="new-video"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          auto_enable: true,
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input
            placeholder="Please input name"
            onChange={(e) => check(e.target?.value)}
          />
        </Form.Item>

        <Form.Item label="Video" style={{ marginBottom: 0 }}>
          {open && (
            <VideoTranscode
              onChange={setVideoData}
              onDisable={setDisabled}
              onChangeName={onChangeNameFromFile}
            />
          )}
        </Form.Item>

        <Form.Item label="Trailer" style={{ marginBottom: 0 }}>
          {open && (
            <VideoTranscode
              onChange={setTrailerData}
              onDisable={setDisabled}
              onChangeName={onChangeNameFromFile}
            />
          )}
        </Form.Item>

        <Form.Item
          label="Auto enable"
          name="auto_enable"
          valuePropName="checked"
          style={{ marginBottom: 10 }}
        >
          <Switch />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
          <Button
            type="default"
            danger={true}
            disabled={disabled}
            style={{ width: 100 }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <ButtonComponent
            isLoading={isLoading}
            disabled={disabled}
            title="Save"
            actionType="save"
            style={{ width: 100 }}
            onClick={() => form.submit()}
          />
        </div>
      </Form>
    </>
  );
};

export default UploadFile;
