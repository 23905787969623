import { useEffect, useState } from "react";

import { Tabs } from "antd";

import REQUESTS from "../../../api/requests";

import ImagesTab from "./tabs/ImagesTab";

import PageComponent from "../../../_components/page/PageComponent";
import AppColorsTab from "./tabs/AppColorsTab";
import AppDomainsTab from "./tabs/AppDomainsTab";

export default function BasicInfoPage({}) {
  const [data, setData] = useState([]);

  const getData = () => {
    REQUESTS.APPLICATION.BASIC_INFO.GET()
      .then((response) => {
        if (response.message) {
          setData(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const tabs = [
    {
      label: "Images",
      key: "images",
      children: <ImagesTab data={data} getData={getData} />
    },

    {
      label: "App colors",
      key: "app_colors",
      children: <AppColorsTab />
    },

    {
      label: "App Domains",
      key: "app_domains",
      children: <AppDomainsTab />
    }
  ];

  return (
    <PageComponent routes={["Application", "Branding"]}>
      <Tabs tabPosition="left" items={tabs} />
    </PageComponent>
  );
}
