import request from "../../../api/request";

const host = `${
  process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"
}admin/`;

const URL = {
  CONTENT_TYPE: `${host}settings/show_content_type`
};

const contentPrivacyPageApi = {
  GET: () => request("GET", URL.CONTENT_TYPE),

  UPDATE: (query) => request("PUT", `${URL.CONTENT_TYPE}`, query)
};

export default contentPrivacyPageApi;
