import { useEffect, useState } from "react";
import { Tabs } from "antd";

import REQUESTS from "../../../api/requests";

import PageComponent from "../../../_components/page/PageComponent";
import DocumentEditor from "./DocumentEditor";
import Loading from "../../../_components/loadings/Loading";

export default function DocumentsPage({}) {
  const [isLoading, setIsLoading] = useState(true);

  const [tabs, setTabs] = useState([]);

  const [activeTab, setActiveTab] = useState("1");

  const getData = () => {
    setIsLoading(true);
    REQUESTS.WEB_PAGE.DOCUMENTS.GET()
      .then((res) => {
        setIsLoading(false);

        if (res.message) {
          const list = res.message.map((item, index) => {
            return {
              key: JSON.stringify(index + 1),
              label: item.name,
              children: <DocumentEditor item={item} key={item.id} />
            };
          });
          setTabs(list);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <PageComponent routes={["Web Page", "Documents"]}>
      <div>
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "50rem"
            }}
          >
            <Loading />
          </div>
        ) : (
          <Tabs
            defaultActiveKey="1"
            tabPosition="left"
            items={tabs}
            activeKey={activeTab}
            onChange={setActiveTab}
          />
        )}
      </div>
    </PageComponent>
  );
}
