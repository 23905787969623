import { Tabs } from "antd";

import ProfileTab from "./ProfileTab";

import NotificationsTab from "./NotificationsTab";

import PageComponent from "../../_components/page/PageComponent";
import RemoveAccountTab from "./RemoveAccountTab";

export default function AccountPage({}) {
  const tabs = [
    {
      label: "Profile",
      key: "profile",
      children: <ProfileTab />
    },

    {
      label: "Notifications",
      key: "notifications",
      children: <NotificationsTab />
    },

    {
      label: "Remove Account",
      key: "remove-account",
      children: <RemoveAccountTab />
    }
  ];

  return (
    <PageComponent>
      <h5>My Account</h5>
      <Tabs items={tabs} />
    </PageComponent>
  );
}
