import request from "../../../api/request";

const host = `${
  process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"
}`;

const URL = {
  STORE_LINKS: `${host}admin/settings/mobil_apps_url`
};

const storeLinksApi = {
  GET: (query) => request("GET", `${URL.STORE_LINKS}`, query),

  UPDATE: (query) => request("PUT", `${URL.STORE_LINKS}`, query)
};

export default storeLinksApi;
