import { useEffect, useState } from "react";

import { Switch, message } from "antd";

import REQUESTS from "../../api/requests";

import styles from "./account_page.module.scss";

export default function NotificationsTab({}) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = () => {
    REQUESTS.PROFILE.NOTIFICATIONS.GET().then((response) => {
      if (response.error) {
        message.error(response.message);
        return;
      }

      setNotifications(response.message);
    });
  };

  const onChange = (checked, notification_id) => {
    if (checked) {
      REQUESTS.PROFILE.NOTIFICATIONS.ENABLE(notification_id).then(
        (response) => {
          if (response.error) {
            message.error(response.message);
            return;
          }

          message.success("Notification updated successfully");

          getNotifications();
        }
      );
    } else {
      REQUESTS.PROFILE.NOTIFICATIONS.DISABLE(notification_id).then(
        (response) => {
          if (response.error) {
            message.error(response.message);
            return;
          }

          message.success("Notification updated successfully");

          getNotifications();
        }
      );
    }
  };

  return (
    <div className={styles["notifications-tab"]}>
      {notifications.map((notification) => {
        return (
          <div className={styles["notification"]}>
            <p>{notification.name}</p>
            <Switch
              size="small"
              checked={notification.is_enabled}
              onChange={(e) => onChange(e, notification.id)}
            />
          </div>
        );
      })}
    </div>
  );
}
