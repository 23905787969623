import { Divider, Form, Input, Radio, Select } from "antd";
import {
  useTvodPaymentsOptions,
  usePaymentsSubscriptionsOptions
} from "../../../../hooks/selectOptions";

export default function Monetization({ form }) {
  const tvodPaymentsOptions = useTvodPaymentsOptions();

  const paymentsSubscriptionsOptions = usePaymentsSubscriptionsOptions();

  return (
    <div>
      <Divider orientation="left">Monetization</Divider>
      <Form.Item
        label="Content monitization tpye"
        name="content_monetization_type"
      >
        <Radio.Group name="content_monetization_type" defaultValue={"free"}>
          <Radio value="free">Free</Radio>
          <Radio value="svod">SVOD</Radio>
          <Radio value="tvod">TVOD</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { content_monetization_type } = form.getFieldsValue();

          return content_monetization_type === "svod" ? (
            <Form.Item
              label="Tariffs"
              name="tariffIds"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select tariff"
                }
              ]}
            >
              <Select options={paymentsSubscriptionsOptions} mode="multiple" />
            </Form.Item>
          ) : content_monetization_type === "tvod" ? (
            <Form.Item
              label="TVOD pricing"
              name="pricing_id"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please select pricing"
                }
              ]}
            >
              <Select options={tvodPaymentsOptions} />
            </Form.Item>
          ) : null;
        }}
      </Form.Item>
    </div>
  );
}
