import { useEffect, useState } from "react";
import { Popconfirm, Radio, Space, Button, message } from "antd";

import REQUESTS from "../../../api/requests";

import PageComponent from "../../../_components/page/PageComponent";

import style from "./style.module.scss";

export default function ContentPrivacyPage({}) {
  const [value, setValue] = useState(1);

  const [current, setCurrent] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const [list, setList] = useState([]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const save = () => {
    setIsLoading(true);

    REQUESTS.VOD.CONTENT_PRIVACY.UPDATE({ id: value })
      .then((response) => {
        if (response.error) {
          message.error(response.message);

          setIsLoading(false);
        } else {
          message.success("Update is successfuly");

          getData();

          setIsLoading(false);
        }
      })

      .catch((err) => {
        console.log(err);

        setIsLoading(false);
      });
  };

  const getData = () => {
    REQUESTS.VOD.CONTENT_PRIVACY.GET()
      .then((response) => {
        if (!response.error) {
          setList(response.message);

          const finded = response?.message.find((item) => item.is_enabled);

          if (finded) {
            setValue(finded.id);
            setCurrent(finded.id);
          }
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const returnDescription = () => {
    const finded = list.find((item) => item.id === value);

    return `You want to change content showing mode to ${finded.name} ?`;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <PageComponent routes={["Contents", "Content Privacy"]}>
      <div className={style["tab"]} style={{ width: 600 }}>
        <p className={style["title"]}>Show All Content</p>

        <p className={style["description"]}>
          If enabled, user will be see all content.
        </p>

        <Radio.Group
          onChange={onChange}
          value={value}
          style={{ width: "100%" }}
        >
          <Space direction="vertical">
            {list.map((item) => {
              return (
                <div key={item.id}>
                  <Radio value={item.id}>{item.name}</Radio>
                  <div style={{ color: "grey", fontSize: 12, marginLeft: 23 }}>
                    {item.description}
                  </div>
                </div>
              );
            })}
          </Space>
        </Radio.Group>

        {list.length ? (
          <Popconfirm
            title="Are you sure ?"
            description={returnDescription}
            onConfirm={save}
            okText="Yes"
            cancelText="No"
            disabled={current === value}
          >
            <Button
              type="primary"
              loading={isLoading}
              disabled={current === value}
              style={{ width: 200, marginTop: 30 }}
            >
              Save
            </Button>
          </Popconfirm>
        ) : null}
      </div>
    </PageComponent>
  );
}
