import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"}`;

const URL = {
    SETTINGS: `${host}payment/payment_settings`,
};

export default {
    GET: (query) => request("GET", URL.SETTINGS, query),

    UPDATE: (query) => request("PUT", URL.SETTINGS, query),
};

// Authorize
// sdf38734hr38fbyd348
// djskfnc873427t2y38

// Paypal
// ATAXInVH32u71G4ANOm8X3D7Oo0XLojNVwqN3HvnFnihogmbZ98AMW8tTLS4QKgDthNHRR7pB70dPmS_
// EAiqeEUKWFe0R-WnVWUDHcpygwuIWNYgYJEyChYXsDhdEin4PBJMPju2By3KNOSHgGuetBPDDhvvei8Z

//Stripe
// pk_test_51MEWkwD4dUaQ5FX2GW2fJZYnSJVxVhJIShEpPR7JYFy14HsWl1XaWXDpXyGL2rgnuHRG2ZjVcGHuCKnEj9oVhj8u00JpNe3b3J
// sk_test_51MEWkwD4dUaQ5FX2N1zbDg8YL8lnNKQ3M8202ww7fNuMzQ2hfn2HQWZsgGARW72neo4QR6nMDlNCwoQgDEryQyDv00rz2HteXS

// Telcell
// https://demo-api.inorain.tv/telcell
