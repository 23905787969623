import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"}`;

const URL = {
    SCRIPT: `${host}admin/script`,
};

export default {
    GET: (query) => request("GET", `${URL.SCRIPT}`, query),

    ADD: (query) => request("POST", `${URL.SCRIPT}`, query),

    EDIT: (query) => request("PUT", `${URL.SCRIPT}`, query),

    DELETE: (query) => request("DELETE", `${URL.SCRIPT}`, query),
};
