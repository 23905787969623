import Hls from "hls.js";

import { memo, useEffect, useRef } from "react";

import ReactHlsPlayer from "react-hls-player";

function Player({ url, autoplay = true }) {
  const hls = useRef(null);
  const playerRef = useRef(null);
  let test_video = document.getElementById("test-video");

  useEffect(() => {
    hls.current = new Hls();
    hls.current.loadSource(url);
    hls.current.attachMedia(playerRef.current);
    playerRef.current.autoplay = autoplay;

    return () => {
      hls.current.destroy();
    };
  }, []);

  if (test_video && test_video.canPlayType("application/vnd.apple.mpegurl")) {
    return (
      <video
        src={url}
        autoPlay={autoplay}
        controls
        width="100%"
        height="auto"
      ></video>
    );
  } else {
    return (
      <ReactHlsPlayer
        url={url}
        autoplay={autoplay}
        controls={true}
        width="100%"
        playerRef={playerRef}
      />
    );
  }
}

export default memo(Player);
