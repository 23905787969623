import { useEffect, useState } from "react";

import icons from "../../../config/icons";

import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
  parseDate,
} from "../../../config/config";

import TableImage from "../../../_components/table/TableImage";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

export default function AvailableDevicesTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [date, setDate] = useState(null);

  const [search, setSearch] = useState({
    name: null,
  });

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      fixed: "left",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",

      render: (text, record, index) => {
        return <TableImage src={record.image} />;
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      align: "center",

      ...getColumnSearchProps(),
    },

    {
      title: `Created date`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this device?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);

    const devicePageParams = {
      sort: data.sort,
      limit: data.limit,
      page: data.page,
    };

    sessionStorage.setItem("device_page", JSON.stringify(devicePageParams));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);
      const devicePage = JSON.parse(sessionStorage.getItem("device_page"));
      const query = {
        limit: devicePage ? devicePage?.limit : limit,
        page: devicePage ? devicePage?.page : currentPage,
        sort: devicePage ? devicePage?.sort : sort,
      };

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      if (search.name) {
        query.search = {
          ...query.search,
          name: search.name[0],
        };
      }

      function onSuccess(data) {
        setLoading(false);

        if (!data.error) {
          if (data?.rows?.length === 0 && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            return;
          }

          setDataSource(data?.rows);

          setTotal(data?.total);
          setLimit(data?.limit);
          setCurrentPage(data?.page);
        }
      }

      function onError() {
        setLoading(false);
      }

      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, search, getAgain, date]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent icon={icons.ADD} title="Add device" onClick={openDrawer} />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 800,
      }}
    />
  );
}
