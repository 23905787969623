import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"}`;

const URL = {
    ARCHIVE: `${host}archive/`,
};

export default {
    SERVERS: {
        GET: (query) => request("POST", `${URL.ARCHIVE}/get`, query),
    },
};
