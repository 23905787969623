import { Pie } from '@ant-design/plots';
import { useState, useEffect } from 'react';

import requests from '../../../server/requests';
import ItemDescription from './ItemDescription';


export default function UsersByGroups() {

    let [users_by_groups, setUsersByGroups] = useState({
        appendPadding: 10,
        data: [],
        angleField: 'value',
        colorField: 'name',
        radius: 0.75,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}',
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    });

    const getUserByGroups = () => {
        requests.statistics.users_by_groups((data) => {
            users_by_groups.data = data.chart_data || data.data;
            setUsersByGroups(users_by_groups);
        })
    }

    useEffect(() => {
        getUserByGroups();
    }, [])



    return (

        <>
            <ItemDescription title={"Users by groups"} content={"The chart is displaying the segmentation of the suers by groups with percents"} />
            <Pie style={{ width: "100%" }} {...users_by_groups} />
        </>


    );
}

















