import { useState } from "react";

import { useSelector } from "react-redux";

import { Progress, Tag, message } from "antd";

import { SyncOutlined } from "@ant-design/icons";

import Player from "../Player";

import Upploader from "../uploader/Uploader";

import uploadFile from "../../config/uploadFIle";

import { getToken } from "../../server/requests";

function VideoTranscode({
  onChange = () => {},
  onDisable = () => {},
  defaultUrl,
  onChangeName = () => {},
  transcodingStatus,
  transcodingId,
}) {
  const { profile } = useSelector((state) => state.globalState);

  const [url, setUrl] = useState("");

  const [fileUrl, setFileUrl] = useState("");

  const [progress, setProgress] = useState(0);

  const [type, setType] = useState("file");

  const [disabled, setDisabled] = useState(false);

  const onUpload = (info) => {
    const file = info?.file?.originFileObj;
    onChangeName(file?.name);
    if (file) {
      setDisabled(true);

      uploadFile(
        file,
        profile?.company_id,
        (res) => {
          setFileUrl(res.file_path);

          onChange({
            type: "file",
            url: res.file_path,
          });

          setProgress(0);
          setDisabled(false);
        },
        (err) => {
          setDisabled(false);
          message.error(err.message);
        },

        (progress) => {
          if (progress === 100) {
            onDisable(false);
          } else {
            onDisable(true);
          }
          setProgress(progress);
        }
      );
    }
  };

  const onChangeUrl = (e) => {
    setUrl(e.target.value);

    onChange({
      type: "url",
      url: e.target.value,
    });
  };

  const onChangeType = (e) => {
    const value = e.target.value;

    setType(value);

    if (value === "url") {
      onChange({
        type: "url",
        url: url,
      });
    } else {
      onChange({
        type: "file",
        url: fileUrl,
      });
    }
  };

  // const renderVideo = () => {
  //   if (transcodingId) {
  //     switch (transcodingStatus) {
  //       case 0:
  //         return (
  //           <Tag icon={<SyncOutlined spin />} color="processing">
  //             Your content is in the process of being created
  //           </Tag>
  //         );

  //       case 1:
  //         return (
  //           <Tag color="red">
  //             Problem with processing your content. Please upload new video
  //           </Tag>
  //         );

  //       case 2:
  //         return defaultUrl ? (
  //           <Player url={`${defaultUrl}?token=${getToken()}`} autoplay={false} />
  //         ) : (
  //           ""
  //         );

  //       default:
  //         return defaultUrl ? (
  //           <Player url={`${defaultUrl}?token=${getToken()}`} autoplay={false} />
  //         ) : (
  //           ""
  //         );
  //     }
  //   }
  // };

  return (
    <div>
      {/* <Radio.Group
        buttonStyle="solid"
        value={type}
        disabled={disabled}
        onChange={onChangeType}
        style={{ marginBottom: 10, width: 200 }}
      >
        <Radio value="file">File</Radio>

        <Radio value="url">Url</Radio>
      </Radio.Group>
      {type === "url" ? (
        <Input
          placeholder="Please input url"
          onChange={onChangeUrl}
          value={url}
        />
      ) : (
        <Upploader
          disabled={disabled}
          accept="video/*"
          onChange={onUpload}
          style={{
            width: "100%"
          }}
        />
      )} */}

      <Upploader
        disabled={disabled}
        accept="video/*"
        onChange={onUpload}
        style={{
          width: "100%",
        }}
      />

      {/* <div style={{ marginTop: 20 }}>{renderVideo()}</div> */}

      {progress ? <Progress percent={progress} size="small" width={"100%"} /> : ""}
    </div>
  );
}

export default VideoTranscode;
