import { useState } from "react";

import { message, notification } from "antd";
import REQUESTS from "../../api/requests";

import AdminsTable from "./AdminsTable";
import AdminDrawer from "./AdminDrawer";

import PageComponent from "../../_components/page/PageComponent";

export default function AdminsPage() {
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [editable, setEditable] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.ADMINS.GET({ query: JSON.stringify(query) })
      .then((response) => {
        onSuccess(response.message);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "delete":
        REQUESTS.ADMINS.DELETE({ id: item.id })
          .then((response) => {
            if (response.error) {
              message.error(response.message);

              return;
            }

            message.success(response.message);

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            message.error(error);
          });

      default:
        break;
    }
  };

  return (
    <PageComponent routes={["Team members"]}>
      <AdminsTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <AdminDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />
    </PageComponent>
  );
}
