import request from "../../../api/request";

const host = `${
  process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"
}admin/`;

const URL = {
  SERVICE_NOTIFICATION: `${host}service_notification`
};

const generalSettingsApi = {
  SERVICE_NOTIFICATION: {
    GET: (params) => request("GET", URL.SERVICE_NOTIFICATION, params),

    CLOSE: (params) => request("PUT", URL.SERVICE_NOTIFICATION, params)
  }
};

export default generalSettingsApi;
