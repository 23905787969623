import { Button, Tooltip } from "antd";
import { useState } from "react";

import NewVideoModal from "./NewVideoModal";

import icons from "../../../../config/icons";

export default function CreateVideo() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Tooltip title="Upload new video">
        <Button
          size="large"
          type="primary"
          shape="circle"
          icon={icons.VIDEO}
          onClick={setIsOpen}
          id="create-video-id"
        ></Button>
      </Tooltip>

      <NewVideoModal open={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
}
