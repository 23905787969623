import { useEffect, useState } from "react";

import { Drawer, Form, notification, Select } from "antd";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";

export default function LanguageDrawer({ onClose, open, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [languagesOptions, setLanguagesOptions] = useState([]);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const body = {
            id: values.id,
        };

        REQUESTS.APPLICATION.LANGUAGES.ENABLE(body)
            .then((response) => {
                setIsLoading(false);

                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });

                    return;
                }

                getData();
                onClose();
            })
            .catch((error) => {
                notification.error({
                    message: "Error",

                    description: error,
                });

                setIsLoading(false);
            });
    };

    const getLanguagesOptions = () => {
        REQUESTS.APPLICATION.LANGUAGES.GET({
            query: JSON.stringify({
                limit: 1000,
                filter: {
                    enabled: false,
                },
            }),
        })

            .then((response) => {
                if (!response.error && response.message?.rows) {
                    const list = response.message.rows.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }));

                    setLanguagesOptions(list);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
        } else {
            getLanguagesOptions();
        }
    }, [open]);

    return (
        <Drawer title="Add language" placement="right" onClose={onClose} open={open}>
            <Form
                form={form}
                name="add-language"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item
                    label="Language"
                    name="id"
                    rules={[
                        {
                            required: true,
                            message: "Please select language",
                        },
                    ]}
                >
                    <Select
                        options={languagesOptions}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) => {
                            return (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare((optionB?.label ?? "").toLowerCase());
                        }}
                    />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
