import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tag, Typography, Tooltip } from "antd";

import icons from "../../config/icons";

import { getColumnDateProps, getQueryDate, parseDate } from "../../config/config";

import Check from "../../_components/table/Check";
import TableButtons from "../../_components/table/TableButtons";
import TableComponent from "../../_components/table/TableComponent";
import ButtonComponent from "../../_components/button/ButtonComponent";

export default function AdsTable({
  getAgain,
  openDrawer,
  openConfigsDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState({
    name: null,
    tariffs: null,
  });

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      fixed: "left",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Content",
      dataIndex: "url",
      key: "url",
      align: "center",
      width: 235,
      render: (text, record, index) => {
        return record.type === "text" ? (
          <Typography.Paragraph ellipsis={true} style={{ margin: 0, width: 210 }}>
            {record.text}
          </Typography.Paragraph>
        ) : (
          <Typography.Paragraph
            ellipsis={true}
            style={{ margin: 0, width: 210 }}
            copyable={{
              tooltips: false,
            }}
          >
            {record.url}
          </Typography.Paragraph>
        );
      },
    },

    {
      title: "Videos",
      dataIndex: "surname",
      key: "surname",
      align: "center",
      ellipsis: true,
      width: 230,
      render: (text, record, index) => {
        return record?.movies?.length > 0 ? (
          record?.movies.map((el) => el.name).join(",")
        ) : record?.all_movies ? (
          <Tag color="green">All Videos Selected</Tag>
        ) : (
          "N/A"
        );
      },
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: 100,
    },

    {
      title: "Tariffs",
      dataIndex: "tariffs",
      key: "tariffs",
      align: "center",
      width: 200,
      ellipsis: true,

      render: (text, record, index) => {
        const tariffsNames = record.tariffs.map((tariff) => {
          return tariff.name;
        });

        const list = tariffsNames.join(", ");

        return list.length ? (
          <Tooltip placement="topLeft" title={list}>
            {list}
          </Tooltip>
        ) : (
          <Check value={false} />
        );
      },
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: 100,
    },

    {
      title: "Full View Count",
      dataIndex: "full_view_count",
      key: "full_view_count",
      align: "center",
      width: 150,
    },

    {
      title: "Part View Count",
      dataIndex: "part_view_count",
      key: "part_view_count",
      align: "center",
      width: 150,
    },

    {
      title: `Created date`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 180,

      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this ads`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);

    const paymentParams = {
      sort: data.sort,
      limit: data.limit,
      page: data.page,
    };
    sessionStorage.setItem("paymentParams", JSON.stringify(paymentParams));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const paymentPage = JSON.parse(sessionStorage.getItem("paymentParams"));

      const query = {
        sort: paymentPage ? paymentPage.sort : sort,
        limit: paymentPage ? paymentPage.limit : limit,
        page: paymentPage ? paymentPage.page : currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name[0],
        };
      }

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      function onSuccess(data) {
        if (data?.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);
        setDataSource(data.rows);

        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, search, date, getAgain]);

  return (
    <TableComponent
      header={
        <>
          <div>
            <ButtonComponent
              icon={icons.CONFIGS}
              title="Configs"
              onClick={openConfigsDrawer}
              style={{ marginRight: 10 }}
            />

            <ButtonComponent icon={icons.ADD} title="Add Ads" onClick={openDrawer} />
          </div>
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 1600,
      }}
    />
  );
}
