
import { Skeleton, Row, Col } from 'antd';
import growth from '../assets/growth.svg'
import ItemDescription from './ItemDescription';

export default function CardChart({
    title,
    value,
    icon,
    description
}) {
    return (
        <div className="card-chart-item">

            <ItemDescription title={title} content={""} />

            <Row>
                <Col xs={{ offset: 1 }}>
                    <div className='item-img-bg concave'>
                        <img src={icon} alt={title} />
                    </div>
                </Col>
                <Col xs={{ offset: 1 }}>
                    <div className='card-values'>
                        {value == null ? <h2><Skeleton.Button active={true} size={"small"} shape={"round"} /></h2> : <h2>{value}</h2>}
                        <img src={growth} />
                    </div>

                </Col>
            </Row>




        </div>
    );
}
