
import { Chart } from "react-google-charts";
import { useEffect, useState } from 'react';
import request from '../../../server/requests';
import ItemDescription from "./ItemDescription";

export default function MapChart() {

    const [map_data, setMapData] = useState([["Country", "Subscribers"]]);

    const [err, setError] = useState(null);


    const getMapData = () => {
        request.statistics.country((data) => {

            let country_array = [
                ["Country", "Subscribers"],
            ];

            for (let i = 0; i < data.length; i++) {
                country_array.push([data[i].country, data[i].count])
            }

            setMapData(country_array);

        }, (err) => {
            setError(err);
        })
    }

    useEffect(() => {
        getMapData();
    }, []);

    return (
        <>

            <ItemDescription title={"Customer by country"} content={"This chart is presenting all customers grouped by their registered countries "} />

            {err != null ?
                <h2>ERROR</h2>
                : <Chart
                    chartType="GeoChart"
                    width="100%"
                    height="350"
                    options={{
                        backgroundColor: "transparent"
                    }}
                    data={map_data}
                />}



        </ >
    );
}
