import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Modal, message, Tabs } from "antd";

import REQUESTS from "../../../../api/requests";
import { useNavigate } from "react-router";
import PATHS from "../../../../config/paths";
import UploadFile from "./upload/UploadFile";
import ImportUrl from "./upload/ImportUrl";

let nameTimeout = null;

export default function NewVideoModal({ open, onClose }) {
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(false);

  const { profile } = useSelector((state) => state.globalState);

  const [isLoading, setIsLoading] = useState(false);

  const [videoData, setVideoData] = useState({
    type: "file",
    url: "",
  });

  const [trailerData, setTrailerData] = useState({
    type: "file",
    url: "",
  });

  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (trailerData.url === "" && videoData.url === "") {
      message.error("Please upload trailer or video");
      return;
    }

    const body = {
      name: values.name,
      company_id: profile?.company_id,
      auto_enable: values.auto_enable,

      // video_input_type: videoData.type,
      // trailer_input_type: trailerData.type
    };

    if (videoData.url) {
      body.video_input = videoData.url;
      body.video_input_type = "file";
    }

    if (trailerData.url) {
      body.trailer_input = trailerData.url;
      body.trailer_input_type = "file";
    }

    setIsLoading(true);

    REQUESTS.VIDEO.ADD(body)

      .then((response) => {
        if (response.error) {
          message.error(response.message);
          setIsLoading(false);
        } else {
          if (response.id) {
            navigate(`${PATHS.EDIT_VIDEO}${response.id}`);
            onClose();
          } else {
            message.error("Something went wrong");
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err.message);
      });
  };

  const checkName = (value) => {
    clearTimeout(nameTimeout);

    nameTimeout = setTimeout(() => {
      const body = {
        name: value,
        company_id: profile?.company_id,
      };

      REQUESTS.VIDEO.CHECK_NAME(body)
        .then((response) => {})
        .catch((err) => {
          if (value) {
            form.setFields([
              {
                name: "name",
                errors: [err.message],
              },
            ]);
          }
        });
    }, 500);
  };

  const onChangeNameFromFile = (value) => {
    const { name } = form.getFieldsValue();

    if (!name) {
      form.setFields([
        {
          name: "name",
          value: value,
          errors: [],
        },
      ]);

      checkName(value);
    }
  };

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleValue = (value) => {
    setIsLoading(true);
    const body = {
      name: value?.name,
      video_id: value?.video_id,
      trailer_id: value?.trailer_id,
      company_id: profile?.company_id,
      auto_enable: value?.auto_enable,
    };

    REQUESTS.VIDEO.TRANSCODER_ADD(body)
      .then((response) => {
        setIsLoading(false);
        console.log(response, "response");
        navigate(`/dashboard/edit-video/${response?.id}`);
        onClose();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err, "err");
      });
  };

  useEffect(() => {
    if (open) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      form.resetFields();
      setDisabled(false);
      setVideoData({
        type: "file",
        url: "",
      });

      setTrailerData({
        type: "file",
        url: "",
      });

      setIsLoading(false);

      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [open]);

  const tabItem = [
    {
      key: "video",
      label: "Upload",
      children: (
        <UploadFile
          form={form}
          onFinish={onFinish}
          check={(value) => checkName(value)}
          open={open}
          setVideoData={setVideoData}
          setDisabled={setDisabled}
          onChangeNameFromFile={onChangeNameFromFile}
          loading={isLoading}
          onClose={() => onClose()}
        />
      ),
    },
    {
      key: "url",
      label: "Import",
      children: (
        <ImportUrl
          open={open}
          disabled={disabled}
          isLoading={isLoading}
          onClose={() => onClose()}
          getValue={(value) => handleValue(value)}
        />
      ),
      disabled: disabled,
    },
  ];

  return (
    <Modal
      title="Create new video"
      open={open}
      width={500}
      footer={false}
      maskClosable={true}
      closable={false}
      destroyOnClose={true}
      disabled={disabled}
    >
      <Tabs defaultActiveKey="video" items={tabItem} />
    </Modal>
  );
}
