import request from "../../../../api/request";
import { DEMO_API_HOST } from "../../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}v2/admin/`;

const URL = {
  LIVE: `${host}live`
};

const goLiveApi = {
  GET: () => request("GET", URL.LIVE),

  EDIT: (data) => request("PUT", URL.LIVE, data)
};

export default goLiveApi;
