export let host = process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/";

export let socket_url =
  process.env.REACT_APP_SOCKET_HOST || "https://demo-socket.inorain.tv/";

export let _host = host;

export const socket_host = socket_url;

export const collectionUrl = host + "test/collection/";
export const collectionsUrl = host + "test/collections/";

export const deviceUrl = host + "admin/devices/";

//TODO
export const soundUrl = host + "test/sound/";
export const soundsUrl = host + "test/sounds/";
export const soundtrackFromYoutubeUrl = host + "test/sounds/youtube/search?token=";

export const transcoderGroupUrl = host + "admin/transcoder/groups/"; //REMOVE
export const transcoderServerUrl = host + "admin/transcoder/server/";

export const usersUrl = host + "admin/users/";

const url = {
  // a
  admin: `${host}admin/`,
  archive: `${host}archive/`,
  // b
  // c

  cast: `${host}movie/cast`,
  conversation: `${host}admin/conversation`,
  chat_message: `${host}admin/chat/message`,
  configs: `${host}admin/configs`,
  category: `${host}admin/category`,
  cast_fetcher: `${host}movie/cast/fetcher`,
  counts_online: `${host}admin/users/counts/online`,
  currency: `${host}payment/currency`,

  // d
  del_collection_movies: `${host}test/collection/movies`,
  // e

  // f

  // g
  geolocation_api: `${host}admin/settings/geolocation_api`,
  // h
  hooks: `${host}admin/hooks/`,

  // i
  // j
  // k
  // l
  languages: `${host}v2/admin/languages`,

  // m
  movie: `${host}admin/movie`,
  movie_statistics: `${host}movie/statistics/`,
  movie_country: `${host}admin/country`,
  movie_fetcher: `${host}movie/fetcher`,
  // n

  // o
  // p
  payment_settings: `${host}payment/payment_settings`,
  payment: `${host}payment/`,
  permission_menu: `${host}admin/menu/permission`,
  // q
  quick_messages: `${host}admin/quick_messages`,

  // r
  reseller: `${host}reseller/`,
  resolution: `${host}admin/resolutions/`,

  // s
  sound: `${host}test/sound/`,
  soundtrack: `${host}test/sounds/`,
  settings: `${host}admin/settings/`,
  smtp_notifications: `${host}admin/notifications/smtp`,
  send_message: `${host}admin/chat/send/message`,
  statistics: `${host}v2/admin/statistics`,
  // t

  tariff: `${host}admin/tariff/`,
  transcode_movie: `${host}movieTranscoder/transcode_movie`,
  tvod: `${host}admin/movie/pricing`,

  // u
  users: `${host}admin/users/`,

  // v
  // w
  widgets: `${host}admin/widgets`,

  // x
  // y
  // z
};

export default url;
