import request from "../../request";

const host = `${process.env.REACT_APP_API_HOST || "https://demo-api.inorain.tv/"}`;

const URL = {
    PRICING: `${host}admin/movie/pricing`,
};

export default {
    GET: (query) => request("GET", URL.PRICING, query),

    ADD: (query) => request("POST", URL.PRICING, query),

    EDIT: (query) => request("PUT", URL.PRICING, query),

    DELETE: (query) => request("DELETE", URL.PRICING, query),
};
