import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Badge, message } from "antd";

import REQUESTS from "../../../../api/requests";
import ButtonComponent from "../../../../_components/ButtonComponent";
import ImageUpload from "../../../../components/ImageUpload";

import styles from "./go_live.module.scss";
import Player from "../../../../_components/Player";

export default function GoLiveModal({ open, onClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const [isStarted, setIsStarted] = useState(false);

  const [form] = Form.useForm();

  const [poster, setPoster] = useState({
    url: null,
    file: null
  });

  const [backdrop, setBackdrop] = useState({
    url: null,
    file: null
  });

  const getData = () => {
    REQUESTS.GO_LIVE.GET()
      .then((response) => {
        if (response.error) {
          message.error(response.message);

          return;
        }

        if (response.message) {
          const data = response.message;

          form.setFields([
            {
              name: "name",
              value: data.name
            },

            {
              name: "description",
              value: data.description
            },

            {
              name: "url",
              value: data.url
            }
          ]);

          if (data.poster) {
            setPoster({
              url: data.poster,
              file: null
            });
          }

          if (data.backdrop) {
            setBackdrop({
              url: data.backdrop,
              file: null
            });
          }

          setIsStarted(data.is_started);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    if (poster.file) {
      formData.append("poster", poster.file);
    }

    if (backdrop.file) {
      formData.append("backdrop", backdrop.file);
    }

    formData.append("name", values.name);
    formData.append("url", values.url);

    formData.append("description", values.description);

    formData.append("is_started", !isStarted);

    REQUESTS.GO_LIVE.EDIT(formData)
      .then((response) => {
        setIsLoading(false);
        if (response.error) {
          message.error(response.message);

          return;
        }

        message.success("Your live stream has been updated successfully");

        getData();
      })
      .catch((err) => {
        message.error(err.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      form.resetFields();

      getData();
    } else {
      setBackdrop({
        url: null,
        file: null
      });

      setPoster({
        url: null,
        file: null
      });

      form.setFields([
        {
          name: "url",
          value: ""
        }
      ]);

      setIsStarted(false);

      setIsLoading(false);
    }
  }, [open]);

  return (
    <Modal
      title="Go Live"
      open={open}
      onCancel={onClose}
      closable={true}
      footer={false}
      width={750}
    >
      <Form
        form={form}
        name="go-live"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
      >
        <div className={styles["go-live-container"]}>
          <div className={styles["info-block"]}>
            <div className={styles["images-block"]}>
              <Form.Item
                label="Poster"
                name="poster"
                style={{ marginBottom: 10 }}
              >
                <ImageUpload
                  image={poster}
                  setImage={setPoster}
                  disabled={isStarted}
                />
              </Form.Item>

              <Form.Item
                label="Backdrop"
                name="backdrop"
                style={{ marginBottom: 10 }}
              >
                <ImageUpload
                  image={backdrop}
                  setImage={setBackdrop}
                  disabled={isStarted}
                />
              </Form.Item>
            </div>

            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input name"
                }
              ]}
              style={{ marginBottom: 10 }}
            >
              <Input disabled={isStarted} />
            </Form.Item>

            <Form.Item
              label="Url"
              name="url"
              rules={[
                {
                  required: true,
                  message: "Please input url"
                }
              ]}
              style={{ marginBottom: 10 }}
            >
              <Input disabled={isStarted} />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              style={{ marginBottom: 15 }}
            >
              <Input.TextArea
                rows={5}
                style={{ resize: "none" }}
                disabled={isStarted}
              />
            </Form.Item>

            <ButtonComponent
              style={{ width: "100%" }}
              title={isStarted ? "Stop" : "Go live"}
              danger={isStarted}
              isLoading={isLoading}
              onClick={() => form.submit()}
            />
          </div>

          {isStarted && (
            <Form.Item shouldUpdate noStyle>
              {() => {
                const { url } = form.getFieldsValue();

                return (
                  <div className={styles["video-block"]}>
                    <Badge color="green" text="Live Stream" />

                    <div style={{ marginTop: 10 }}>
                      <Player url={url || ""} autoplay={false} />
                    </div>
                  </div>
                );
              }}
            </Form.Item>
          )}
        </div>
      </Form>
    </Modal>
  );
}
