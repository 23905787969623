import { Input, DatePicker } from "antd";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";

const saveTokenToLocalStorage = (token) => {
  window.localStorage.setItem("admtoken", token);
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const getColumnDateProps = (onChange) => ({
  filterDropdown: ({}) => (
    <div
      style={{
        padding: 8
      }}
    >
      <DatePicker.RangePicker
        onChange={(e, dateString) => onChange(dateString)}
        renderExtraFooter={() => "extra footer"}
      />
    </div>
  ),
  filterIcon: (filtered) => (
    <CalendarOutlined
      style={{
        color: filtered ? "#1890ff" : undefined
      }}
    />
  )
});

const parseDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const hours = d.getHours();
  let month = d.getMonth() + 1;
  let minutes = d.getMinutes();
  let day = d.getDate();

  if (day < 10) {
    day = `0${day}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const parseDateFormat = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const hours = d.getHours();
  let month = d.getMonth() + 1;
  let minutes = d.getMinutes();
  let day = d.getDate();

  if (day < 10) {
    day = `0${day}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${day}/${month}/${year}`;
};

const getQueryDate = (date, type = "createdAt") => {
  if (date && date[0]) {
    let to = new Date(date[1]);
    to.setDate(to.getDate() + 1);

    return {
      [type]: {
        from: new Date(date[0]),
        to: new Date(to)
      }
    };
  }

  return null;
};

const getColumnSearchProps = () => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
    return (
      <Input
        allowClear
        placeholder="Type text here"
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
          confirm({ closeDropdown: false });
        }}
      />
    );
  },
  filterIcon: () => {
    return (
      <>
        <SearchOutlined />
      </>
    );
  }
});

const scrollToTopOfDrawerBody = () => {
  setTimeout(() => {
    if (document.getElementsByClassName("ant-drawer-body")[0]) {
      document.getElementsByClassName("ant-drawer-body")[0].scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }, 500);
};

const returnGender = (gender) => {
  switch (gender) {
    case 0:
      return "Unknown";
    case 1:
      return "Female";

    case 2:
      return "Male";

    default:
      return "Unknown";
  }
};

const outputTypeOptions = [
  {
    label: "HLS",
    value: "hls"
  },

  {
    label: "RTMP",
    value: "rtmp"
  },

  {
    label: "UDP",
    value: "udp"
  }
];

const pgOptions = [
  {
    label: "0 – Not suitable for below 10. Must be 10 and older.",
    value: 0
  },

  {
    label:
      "10-12 PG – Not suitable for below 10, suitable for 10-12 but parent or caregiver must be present for the duration of the film, suitable for 13 and older.",
    value: 10
  },

  {
    label: "13 – Not suitable for below 13. Must be 13 and older.",
    value: 13
  },

  {
    label: "16 – Not suitable for below 16. Must be 16 and older",
    value: 16
  },

  {
    label: "18 – Not suitable for below 18. Must be 18 and older.",
    value: 18
  }
];

export {
  saveTokenToLocalStorage,
  getBase64,
  getColumnDateProps,
  parseDate,
  getQueryDate,
  getColumnSearchProps,
  scrollToTopOfDrawerBody,
  parseDateFormat,
  returnGender,
  outputTypeOptions,
  pgOptions
};
