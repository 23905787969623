import { useState } from "react";
import { message } from "antd";

import REQUESTS from "../../../api/requests";

import ManageMoviesDrawer from "./ManageMoviesDrawer";
import VodCollectionDrawer from "./VodCollectionDrawer";
import VodCollectionsTable from "./VodCollectionsTable";

import PageComponent from "../../../_components/page/PageComponent";

export default function VodCollectionsPage({}) {
  const [getAgain, setGetAgain] = useState(false);

  const [editable, setEditable] = useState(null);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [collectionForManageMovies, setCollectionForManageMovies] =
    useState(null);

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "manage_movies":
        setCollectionForManageMovies(item);
        break;

      case "delete":
        REQUESTS.VOD.COLLECTIONS.DELETE({ id: item.id })
          .then((response) => {
            if (response.error) {
              message.error(response.message);

              return;
            }

            message.success("Collection deleted successfully");

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            message.error(error);
          });

        break;

      default:
        break;
    }
  };

  const getDataSource = (onSuccess, onError) => {
    const query = {
      limit: 1000
    };

    REQUESTS.VOD.COLLECTIONS.GET({ query: JSON.stringify(query) })
      .then((response) => {
        if (!response.error) {
          onSuccess(response?.message?.rows || []);
        }
      })
      .catch((error) => {
        onError(error);
      });
  };

  const changePosition = (ids, callback) => {
    const query = {
      collectionIds: ids
    };

    REQUESTS.VOD.COLLECTIONS.SORT(query)
      .then((response) => {
        if (response.error) {
          message.error(response.message);

          return;
        }

        message.success("Collections sorted successfully");

        callback();
      })

      .catch(() => {
        callback();
      });
  };

  const onVisible = (id, isVisible) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("visible", isVisible);

    REQUESTS.VOD.COLLECTIONS.EDIT(formData)
      .then((response) => {
        if (!response.error) {
          message.success("Collection edited successfully");
          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };

  return (
    <PageComponent routes={["Contents", "Collections"]}>
      <VodCollectionsTable
        getAgain={getAgain}
        onVisible={onVisible}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        changePosition={changePosition}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <VodCollectionDrawer
        open={isOpenDrawer}
        editable={editable}
        onClose={() => {
          setEditable(null);
          setIsOpenDrawer(false);
        }}
        getData={() => setGetAgain((prev) => !prev)}
      />

      <ManageMoviesDrawer
        collection={collectionForManageMovies}
        open={collectionForManageMovies ? true : false}
        onClose={() => setCollectionForManageMovies(null)}
      />
    </PageComponent>
  );
}
