import { vodServiceId } from "../../../../config/statics";

export default function initEditableSubscriptionData(
  form,
  editable,
  setVodSeervice,
  permissions
) {
  form.setFields([
    { name: "name", value: editable.name },
    { name: "description", value: editable.description },
    { name: "description", value: editable.description },
    { name: "price", value: editable.price },
    { name: "device_service", value: editable.device_service },
    { name: "payment_type", value: editable.payment_type },
    { name: "scheduling_type", value: editable.scheduling_type },
    { name: "scheduling_time", value: editable.scheduling_time }
  ]);

  if (permissions?.payments?.authorize) {
    form.setFields([
      {
        name: "is_supported_authorize",
        value: editable.is_supported_authorize
      }
    ]);
  }

  if (permissions?.payments?.paypal) {
    form.setFields([
      { name: "paypal_plan_id", value: editable.paypal_plan_id }
    ]);
  }

  if (permissions?.payments?.stripe) {
    form.setFields([
      { name: "stripe_plan_id", value: editable.stripe_plan_id }
    ]);
  }

  if (permissions?.payments?.apple) {
    form.setFields([
      { name: "apple_subscription_id", value: editable.apple_subscription_id }
    ]);
  }

  if (permissions?.payments?.google) {
    form.setFields([
      { name: "google_subscription_id", value: editable.google_subscription_id }
    ]);
  }

  const servicesList = editable.service;

  for (let i = 0; i < servicesList.length; i++) {
    if (servicesList[i].serviceId == vodServiceId) {
      if (servicesList[i].filter) {
        const vodFilter = JSON.parse(servicesList[i].filter);
        setVodSeervice({
          category: {
            type: vodFilter?.category?.type,
            values: vodFilter?.category?.values
          },

          id: {
            exclude: vodFilter?.id?.exclude || [],
            include: vodFilter?.id?.include || []
          },

          year: {
            type: vodFilter?.year?.type,
            values: vodFilter?.year?.values
          }
        });
      }
    }
  }
}
