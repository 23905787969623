
import { Skeleton } from 'antd';

import { Col, Row } from 'antd';
import like from '../assets/mdi_like.svg'
import dislike from '../assets/dislike.svg'
import eye from '../assets/eye.svg';
import { useEffect, useState } from 'react';
import requests from "../../../server/requests";
import ItemDescription from './ItemDescription';

export default function TopMovies() {

    const [movies, setMovies] = useState([]);
    const [err, setError] = useState(null);

    const getTopMovies = () => {

        requests.statistics.top_watched({ limit: 5 }, (data) => {
            setMovies(data);
        }, (err) => {
            setError(err)
        })

    }

    useEffect(() => {
        getTopMovies();
    }, [])

    return (
        <>

            <ItemDescription title={"Top 5 watched videos"} content={"Under this section you will see your top 5 videos list information based on the views counts"} />
            {err != null ?
                <div>ERROR</div>
                :

                movies.length < 1 ?
                    <Col span={24}>
                        <Skeleton active />
                    </Col>

                    : movies.map((item) => {
                        return <Row key={item.id} className="list">
                            <Col xs={{ span: 4, offset: 1 }} lg={{ span: 2, offset: 1 }}>
                                <img className="movie_poster" src={item.poster} alt={item.anme} />
                            </Col>
                            <Col xs={{ span: 6, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                                <h3>{item.name}</h3>
                            </Col>
                            <Col xs={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }}>
                                <ul className={"more_video_items"}>
                                    <li>
                                        <img src={like} alt="Like" /> {item.like}
                                    </li>
                                    <li>
                                        <img src={dislike} alt="Dislike" /> {item.dislike}
                                    </li>
                                    <li>
                                        <img src={eye} alt="Views" /> {item.views_count}
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    })


            }

        </>

    );
}
