import { useState } from "react";

import { Form, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";

export default function NewTranslation({ getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        const body = {
            key: values.key,
        };

        REQUESTS.APPLICATION.TRANSLATIONS.ADD(body)
            .then((response) => {
                setIsLoading(false);
                if (!response.error) {
                    notification.success({
                        message: "Success",
                        description: "New translation added successfully",
                    });

                    getData();

                    form.resetFields();
                } else {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Error",
                    description: error,
                });

                setIsLoading(false);
            });
    };

    return (
        <Form
            form={form}
            name="add-translation"
            layout="inline"
            onFinish={onFinish}
            onKeyPress={(e) => {
                if (e.key === "Enter") {
                    form.submit();
                }
            }}
        >
            <Form.Item name="key" label="New translation">
                <Input />
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }} shouldUpdate noStyle>
                {() => {
                    const { key } = form.getFieldsValue();

                    return (
                        <ButtonComponent
                            title="Save"
                            actionType="save"
                            disabled={!key}
                            isLoading={isLoading}
                            onClick={() => form.submit()}
                        />
                    );
                }}
            </Form.Item>
        </Form>
    );
}
