import request from "../../../../api/request";
import { DEMO_API_HOST } from "../../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  MOVIE: `${host}admin/movie`,

  MOVIES: `${host}v2/admin/movies`,

  GALLERY: `${host}admin/movie/galery`
};

const videosApi = {
  GET: (query) => request("GET", URL.MOVIES, query),

  DELETE: (query) => request("DELETE", URL.MOVIE, query),

  HIGHLIGHTED: (query) => request("POST", `${URL.MOVIE}/highlighted`, query),

  ENABLE_OR_DISABLE: (type, query) =>
    request("PUT", `${URL.MOVIE}/${type}`, query),

  BY_ID: (id) => request("GET", `${URL.MOVIE}?id=${id}`),

  GALLERY: {
    ADD: (query) => request("POST", URL.GALLERY, query),

    DELETE: (query) => request("DELETE", URL.GALLERY, query)
  }
};

export default videosApi;
