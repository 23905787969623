import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MobileOutlined,
  LockOutlined,
  UnlockOutlined,
  TagsOutlined,
  MinusOutlined,
  SettingOutlined,
  SafetyCertificateOutlined,
  CloseCircleOutlined,
  SendOutlined,
  FolderOpenOutlined,
  CodeOutlined,
  DragOutlined,
  LeftOutlined,
  InfoCircleOutlined,
  LoginOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
  InstagramOutlined,
  BorderlessTableOutlined,
  LikeOutlined,
  DislikeOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  VideoCameraAddOutlined
} from "@ant-design/icons";

import Icon from "@ant-design/icons";
export default {
  // a
  ADD: <PlusOutlined />,
  ARCHIVE_ICON: <i className="fas fa-archive" />,

  ARROW_UP: <ArrowUpOutlined />,
  ARROW_DOWN: <ArrowDownOutlined />,

  ARROW_LEFT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
    </svg>
  ),

  ANDROID_MOBILE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M398 931h164v-40H398v40Zm-138 85q-24 0-42-18t-18-42V196q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-150v90h440v-90H260Zm0-60h440V286H260v520Zm0-580h440v-30H260v30Zm0 640v90-90Zm0-640v-30 30Z" />
    </svg>
  ),

  ADMIN: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M480 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160 896v-94q0-38 19-65t49-41q67-30 128.5-45T480 636q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800 764 800 802v94H160Zm60-60h520v-34q0-16-9.5-30.5T707 750q-64-31-117-42.5T480 696q-57 0-111 11.5T252 750q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T570 425q0-39-25.5-64.5T480 335q-39 0-64.5 25.5T390 425q0 39 25.5 64.5T480 515Zm0-90Zm0 411Z" />
    </svg>
  ),

  ADS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M468 816q-96-5-162-74t-66-166q0-100 70-170t170-70q97 0 166 66t74 163l-63-20q-11-64-60-106.5T480 396q-75 0-127.5 52.5T300 576q0 67 42.5 116.5T449 753l19 63Zm48 158q-9 1-18 1.5t-18 .5q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 9-.5 18t-1.5 18l-58-18v-18q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 142 99 241t241 99h18l18 58Zm305 22L650 825l-50 151-120-400 400 120-151 50 171 171-79 79Z" />
    </svg>
  ),

  APPLICATION_SETTINGS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M452 770h56l10-54q20-6 34-15t26-21l62 19 26-54-47-30q4-21 4-39t-4-39l47-30-26-54-62 19q-12-12-26-21t-34-15l-10-54h-56l-10 54q-20 6-34 15t-26 21l-62-19-26 54 47 30q-4 21-4 39t4 39l-47 30 26 54 62-19q12 12 26 21t34 15l10 54Zm28-109q-36 0-60.5-24.5T395 576q0-36 24.5-60.5T480 491q36 0 60.5 24.5T565 576q0 36-24.5 60.5T480 661ZM180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600V276H180v600Zm0-600v600-600Z" />
    </svg>
  ),

  ARCHIVE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M120 896V748h720v148H120Zm60-38h72v-72h-72v72Zm-60-454V256h720v148H120Zm60-38h72v-72h-72v72Zm-60 284V502h720v148H120Zm60-38h72v-72h-72v72Z" />
    </svg>
  ),

  // b
  BACK: <LeftOutlined />,
  // c
  CHAT: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2C0.447715 2 0 2.44772 0 3V12C0 12.5523 0.447715 13 1 13H14C14.5523 13 15 12.5523 15 12V3C15 2.44772 14.5523 2 14 2H1ZM1 3L14 3V3.92494C13.9174 3.92486 13.8338 3.94751 13.7589 3.99505L7.5 7.96703L1.24112 3.99505C1.16621 3.94751 1.0826 3.92486 1 3.92494V3ZM1 4.90797V12H14V4.90797L7.74112 8.87995C7.59394 8.97335 7.40606 8.97335 7.25888 8.87995L1 4.90797Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),

  CHECK: <CheckOutlined />,

  CONFIGS: <SettingOutlined />,

  CLOSE: <i className="fa fa-times" aria-hidden="true" />,

  // d
  DISLIKE: <DislikeOutlined />,
  DASHBOARD: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M160 896V456h140v440H160Zm250 0V256h140v640H410Zm250 0V616h140v280H660Z" />
    </svg>
  ),
  DELETE: <i className="fas fa-trash-alt" />,

  // e

  EDIT: <i className="fas fa-pencil-alt" />,
  // f
  FOLDER: <FolderOpenOutlined />,
  // g
  GALLERY: <i className="fas fa-images" />,
  GOOGLE: (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.54 11.7614C22.54 10.9459 22.4668 10.1618 22.3309 9.40909H11.5V13.8575H17.6891C17.4225 15.295 16.6123 16.513 15.3943 17.3284V20.2139H19.1109C21.2855 18.2118 22.54 15.2636 22.54 11.7614Z"
        fill="#4285F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 22.9998C14.605 22.9998 17.2081 21.9701 19.1109 20.2137L15.3943 17.3282C14.3645 18.0182 13.0472 18.426 11.5 18.426C8.50474 18.426 5.96951 16.403 5.06519 13.6848H1.22314V16.6644C3.11542 20.4228 7.00451 22.9998 11.5 22.9998Z"
        fill="#34A853"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.06523 13.6851C4.83523 12.9951 4.70455 12.258 4.70455 11.5001C4.70455 10.7421 4.83523 10.0051 5.06523 9.31506V6.33552H1.22318C0.444318 7.88802 0 9.64438 0 11.5001C0 13.3557 0.444318 15.1121 1.22318 16.6646L5.06523 13.6851Z"
        fill="#FBBC05"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 4.57386C13.1884 4.57386 14.7043 5.15409 15.8961 6.29364L19.1945 2.99523C17.2029 1.13955 14.5997 0 11.5 0C7.00451 0 3.11542 2.57705 1.22314 6.33545L5.06519 9.315C5.96951 6.59682 8.50474 4.57386 11.5 4.57386Z"
        fill="#EA4335"
      />
    </svg>
  ),
  // h
  HISTORY: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M142 449V241h60v106q52-61 123.5-96T477 216q151 0 257 104t106 254v10h-60v-13q0-124-89-209.5T477 276q-68 0-127.5 31T246 389h105v60H142Zm-22 126h60q0 125 86 213t211 88h12l34 57q-11 2-22.5 2.5t-23.5.5q-149 0-253-105.5T120 575Zm440 119L451 587V373h60v189l81 79-32 53Zm175 352-10-66q-18-5-34-13.5T661 944l-55 12-25-42 47-44q-3-12-3-25t3-25l-47-44 25-42 55 12q14-14 30-22.5t34-13.5l10-66h54l10 66q18 5 34 13.5t30 22.5l55-12 25 42-47 44q3 12 3 25t-3 25l47 44-25 42-55-12q-14 14-30 22.5T799 980l-10 66h-54Zm27-121q36 0 58-22t22-58q0-36-22-58t-58-22q-36 0-58 22t-22 58q0 36 22 58t58 22Z" />
    </svg>
  ),
  // i
  INFO: <InfoCircleOutlined />,
  INSTAGRAM: <InstagramOutlined />,
  // j
  // k
  // l
  LOGIN: <LoginOutlined />,
  LOGOUT: <LogoutOutlined />,

  LOGS: <CodeOutlined />,
  LOCK: <LockOutlined />,
  LIKE: <LikeOutlined />,

  LIVE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M197 859q-54-54-85.5-126.5T80 576q0-84 31.5-156.5T197 293l43 43q-46 46-73 107.5T140 576q0 71 26.5 132T240 816l-43 43Zm113-113q-32-32-51-75.5T240 576q0-51 19-94.5t51-75.5l43 43q-24 24-38.5 56.5T300 576q0 38 14 70t39 57l-43 43Zm170-90q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm170 90-43-43q24-24 38.5-56.5T660 576q0-38-14-70t-39-57l43-43q32 32 51 75.5t19 94.5q0 50-19 93.5T650 746Zm113 113-43-43q46-46 73-107.5T820 576q0-71-26.5-132T720 336l43-43q54 55 85.5 127.5T880 576q0 83-31.5 155.5T763 859Z"></path>
    </svg>
  ),

  // m
  MOBILE: <MobileOutlined />,
  MINUS: <MinusOutlined />,
  MOVE: <DragOutlined />,
  MORE_APPS: <AppstoreAddOutlined />,
  // n
  NOTIFICATIONS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M480 976q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576v53q0 56-39.5 94.5T744 762q-36 0-68-17.5T627 695q-26 34-65 50.5T480 762q-78 0-132.5-54T293 576q0-78 54.5-133T480 388q78 0 132.5 55T667 576v53q0 31 22.5 52t54.5 21q31 0 53.5-21t22.5-52v-53q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 142 99 241t241 99h214v60H480Zm0-274q53 0 90-36.5t37-89.5q0-54-37-91t-90-37q-53 0-90 37t-37 91q0 53 37 89.5t90 36.5Z" />
    </svg>
  ),
  // o
  ONLINE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763l36 36q-49 47-76 111.5T130-480q0 71 27 135.5T233-233l-36 36Zm80-80q-38-38-62.5-91.5T190-480q0-58 24.5-111.5T277-683l35 35q-34 30-53 76t-19 92q0 46 19 92t53 76l-35 35Zm165-23q0-21-15-43t-32.5-45Q377-411 362-436.5T347-490q0-55 39-94t94-39q55 0 94 39t39 94q0 28-15 53.5T565.5-388Q548-365 533-343t-15 43h-76Zm-2 90v-50h80v50h-80Zm243-67-35-35q34-30 53-76t19-92q0-46-19-92t-53-76l35-35q38 38 62.5 91.5T770-480q0 58-24.5 111.5T683-277Zm80 80-36-36q49-47 76-111.5T830-480q0-71-27-135.5T727-727l36-36q54 54 85.5 127T880-480q0 83-31.5 156T763-197Z" />
    </svg>
  ),
  // p
  PUBLISH: <i className="fa fa-download" />,

  PLAY: <PlayCircleOutlined />,

  PAUSE: <PauseCircleOutlined />,

  PAYMENTS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M540 636q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM220 776q-24.75 0-42.375-17.625T160 716V316q0-24.75 17.625-42.375T220 256h640q24.75 0 42.375 17.625T920 316v400q0 24.75-17.625 42.375T860 776H220Zm100-60h440q0-42 29-71t71-29V416q-42 0-71-29t-29-71H320q0 42-29 71t-71 29v200q42 0 71 29t29 71Zm480 180H100q-24.75 0-42.375-17.625T40 836V376h60v460h700v60ZM220 716V316v400Z" />
    </svg>
  ),

  PROFILE: <UserOutlined />,

  PROTECT: <SafetyCertificateOutlined />,

  PHONE: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 10.5L22.1667 5.83334M22.1667 5.83334V9.33334M22.1667 5.83334H18.6667"
        stroke="white"
        stroke-width="1.23853"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.1485 16.9727L17.6177 17.5327C17.6177 17.5327 16.3542 18.8615 12.9067 15.232C9.45917 11.6025 10.7227 10.2737 10.7227 10.2737L11.0575 9.92016C11.8812 9.05216 11.9593 7.65916 11.2395 6.64183L9.7695 4.56166C8.87817 3.30166 7.15733 3.136 6.1365 4.2105L4.306 6.13666C3.80083 6.66983 3.4625 7.35933 3.50333 8.12583C3.60833 10.0858 4.446 14.301 9.11733 19.2208C14.0722 24.4358 18.7213 24.6435 20.6218 24.4557C21.2238 24.3973 21.7465 24.0718 22.1677 23.6285L23.8243 21.8832C24.9443 20.7048 24.6293 18.6865 23.1967 17.8628L20.9683 16.5795C20.028 16.0393 18.8835 16.198 18.1485 16.9715V16.9727Z"
        fill="white"
      />
    </svg>
  ),

  // q
  // r
  RESTORE: <i className="fas fa-window-restore" />,

  // s
  SEND: <SendOutlined />,

  SETTINGS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="m388 976-20-126q-19-7-40-19t-37-25l-118 54-93-164 108-79q-2-9-2.5-20.5T185 576q0-9 .5-20.5T188 535L80 456l93-164 118 54q16-13 37-25t40-18l20-127h184l20 126q19 7 40.5 18.5T669 346l118-54 93 164-108 77q2 10 2.5 21.5t.5 21.5q0 10-.5 21t-2.5 21l108 78-93 164-118-54q-16 13-36.5 25.5T592 850l-20 126H388Zm92-270q54 0 92-38t38-92q0-54-38-92t-92-38q-54 0-92 38t-38 92q0 54 38 92t92 38Zm0-60q-29 0-49.5-20.5T410 576q0-29 20.5-49.5T480 506q29 0 49.5 20.5T550 576q0 29-20.5 49.5T480 646Zm0-70Zm-44 340h88l14-112q33-8 62.5-25t53.5-41l106 46 40-72-94-69q4-17 6.5-33.5T715 576q0-17-2-33.5t-7-33.5l94-69-40-72-106 46q-23-26-52-43.5T538 348l-14-112h-88l-14 112q-34 7-63.5 24T306 414l-106-46-40 72 94 69q-4 17-6.5 33.5T245 576q0 17 2.5 33.5T254 643l-94 69 40 72 106-46q24 24 53.5 41t62.5 25l14 112Z" />
    </svg>
  ),

  SUBSCRIPTION: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M480-40q-112 0-216-66T100-257v137H40v-240h240v60H143q51 77 145.5 138.5T480-100q78 0 147.5-30t121-81.5Q800-263 830-332.5T860-480h60q0 91-34.5 171T791-169q-60 60-140 94.5T480-40Zm-29-153v-54q-45-12-75.5-38.5T324-358l51-17q12 38 42.5 60t69.5 22q40 0 66.5-19.5T580-364q0-33-25-55.5T463-470q-60-25-90-54t-30-78q0-44 30-75t80-38v-51h55v51q38 4 66 24t45 55l-48 23q-15-28-37-42t-52-14q-39 0-61.5 18T398-602q0 32 26 51t84 43q69 29 98 61t29 83q0 25-9 46t-25.5 36Q584-267 560-257.5T506-245v52h-55ZM40-480q0-91 34.5-171T169-791q60-60 140-94.5T480-920q112 0 216 66t164 151v-137h60v240H680v-60h137q-51-77-145-138.5T480-860q-78 0-147.5 30t-121 81.5Q160-697 130-627.5T100-480H40Z" />
    </svg>
  ),

  SOUNDTRACKS: <i className="fa fa-music" />,
  // t
  TARIFF: <TagsOutlined />,

  TV: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M170 936v-80h-30q-24.75 0-42.375-17.625T80 796V316q0-24.75 17.625-42.375T140 256h680q24.75 0 42.375 17.625T880 316v480q0 24.75-17.625 42.375T820 856h-30v80h-23l-26-80H220l-27 80h-23Zm-30-140h680V316H140v480Zm340-240Z" />
    </svg>
  ),

  TEAM_MEMBERS: <UsergroupAddOutlined />,
  // u
  UNLOCK: <UnlockOutlined />,

  UNPROTECT: <CloseCircleOutlined />,

  USERS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M38 896v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358 636q62 0 120 14t131 46q32 14 50.5 42.5T678 802v94H38Zm700 0v-94q0-63-32-103.5T622 633q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519 568q24-25 36.5-61.5T568 425q0-45-12.5-79.5T519 282q11-3 24.5-5t24.5-2q66 0 108 42t42 108ZM98 836h520v-34q0-16-9.5-31T585 750q-72-32-121-43t-106-11q-57 0-106.5 11T130 750q-14 6-23 21t-9 31v34Zm260-321q39 0 64.5-25.5T448 425q0-39-25.5-64.5T358 335q-39 0-64.5 25.5T268 425q0 39 25.5 64.5T358 515Zm0 321Zm0-411Z" />
    </svg>
  ),

  USERS_GROUP: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M0-240v-53q0-38.567 41.5-62.784Q83-380 150.376-380q12.165 0 23.395.5Q185-379 196-377.348q-8 17.348-12 35.165T180-305v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-19.861-3.5-37.431Q773-360 765-377.273q11-1.727 22.171-2.227 11.172-.5 22.829-.5 67.5 0 108.75 23.768T960-293v53H780Zm-480-60h360v-6q0-37-50.5-60.5T480-390q-79 0-129.5 23.5T300-305v5ZM149.567-410Q121-410 100.5-430.562 80-451.125 80-480q0-29 20.562-49.5Q121.125-550 150-550q29 0 49.5 20.5t20.5 49.933Q220-451 199.5-430.5T149.567-410Zm660 0Q781-410 760.5-430.562 740-451.125 740-480q0-29 20.562-49.5Q781.125-550 810-550q29 0 49.5 20.5t20.5 49.933Q880-451 859.5-430.5T809.567-410ZM480-480q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm.351-60Q506-540 523-557.351t17-43Q540-626 522.851-643t-42.5-17Q455-660 437.5-642.851t-17.5 42.5Q420-575 437.351-557.5t43 17.5ZM480-300Zm0-300Z" />
    </svg>
  ),

  UNPUBLISH: <i className="fa fa-upload" />,

  // v
  VOD: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M314 853V689l132 82-132 82Zm206-382q-45.833 0-77.917-32.118-32.083-32.117-32.083-78Q410 315 442.083 283q32.084-32 77.917-32h40v40h-40q-29.167 0-49.583 20.382Q450 331.765 450 360.882 450 390 470.417 410.5 490.833 431 520 431h40v40h-40Zm120 0v-40h40q29.167 0 49.583-20.382Q750 390.235 750 361.118 750 332 729.583 311.5 709.167 291 680 291h-40v-40h40q45.833 0 77.917 32.118 32.083 32.117 32.083 78Q790 407 757.917 439 725.833 471 680 471h-40Zm-110-90v-40h140v40H530Zm123 265v-80h207V196H340v370h-60V196q0-24.75 17.625-42.375T340 136h520q24.75 0 42.375 17.625T920 196v390q0 24.75-17.625 42.375T860 646H653Zm-553 370q-24.75 0-42.375-17.625T40 956V586q0-24.75 17.625-42.375T100 526h520q24.75 0 42.375 17.625T680 586v370q0 24.75-17.625 42.375T620 1016H100Zm0-60h520V586H100v370Zm500-575ZM360 771Z" />
    </svg>
  ),

  VIDEO: <VideoCameraAddOutlined />,
  // w

  WEB: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M480 976q-84 0-157-31.5T196 859q-54-54-85-127.5T80 574q0-84 31-156.5T196 291q54-54 127-84.5T480 176q84 0 157 30.5T764 291q54 54 85 126.5T880 574q0 84-31 157.5T764 859q-54 54-127 85.5T480 976Zm0-58q35-36 58.5-82.5T577 725H384q14 60 37.5 108t58.5 85Zm-85-12q-25-38-43-82t-30-99H172q38 71 88 111.5T395 906Zm171-1q72-23 129.5-69T788 725H639q-13 54-30.5 98T566 905ZM152 665h159q-3-27-3.5-48.5T307 574q0-25 1-44.5t4-43.5H152q-7 24-9.5 43t-2.5 45q0 26 2.5 46.5T152 665Zm221 0h215q4-31 5-50.5t1-40.5q0-20-1-38.5t-5-49.5H373q-4 31-5 49.5t-1 38.5q0 21 1 40.5t5 50.5Zm275 0h160q7-24 9.5-44.5T820 574q0-26-2.5-45t-9.5-43H649q3 35 4 53.5t1 34.5q0 22-1.5 41.5T648 665Zm-10-239h150q-33-69-90.5-115T565 246q25 37 42.5 80T638 426Zm-254 0h194q-11-53-37-102.5T480 236q-32 27-54 71t-42 119Zm-212 0h151q11-54 28-96.5t43-82.5q-75 19-131 64t-91 115Z" />
    </svg>
  ),

  WELCOME: <BorderlessTableOutlined />,
  // x
  X: <CloseOutlined />
  // y
  // z
};
