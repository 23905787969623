import { Popconfirm, Button, message } from "antd";

import REQUESTS from "../../api/requests";

export default function RemoveAccountTab({}) {
  const onRemove = () => {
    REQUESTS.PROFILE.REMOVE({})
      .then((response) => {
        if (response.error) {
          message.error(response.message);

          return;
        }

        message.success("Notification updated successfully");

        localStorage.clear();

        window.location.href = "/";
      })
      .catch(() => {
        message.error("Something went wrong");
      });
  };

  return (
    <div>
      <p style={{ marginBottom: 20, width: 700 }}>
        When you opt for the "Remove Account" functionality, you're initiating a
        request to permanently delete your account from our platform. This
        action doesn't lead to an immediate removal. Instead, our team will
        process your request, ensuring all data and potential ongoing activities
        associated with your account are carefully reviewed. Once the internal
        assessment and procedures are completed, your account will be set for
        removal. You'll be promptly notified about the status of your request
        and the final deletion of your account. Please note, it's crucial to
        backup any essential data before initiating this process, as
        post-deletion retrieval won't be possible. Should you change your
        decision, contact our support team as soon as possible.
      </p>

      <Popconfirm
        placement="topRight"
        title={`Do you want to remove your account?`}
        onConfirm={onRemove}
        okText="Remove"
        cancelText="Cancel"
        okButtonProps={{
          danger: true
        }}
      >
        <Button type="primary" danger>
          Remove
        </Button>
      </Popconfirm>
    </div>
  );
}
