import { useEffect, useState } from "react";

import { Drawer, Form, Select, Tag, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";

export default function OtherGroupsDrawer({ onClose, open, deletable, getData }) {
    const [isLoading, setIsLoading] = useState(false);
    const [groupsOptions, setGroupsOptions] = useState([]);

    const [form] = Form.useForm();

    const getUsersGroups = () => {
        REQUESTS.USERS.GROUPS.GET({ query: JSON.stringify({ limit: 100000 }) })
            .then((response) => {
                if (response.message && response.message.rows) {
                    const rows = response.message.rows;

                    const groups = [];

                    for (let i = 0; i < rows.length; i++) {
                        if (rows[i].id === deletable?.id) continue;

                        groups.push({
                            label: rows[i].name,
                            value: rows[i].id,
                        });
                    }

                    setGroupsOptions(groups);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onFinish = (values) => {
        setIsLoading(true);
        const body = {
            id: deletable?.id,
            newId: values.newId,
        };

        REQUESTS.USERS.GROUPS.DELETE(body)
            .then((response) => {
                if (response.error) {
                    notification.error({
                        message: "Error",
                        description: response.message,
                    });
                }

                getData();
                onClose();

                setIsLoading(false);
            })
            .catch((error) => {
                notification.error({
                    message: "Error",
                    description: error,
                });
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setIsLoading(false);
        } else {
            getUsersGroups();
        }
    }, [open]);

    return (
        <Drawer
            width={500}
            open={open}
            placement="right"
            onClose={onClose}
            title={`Are you sure you want to delete ${deletable?.name} group?`}
        >
            <Tag
                bordered={false}
                color="gold"
                style={{ width: "100%", marginBottom: 10, padding: 10 }}
            >
                Choose the group where will be moved all users from deleted group or leave
                <br />
                it blank if you sure there is no user on deleted group
            </Tag>

            <Form
                form={form}
                name="users-all-groups"
                layout="vertical"
                onFinish={onFinish}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        form.submit();
                    }
                }}
            >
                <Form.Item
                    label="Groups"
                    name="newId"
                    rules={[
                        {
                            required: true,
                            message: "Please select group",
                        },
                    ]}
                >
                    <Select options={groupsOptions} />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <ButtonComponent
                        title="Save"
                        actionType="save"
                        isLoading={isLoading}
                        onClick={() => form.submit()}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}
