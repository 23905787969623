import adminsApi from "../pages/admins/_adminsApi";
import adsApi from "../pages/ads/_adsApi";

import PROFILE from "./routes/profile";
import MESSAGES from "./routes/messages";
import ARCHIVE from "./routes/archive/archive";

import NEWS from "./routes/web-page/news";
import FAQ from "./routes/web-page/faq";
import SLIDER from "./routes/web-page/slider";
import WEB_SETTINGS from "./routes/web-page/settings";
import COUNTRIES from "./routes/general/countries";
import GENERAL from "./routes/general/general";
import TVOD_PAYMENT from "./routes/payments/tvod-payment";
import PAYMENTS_HISTORY from "./routes/payments/history";
import PAYMENTS_CONFIG from "./routes/payments/config";
import PAYMENTS_SUBSCRIBTIONS from "./routes/payments/subscriptions";

import KEYWORDS from "./routes/settings/keywords";
import SCRIPTS from "./routes/settings/scripts";

import vodGenresApi from "../pages/vod/genres/_vodGenresApi";
import basicInfoApi from "../pages/application/basic-info/_basicInfoApi";

import languagesApi from "../pages/application/languages/_languagesApi";
import translationsApi from "../pages/application/translations/_translationsApi";

import userApi from "../pages/users/user/_userApi";
import usersApi from "../pages/users/users/_usersApi";
import usersGroupsApi from "../pages/users/groups/_userGrpupsApi";

import vodCollectionsApi from "../pages/vod/collections/_vodCollectionsApi";
import castApi from "../pages/vod/cast/_castApi";

import webFeaturesApi from "../pages/web-page/features/_webFeaturesApi";
import _webAvailableDevicesApi from "../pages/web-page/available-devices/_webAvailableDevicesApi";
import goLiveApi from "../layout/header/components/go-live/goLiveApi";
import videoApi from "./videoApi";
import contentPrivacyPageApi from "../pages/vod/content-privacy/api";
import generalSettingsApi from "../pages/settings/general-settings/_generalSettingsApi";
import documentsApi from "../pages/web-page/documents/_documentsApi";
import videosApi from "../pages/vod/videos/logic/api";
import moreAppsApi from "../pages/application/more-apps/moreAppsApi";
import storeLinksApi from "../pages/application/store-links/api";

const REQUESTS = {
  // a
  ADS: adsApi,
  ADMINS: adminsApi,
  ARCHIVE,

  APPLICATION: {
    BASIC_INFO: basicInfoApi,
    LANGUAGES: languagesApi,
    TRANSLATIONS: translationsApi,
    STORE_LINKS: storeLinksApi,
  },
  // b
  // c
  CURRENCY: GENERAL.CURRENCY,
  COUNTRIES,
  // d
  DEVICES: GENERAL.DEVICES,
  // e
  // f
  // g
  GOOGLE: GENERAL.GOOGLE,
  GO_LIVE: goLiveApi,
  // h
  // i
  // j
  // k
  KEYWORDS,
  // l

  // m
  MESSAGES,
  MORE_APPS: moreAppsApi,
  // n
  // o
  // p
  PROFILE,
  PAYMENTS: {
    TVOD_PAYMENT,
    HISTORY: PAYMENTS_HISTORY,
    CONFIGS: PAYMENTS_CONFIG,
    SUBSCRIPTIONS: PAYMENTS_SUBSCRIBTIONS,
  },
  // q
  // r
  // s
  SCRIPTS,
  SETTINGS: {
    GENERAL: generalSettingsApi,
  },

  // t

  // u
  USERS: {
    USER: userApi,
    USERS: usersApi,
    GROUPS: usersGroupsApi,
  },
  // v
  VIDEO: videoApi,
  // VIDEO: {
  //   EDIT: editMovieApi,
  // },
  VOD: {
    CAST: castApi,
    GENRES: vodGenresApi,
    COLLECTIONS: vodCollectionsApi,

    CONTENT_PRIVACY: contentPrivacyPageApi,

    VIDEOS: videosApi,
  },
  // w
  WIDGETS: GENERAL.WIDGETS,

  WEB_PAGE: {
    FAQ,
    NEWS,
    SLIDER,
    FEATURES: webFeaturesApi,
    SETTINGS: WEB_SETTINGS,
    AVAILABLE_DEVICES: _webAvailableDevicesApi,
    DOCUMENTS: documentsApi,
  },
  // x
  // y
  // z
};

export default REQUESTS;
