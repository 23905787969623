import { Space, Tag, Tooltip } from "antd";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

import icons from "../../../config/icons";
import {
  getQueryDate,
  getColumnDateProps,
  getColumnSearchProps,
  parseDateFormat,
} from "../../../config/config";

import Check from "../../../_components/table/Check";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

import {
  useUsersGroupsOptions,
  usePaymentsSubscriptionsOptions,
} from "../../../hooks/selectOptions";
import PATHS from "../../../config/paths";

let statusOptions = [
  { text: "Active", value: 0 },
  { text: "Disabled by tariff", value: 2 },
  { text: "Disabled by admin", value: 3 },
];

export default function UsersTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const navigate = useNavigate();
  const groupsOptions = useUsersGroupsOptions("text");

  const tariffOptions = usePaymentsSubscriptionsOptions("text");

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [registrationDate, setRegistrationDate] = useState(null);

  const [tariffCreatedDate, setTariffCreatedDate] = useState(null);

  const [expireTariffDate, setExpireTariffDate] = useState(null);

  const [search, setSearch] = useState({
    name: null,
    surname: null,
    phone: null,
    email: null,
    username: null,
  });

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      fixed: "left",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      width: 130,
      title: "Online / Offline",
      dataIndex: `online`,
      align: "center",
      key: "online",
      sorter: true,
      render: (text, record, index) => {
        return record.online ? (
          <Tag color="success">Online</Tag>
        ) : (
          <Tag color="error">Offline</Tag>
        );
      },
    },

    {
      title: "Status",
      dataIndex: `status`,
      align: "center",
      key: "status",

      filters: statusOptions,
      filterMultiple: false,
      onFilter: (value, record) => true,

      render: (text, record, index) => {
        if (record.archive) {
          return <Tag color="error">Archive</Tag>;
        }

        switch (record.status) {
          case 0:
          case 1:
            return <Tag color="success">Active</Tag>;

          case 2:
            return <Tag color="error">Disabled by tariff</Tag>;

          case 3:
            return <Tag color="error">Disabled by admin</Tag>;

          default:
            break;
        }
      },
    },

    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      align: "center",

      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",

      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
      ...getColumnSearchProps(),
    },

    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
      align: "center",

      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
      ...getColumnSearchProps(),
    },

    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: `Group`,
      dataIndex: "groupId",
      key: "groupId",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      filters: groupsOptions,
      filterMultiple: false,
      onFilter: (value, record) => true,

      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={record?.group?.name}>
            {record.group ? record.group.name : "N/A"}
          </Tooltip>
        );
      },
    },

    {
      title: "Payment type",
      dataIndex: "payment_type",
      key: "payment_type",
      align: "center",
    },

    {
      title: "Subscription id",
      dataIndex: "subscription_id",
      key: "subscription_id",
      align: "center",

      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: `Registration date`,
      dataIndex: "register_date",
      key: "register_date",
      align: "center",

      render: (text, record, index) => {
        return parseDateFormat(text);
      },

      ...getColumnDateProps((date) => {
        setRegistrationDate(date);
        setExpireTariffDate(null);
        setTariffCreatedDate(null);
      }),
    },

    {
      title: `Tariff`,
      dataIndex: "tariffId",
      key: "tariffId",
      align: "center",

      filters: tariffOptions,
      filterMultiple: false,
      onFilter: (value, record) => true,

      render: (text, record, index) => {
        return <span>{record.tariff ? record.tariff.name : "N/A"}</span>;
      },
    },

    {
      title: `Tariff created date`,
      dataIndex: "upd_tariff",
      key: "upd_tariff",
      align: "center",

      render: (text, record, index) => {
        return text ? parseDateFormat(text) : "N/A";
      },

      ...getColumnDateProps((date) => {
        setRegistrationDate(null);
        setExpireTariffDate(null);
        setTariffCreatedDate(date);
      }),
    },

    {
      title: `Expire date`,
      dataIndex: "expire_tariff",
      key: "expire_tariff",
      align: "center",

      render: (text, record, index) => {
        return text ? parseDateFormat(text) : "N/A";
      },

      ...getColumnDateProps((date) => {
        setRegistrationDate(null);
        setExpireTariffDate(date);
        setTariffCreatedDate(null);
      }),
    },

    {
      width: 130,
      sorter: true,
      title: "Auto Renewal",
      key: "auto_renewal",
      dataIndex: "auto_renewal",
      align: "center",
      render: (text, record, index) => {
        return <Check value={record.auto_renewal} />;
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            items={[
              {
                key: "edit",
                name: "Edit",
                icon: icons.EDIT,
              },

              {
                key: "tariff",
                name: "Tariff",
                icon: icons.TARIFF,
              },

              record.status === 3
                ? {
                    key: "unlock",
                    name: "Unlock",
                    icon: icons.UNLOCK,
                    question: `Do you want to unlock this user`,
                    onConfirm: (e) => {
                      handleMenuClick(e, record);
                    },
                  }
                : {
                    key: "lock",
                    name: "Lock",
                    icon: icons.LOCK,
                    question: `Do you want to lock this user?`,
                    onConfirm: (e) => {
                      handleMenuClick(e, record);
                    },
                  },

              record.archive
                ? {
                    key: "restore",
                    name: "Restore",
                    icon: icons.RESTORE,
                    question: `Do you want to restore this user?`,
                    onConfirm: (e) => {
                      handleMenuClick(e, record);
                    },
                  }
                : {
                    key: "archive",
                    name: "Archive",
                    icon: icons.ARCHIVE_ICON,
                    question: `Do you want to archive this user?`,
                    onConfirm: (e) => {
                      handleMenuClick(e, record);
                    },
                  },
            ]}
          />
        </div>
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    const usersParams = {
      sort: data.sort,
      limit: data.limit,
      page: data.page,
    };

    sessionStorage.setItem("users", JSON.stringify(usersParams));

    setSearch(data.search);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const usersParams = JSON.parse(sessionStorage.getItem("users"));

      const query = {
        sort: usersParams ? usersParams?.sort : sort,
        limit: usersParams ? usersParams?.limit : limit,
        page: usersParams ? usersParams?.page : currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name[0],
        };
      }

      if (search.surname) {
        query.search = {
          surname: search.surname[0],
        };
      }

      if (search.username) {
        query.search = {
          username: search.username[0],
        };
      }

      if (search.phone) {
        query.search = {
          phone: search.phone[0],
        };
      }

      if (search.email) {
        query.search = {
          email: search.email[0],
        };
      }

      if (search.groupId && search.groupId[0]) {
        if (query.filter) {
          query.filter = {
            ...query.filter,
            groupId: search.groupId[0],
          };
        } else {
          query.filter = {
            groupId: search.groupId[0],
          };
        }
      }

      if (search.tariffId && search.tariffId[0]) {
        if (query.filter) {
          query.filter = {
            ...query.filter,
            tariffId: search.tariffId[0],
          };
        } else {
          query.filter = {
            tariffId: search.tariffId[0],
          };
        }
      }

      if (search.status && search.status[0]) {
        if (query.filter) {
          query.filter = {
            ...query.filter,
            status: search.status[0],
          };
        } else {
          query.filter = {
            status: search.status[0],
          };
        }
      }

      const queryRegistrationDate = getQueryDate(registrationDate, "register_date");

      if (queryRegistrationDate) query.between = queryRegistrationDate;

      const queryTariffCreatedDate = getQueryDate(tariffCreatedDate, "upd_tariff");

      if (queryTariffCreatedDate) query.between = queryTariffCreatedDate;

      const queryExpireTariffDate = getQueryDate(expireTariffDate, "expire_tariff");

      if (queryExpireTariffDate) query.between = queryExpireTariffDate;

      function onSuccess(data) {
        if (data.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);
        setDataSource(data.rows);

        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    limit,
    sort,
    search,
    registrationDate,
    tariffCreatedDate,
    expireTariffDate,
    getAgain,
  ]);

  return (
    <TableComponent
      header={
        <>
          <Space>
            <ButtonComponent icon={icons.ADD} title="Add User" onClick={openDrawer} />
          </Space>
        </>
      }
      rowClassName="clickable-row"
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            navigate(PATHS.USER + record.id);
          },
        };
      }}
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 2500,
      }}
    />
  );
}
