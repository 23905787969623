import Axios from "axios";
import { DEMO_API_HOST } from "../config/statics";
import { getToken } from "../server/requests";
import request from "./request";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}admin/`;

const URL = {
  MOVIE: `${host}movie`,
  ENCODER: `https://vodencoder.streamifyer.com`,
  TRANSCODER: `https://vodencoder.streamifyer.com/transcodings/yt`,
};

const videoApi = {
  GET: (id) => request("GET", `${URL.MOVIE}?token=${getToken()}&id=${id}`),

  EDIT: (query) => request("PUT", `${URL.MOVIE}`, query),

  ADD: (query) => request("POST", `${URL.ENCODER}/transcodings`, query),

  UPDATE_VIDEO: (query) => request("PUT", `${URL.ENCODER}/transcodings`, query),

  GET_TARIFF: (id) =>
    request("GET", `${URL.MOVIE}/tariff?movieId=${id}&token=${getToken()}`, {}),

  CHECK_NAME: (query) => request("POST", `${URL.ENCODER}/transcodings/check_name`, query),

  STATUS: (query) => request("GET", `${URL.ENCODER}/upload/status`, query),

  START: (query, header, onPorgress) =>
    Axios.post(`${URL.ENCODER}/upload`, query, {
      headers: header,

      onUploadProgress: (progressEvent) => {
        const total = parseFloat(progressEvent.total);
        const loaded = parseFloat(progressEvent.loaded);

        const progress = Math.floor((loaded / total) * 100);

        onPorgress(progress);
      },
    }),

  TRANSCODER_ADD: (query) => request("POST", `${URL.TRANSCODER}`, query),

  TRANSCODER_EDIT: (query) => request("PUT", `${URL.TRANSCODER}`, query),
};

export default videoApi;
