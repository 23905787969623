import { Button, Upload, message } from "antd";

import { UploadOutlined } from "@ant-design/icons";

export default function Upploader({ accept, onChange, style, disabled }) {
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      onChange(info);
    }
  };

  const beforeUpload = (file) => {
    const maxSize = 999 * 1024 * 1024; // 9999 MB in bytes
    if (file.size > maxSize) {
      message.error("File size exceeds the limit (999 MB).");

      return false;
    }
    return true;
  };

  return (
    <div style={{ width: "100%" }}>
      <Upload
        disabled={disabled}
        multiple={false}
        maxCount={1}
        accept={accept}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        customRequest={({ file, onSuccess }) => {
          setTimeout(() => {
            onSuccess("ok");
          }, 0);
        }}
        style={{ width: "100%" }}
      >
        <Button icon={<UploadOutlined />} style={style} disabled={disabled}>
          Click to Upload
        </Button>
      </Upload>
    </div>
  );
}
