import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
    GENRES: `${host}admin/movie/genres`,

    PROTECT: `${host}v2/admin/genre/protect`,
};

const vodGenresApi = {
    GET: (query) => request("GET", URL.GENRES, query),

    ADD: (query) => request("POST", URL.GENRES, query),

    EDIT: (query) => request("PUT", URL.GENRES, query),

    DELETE: (query) => request("DELETE", URL.GENRES, query),

    PROTECT: (query) => request("PUT", URL.PROTECT, query),
};

export default vodGenresApi;
