import { Divider, Form, Input } from "antd";

export default function About() {
  return (
    <div>
      <Divider orientation="left">Info</Divider>

      <Form.Item label="Name" name="name" style={{ marginBottom: 10 }}>
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        style={{ marginBottom: 10 }}
      >
        <Input.TextArea style={{ resize: "none" }} rows={6} />
      </Form.Item>
    </div>
  );
}
