import { Image } from "antd";
import defaultImage from "../../img/image.png";

export default function TableImage({ src, style }) {
  const imageError = (e) => (e.target.src = defaultImage);

  return (
    <Image
      src={src}
      onError={imageError}
      style={{ width: 30, height: 30, borderRadius: "50%", ...style }}
    />
  );
}
