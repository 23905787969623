import { Line } from '@ant-design/plots';
import { useState, useEffect } from 'react';

import requests from '../../../server/requests';
import ItemDescription from './ItemDescription';


export default function Revenue() {

    let [chartData, setChartData] = useState({
        data: [],
        xField: 'date',
        yField: 'value',
        label: {},
        point: {
            size: 5,
            shape: 'diamond',
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 2,
            },
        },
        tooltip: {
            showMarkers: false,
        },
        state: {
            active: {
                style: {
                    shadowBlur: 4,
                    stroke: '#000',
                    fill: 'red',
                },
            },
        },
        interactions: [
            {
                type: 'marker-active',
            },
        ],
    });


    const getRevenue = () => {
        requests.statistics.payments_count((data) => {

            chartData.data = data.data;
            console.log(chartData);
            setChartData(chartData)

        })
    }

    useEffect(() => {
        getRevenue();
    }, [])





    return (

        <>
            <ItemDescription title={"Revenue"} content={"This chart is presenting the revenue earned per each month for current year"} />
            <Line style={{ width: "100%" }} {...chartData} />
        </>

    );
}



