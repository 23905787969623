import { Line } from "@ant-design/plots";
import { useState, useEffect } from "react";

import requests from "../../../server/requests";
import ItemDescription from "./ItemDescription";

export default function UserStatistics() {
  let [users, setUsers] = useState({
    data: [],
    xField: "date",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2
      }
    },
    tooltip: {
      showMarkers: false
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red"
        }
      }
    },
    interactions: [
      {
        type: "marker-active"
      }
    ]
  });

  const getUsers = () => {
    requests.statistics.user_statistics((data) => {
      users.data = data.data;
      setUsers(users);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <ItemDescription
        title={"Registered users"}
        content={
          "This chart is displaying total users per month registered to watch your videos"
        }
      />
      <Line style={{ width: "100%" }} {...users} />
    </>
  );
}
