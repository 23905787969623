import { useEffect, useState } from "react";

import { ColorPicker, Button, message, Divider } from "antd";

import REQUESTS from "../../../../api/requests";

import colorByHex from "../../../../config/colorByHex";

import style from "../_basic-info.module.scss";

export default function AppColorsTab({}) {
  const [isLoading, setIsLoading] = useState(false);

  const [primary, setPrimary] = useState("#1890ff");

  const [secondary, setSecondary] = useState("#1890ff");

  const [accent, setAccent] = useState("#1890ff");

  const [card, setCard] = useState("#1890ff");

  const [background, setBackground] = useState("#1890ff");

  const getSettings = () => {
    REQUESTS.APPLICATION.BASIC_INFO.COLORS.GET().then((response) => {
      if (!response.error) {
        const settings = response.message || [];

        for (let i = 0; i < settings.length; i++) {
          switch (settings[i].key) {
            case "primary_color":
              setPrimary(settings[i].value);
              break;
            case "secondary_color":
              setSecondary(settings[i].value);
              break;
            case "accent_color":
              setAccent(settings[i].value);
              break;
            case "card_color":
              setCard(settings[i].value);
              break;
            case "background_color":
              setBackground(settings[i].value);
              break;
            default:
              break;
          }
        }
      }
    });
  };

  const save = () => {
    setIsLoading(true);

    const body = {
      primary_color: colorByHex(primary),
      secondary_color: colorByHex(secondary),
      accent_color: colorByHex(accent),
      card_color: colorByHex(card),
      background_color: colorByHex(background)
    };

    REQUESTS.APPLICATION.BASIC_INFO.COLORS.UPDATE(body)
      .then((response) => {
        if (response.error) {
          message.error(response.message);

          setIsLoading(false);
        } else {
          message.success("Update is successfuly");

          setIsLoading(false);
        }
      })

      .catch((err) => {
        message.error(err.message || "Something went wrong");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  const colors = [
    {
      title: "Primary",
      value: primary,
      onChange: setPrimary
    },

    {
      title: "Secondary",
      value: secondary,
      onChange: setSecondary
    },

    {
      title: "Accent",
      value: accent,
      onChange: setAccent
    },

    {
      title: "Card",
      value: card,
      onChange: setCard
    },

    {
      title: "Background",
      value: background,
      onChange: setBackground
    }
  ];

  return (
    <div className={style["tab"]} style={{ width: 400 }}>
      <Divider orientation="left">Setup colors for application</Divider>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 15,
          marginTop: 20
        }}
      >
        {colors.map((item) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 15
              }}
            >
              <p style={{ textAlign: "start" }}>{item.title}</p>

              <ColorPicker
                value={item.value}
                onChange={item.onChange}
                presets={[
                  {
                    label: "Recommended",
                    colors: [
                      "#000000",
                      "#000000E0",
                      "#000000A6",
                      "#00000073",
                      "#00000040",
                      "#00000026",
                      "#0000001A",
                      "#00000012",
                      "#0000000A",
                      "#00000005",
                      "#F5222D",
                      "#FA8C16",
                      "#FADB14",
                      "#8BBB11",
                      "#52C41A",
                      "#13A8A8",
                      "#1677FF",
                      "#2F54EB",
                      "#722ED1",
                      "#EB2F96",
                      "#F5222D4D",
                      "#FA8C164D",
                      "#FADB144D",
                      "#8BBB114D",
                      "#52C41A4D",
                      "#13A8A84D",
                      "#1677FF4D",
                      "#2F54EB4D",
                      "#722ED14D",
                      "#EB2F964D"
                    ]
                  }
                ]}
              />
            </div>
          );
        })}
      </div>

      <Button
        type="primary"
        loading={isLoading}
        style={{ width: "100%", marginTop: 30 }}
        onClick={save}
      >
        Save
      </Button>
    </div>
  );
}
