import { Form, Input, Select, Space } from "antd";

export default function Scheduling({}) {
    const options = [
        {
            value: "day",
            label: "Day",
        },
        {
            value: "month",
            label: "Month",
        },
        {
            value: "year",
            label: "Year",
        },
    ];

    return (
        <Space>
            <Form.Item label="Scheduling type" name="scheduling_type" style={{ width: 180 }}>
                <Select options={options} />
            </Form.Item>

            <Form.Item
                label="Scheduling Time"
                name="scheduling_time"
                rules={[
                    {
                        required: true,
                        message: "Please input stripe plan id",
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </Space>
    );
}
