import { useEffect, useState } from "react";

import {
  Drawer,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Select
} from "antd";

import REQUESTS from "../../../api/requests";

import initValues from "./initValues";
import createFormValues from "./createFormValues";

import AdsUrl from "./AdsUrl";
import VodSelect from "./VodSelect";

import ButtonComponent from "../../../_components/ButtonComponent";
import { usePaymentsSubscriptionsOptions } from "../../../hooks/selectOptions";

const adsTypeOptions = [
  {
    name: "Custom / VAST",
    value: "custom"
  },
  {
    name: "IMA",
    value: "IMA"
  }
];

export default function AdsDrawer({ onClose, open, editable, getData }) {
  const paymentsSubscriptionsOptions = usePaymentsSubscriptionsOptions();

  const [isLoading, setIsLoading] = useState(false);

  const [type, setType] = useState("custom");

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = createFormValues({ values, editable });

    if (editable) {
      REQUESTS.ADS.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.ADS.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message
            });

            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const onFieldsChange = (changedFields, allFields) => {
    switch (changedFields[0].name[0]) {
      case "type":
        setType(changedFields[0].value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }

    if (editable) {
      setType(editable.type);

      initValues({ form, editable });
    }
  }, [open, editable]);

  return (
    <Drawer
      width={500}
      title={`${editable ? "Edit" : "Add"} ads`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        name="ads"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
        onFieldsChange={onFieldsChange}
        initialValues={{
          type: "custom",
          all_movies: false
        }}
      >
        <Form.Item label="Type" name="type">
          <Radio.Group buttonStyle="solid">
            {adsTypeOptions.map((item) => (
              <Radio key={item.value} value={item.value}>
                {item.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <AdsUrl form={form} />

        <VodSelect form={form} />

        <Form.Item label="Tariffs" name="tariffs_id">
          <Select
            options={paymentsSubscriptionsOptions}
            mode="multiple"
            style={{ width: "100%", marginTop: 5 }}
            showSearch
            showArrow
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input)
            }
            filterSort={(optionA, optionB) => {
              return (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase());
            }}
          />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
