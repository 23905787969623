import { Space, Tooltip } from "antd";
import TableImage from "./table/TableImage";

export default function VideoInfo({
  name,
  image,
  description,
  textWidth = 170
}) {
  return (
    <Tooltip placement="topLeft" title={name}>
      <Space>
        <TableImage
          src={image}
          style={{
            width: 70,
            height: 70,
            borderRadius: 10,
            objectFit: "cover"
          }}
        />

        <div style={{ marginLeft: 5, flexDirection: "column" }}>
          <div
            className="text-ellipsis"
            style={{ width: textWidth, fontSize: 17 }}
          >
            {" "}
            {name}
          </div>
          {description && (
            <div
              className="description-ellipsis"
              style={{ width: 180, color: "grey" }}
            >
              {description}
            </div>
          )}
        </div>
      </Space>
    </Tooltip>
  );
}
