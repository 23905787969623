import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ConfigProvider, notification } from "antd";

import "react-datepicker/dist/react-datepicker.css";

import "./App.css";
import "./Util.css";

import "./Antd.scss";

import "filepond/dist/filepond.min.css";

import * as actions from "./redux-store/actions";

import "./app.scss";

import { host } from "./server/url";
import LoginPage from "./pages/login/LoginPage";
import LayoutComponent from "./layout/LayoutComponent";
import PATHS from "./config/paths";
import ChatPage from "./routes/chat/ChatPage";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import SplashScreen from "./pages/splash-screen/SplashScreen";
import NewsPage from "./pages/web-page/news/NewsPage";
import FaqPage from "./pages/web-page/faq/FaqPage";
import FeaturesPage from "./pages/web-page/features/FeaturesPage";
import WebSettingsPage from "./pages/web-page/settings/WebSettingsPage";
import WebSliderPage from "./pages/web-page/slider/WebSliderPage";
import TvodPaymentPage from "./pages/payments/tvod-payment/TvodPaymentPage";
import PaymentHistoryPage from "./pages/payments/history/PaymentHistoryPage";
import PaymentConfigPage from "./pages/payments/config/PaymentConfigPage";
import PaymentsSubscriptionsPage from "./pages/payments/subscriptions/PaymentsSubscriptionsPage";
import AdminsPage from "./pages/admins/AdminsPage";

import "react-color-palette/lib/css/styles.css";
import UsersGroupsPage from "./pages/users/groups/UsersGroupsPage";
import UsersPage from "./pages/users/users/UsersPage";
import UserPage from "./pages/users/user/UserPage";
import AdsPage from "./pages/ads/AdsPage";
import VodGenresPage from "./pages/vod/genres/VodGenresPage";
import BasicInfoPage from "./pages/application/basic-info/BasicInfoPage";
import LanguagesPage from "./pages/application/languages/LanguagesPage";
import TranslationsPage from "./pages/application/translations/TranslationsPage";
import VodCollectionsPage from "./pages/vod/collections/VodCollectionsPage";
import CastPage from "./pages/vod/cast/CastPage";
import ErrorPage from "./pages/error/ErrorPage";
import AvailableDevicesPage from "./pages/web-page/available-devices/AvailableDevicesPage";
import WelcomePage from "./pages/welcome/WelcomePage";
import AnalyticsPage from "./pages/analytics/AnalyticsPage";
import RegisterPage from "./pages/register/RegisterPage";
import AccountPage from "./pages/account/AccountPage";
import InitialWelcomePage from "./pages/initial-welcome/InitialWelcomePage";
import ContentPrivacyPage from "./pages/vod/content-privacy/ContentPrivacyPage";
import MobileModal from "./_components/mobile-modal/MobileModal";
import DocumentsPage from "./pages/web-page/documents/DocumentsPage";
import VideosPage from "./pages/vod/videos/VideosPage";
import MoreAppsPage from "./pages/application/more-apps/MoreAppsPage";
import EditVideoPage from "./pages/vod/edit-video/EditVideoPage";
import StoreLinksPage from "./pages/application/store-links/StoreLinksPage";

export let socket = null;

const token = localStorage.getItem("admtoken");

export default function App() {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dashboardSettings } = useSelector((state) => state.globalState);

  const { setNewMessage, setIsChangedNotReadMessagesCount } =
    bindActionCreators(actions, dispatch);

  const [socketIsConnected, setSocketIsConnected] = useState(false);

  const connectSocket = () => {
    if (token && !socketIsConnected) {
      socket = window.io(host, {
        auth: {
          authorization: token
        }
      });

      socket.on("connect", (data) => {
        setSocketIsConnected(true);
      });

      socket.on("connect_error", (err) => {
        setSocketIsConnected(false);
      });

      socket.on("new_chat_message", (data) => {
        setNewMessage(data);
        setIsChangedNotReadMessagesCount(new Date());
        if (window.location.hash === "#/dashboard/chat") {
          return;
        }

        notification.success({
          message: "New message",
          description: data.content,
          style: { cursor: "pointer" },
          onClick: () => {
            navigate(PATHS.CHAT, { state: { id: data.conversation_id } });
          }
        });
      });
    }
  };

  useEffect(() => {
    if (!socketIsConnected) {
      connectSocket();
    }
  }, [socketIsConnected]);

  const resize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    resize();

    window.addEventListener("resize", resize, true);

    return () => {
      window.removeEventListener("resize", resize, true);
    };
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            colorPrimary: dashboardSettings?.primaryColor || "#43b594",
            colorPrimaryHover: dashboardSettings?.primaryHoverColor || "#43b594"
          },

          Button: {
            algorithm: true // Enable algorithm
          },

          // Table: {
          //   colorBgContainer: "rgba(31, 33, 37, 1)",
          //   colorText: "white",
          //   borderColor: "#4d4c4c",
          //   colorTe: "white"
          // },

          Pagination: {
            colorPrimary: dashboardSettings?.primaryColor || "#43b594",
            colorPrimaryHover: dashboardSettings?.primaryHoverColor || "#43b594"
          },

          Select: {
            colorPrimaryHover: dashboardSettings?.primaryColor || "#43b594"
          },

          Input: {
            colorPrimary: dashboardSettings?.primaryColor || "#43b594",
            colorPrimaryHover: dashboardSettings?.primaryHoverColor || "#43b594"
          }
        }
      }}
    >
      {isMobile && <MobileModal />}

      <Routes>
        <Route path="/" index element={<SplashScreen />} />
        <Route path={PATHS.LOGIN} element={<LoginPage />} />
        <Route path={PATHS.REGISTER} element={<RegisterPage />} />
        <Route path={PATHS.INITIAL_WELCOME} element={<InitialWelcomePage />} />

        <Route path={PATHS.DASHBOARD} element={<LayoutComponent />}>
          <Route path={PATHS.ACCOUNT} element={<AccountPage />} />

          <Route path={PATHS.WELCOME} element={<WelcomePage />} />

          <Route path={PATHS.ANALYTICS} element={<AnalyticsPage />} />

          <Route path={PATHS.CHAT} element={<ChatPage />} />

          {/* USERS */}
          <Route path={PATHS.USERS} element={<UsersPage />} />
          <Route path={`${PATHS.USER}:id`} element={<UserPage />} />
          <Route path={PATHS.USERS_GROUP} element={<UsersGroupsPage />} />

          {/* PAYMENTS */}
          <Route path={PATHS.TVOD_PAYMENT} element={<TvodPaymentPage />} />
          <Route
            path={PATHS.PAYMENT_HISTORY}
            element={<PaymentHistoryPage />}
          />
          <Route
            path={PATHS.PAYMENT_SUBSCRIPTION}
            element={<PaymentsSubscriptionsPage />}
          />
          <Route path={PATHS.PAYMENT_CONFIGS} element={<PaymentConfigPage />} />

          <Route path={PATHS.MORE_APPS} element={<MoreAppsPage />} />

          <Route path={PATHS.TEAM_MEMBERS} element={<AdminsPage />} />

          {/* ADS */}
          <Route path={PATHS.ADS} element={<AdsPage />} />

          {/* WEB PAGE */}

          <Route path={PATHS.WEB_NEWS} element={<NewsPage />} />
          <Route path={PATHS.WEB_FAQ} element={<FaqPage />} />
          <Route path={PATHS.WEB_FEATURE} element={<FeaturesPage />} />
          <Route path={PATHS.WEB_SETTINGS} element={<WebSettingsPage />} />
          <Route path={PATHS.WEB_SLIDER} element={<WebSliderPage />} />
          <Route
            path={PATHS.WEB_AVAILABLE_DEVICES}
            element={<AvailableDevicesPage />}
          />

          {/* APPLICATION */}
          <Route path={PATHS.APPLICATION_BASIC} element={<BasicInfoPage />} />

          <Route
            path={PATHS.APPLICATION_LANGUAGES}
            element={<LanguagesPage />}
          />
          <Route
            path={`${PATHS.APPLICATION_LANGUAGE}:id`}
            element={<TranslationsPage />}
          />

          {/* VOD MANAGER */}
          <Route path={PATHS.TAGS} element={<VodGenresPage />} />
          <Route path={PATHS.VIDEOS} element={<VideosPage />} />
          <Route path={PATHS.CAST} element={<CastPage />} />
          <Route path={PATHS.COLLECTION} element={<VodCollectionsPage />} />
          <Route
            path={PATHS.CONTENT_PRIVACY}
            element={<ContentPrivacyPage />}
          />

          <Route path={`${PATHS.EDIT_VIDEO}:id`} element={<EditVideoPage />} />

          {/* SETTINGS */}
          <Route path={PATHS.DOCUMENTS} element={<DocumentsPage />} />
          <Route path={PATHS.STORE_LINKS} element={<StoreLinksPage />} />

          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </ConfigProvider>
  );
}
