import { useEffect, useState } from "react";

import { Checkbox, Drawer, Form, Select, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";

import { usePaymentsSubscriptionsOptions } from "../../../hooks/selectOptions";

export default function TariffDrawer({ onClose, open, user, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const tariffOptions = usePaymentsSubscriptionsOptions();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      user_id: user?.id,
      tariff_id: values.tariff_id,
      auto_renewal: values.auto_renewal
    };

    REQUESTS.USERS.USERS.TARIFF(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message
          });

          return;
        }

        getData();
        onClose();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }

    if (user) {
      const defaultSelectedTariff = tariffOptions?.find(
        (tariff) => tariff.value === user.tariffId
      );

      if (defaultSelectedTariff) {
        form.setFields([
          { name: "tariff_id", value: defaultSelectedTariff.value }
        ]);
      }

      form.setFields([{ name: "auto_renewal", value: user.auto_renewal }]);
    }
  }, [open, user, tariffOptions]);

  return (
    <Drawer
      open={open}
      placement="right"
      onClose={onClose}
      title="Choose tariff for user"
    >
      <Form
        form={form}
        name="user-tariff"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
      >
        <Form.Item
          label="Tariff"
          name="tariff_id"
          rules={[
            {
              required: true,
              message: "Please select tariff"
            }
          ]}
        >
          <Select options={tariffOptions} />
        </Form.Item>

        <Form.Item name="auto_renewal" noStyle valuePropName="checked">
          <Checkbox>Auto Renewal</Checkbox>
        </Form.Item>

        <Form.Item style={{ textAlign: "center", marginTop: 20 }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
