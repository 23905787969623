import { Progress } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import REQUESTS from "../../api/requests";
import PATHS from "../../config/paths";
import { getToken } from "../../server/requests";
import style from "./splash_screen.module.scss";

export default function SplashScreen({}) {
  const navigate = useNavigate();

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (percent < 100) {
      setTimeout(() => {
        setPercent(percent + 1);
      }, 10);
    }
  }, [percent]);

  useEffect(() => {
    const token = getToken();
    if (token) {
      REQUESTS.PROFILE.VALIDATE({})
        .then((response) => {
          if (!response.error) {
            if (response?.message?.valid) {
              navigate(PATHS.WELCOME);
            } else {
              navigate(PATHS.LOGIN);
            }
          } else {
            navigate(PATHS.LOGIN);
          }
        })
        .catch((err) => {
          navigate(PATHS.LOGIN);
        });
    } else {
      navigate(PATHS.LOGIN);
    }
  }, []);

  return (
    <div className={style.page}>
      <div>
        <p className={style.title}>Welcome to the admin panel</p>
        <Progress
          type="line"
          percent={percent}
          strokeColor="#2c8f85"
          trailColor="white"
          style={{ width: 300 }}
        />
      </div>
    </div>
  );
}
