import { useEffect, useState } from "react";

import { Button, Drawer, Form, Input, notification } from "antd";
import REQUESTS from "../../../api/requests";
import ImageUpload from "../../../components/ImageUpload";

export default function NewsDrawer({ onClose, open, current, getData }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const [image, setImage] = useState({
        url: null,
        file: null,
    });

    const onFinish = (values) => {
        setIsLoading(true);

        const formData = new FormData();
        if (image.file) {
            formData.append("image", image.file);
        }
        formData.append("title", values.title);
        formData.append("body", values.body);

        if (current) {
            formData.append("id", current.id);

            REQUESTS.WEB_PAGE.NEWS.EDIT(formData)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            message: "Error",
                            description: response.message,
                        });

                        return;
                    }
                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        } else {
            REQUESTS.WEB_PAGE.NEWS.ADD(formData)
                .then((response) => {
                    setIsLoading(false);

                    if (response.error) {
                        notification.error({
                            message: "Error",
                            description: response.message,
                        });

                        return;
                    }
                    getData();
                    onClose();
                })
                .catch((err) => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setImage({
                url: null,
                file: null,
            });
        }

        if (current) {
            form.setFields([
                { name: "title", value: current.title },
                { name: "body", value: current.body },
            ]);
            setImage({
                url: current?.image,
                file: null,
            });
        }
    }, [open, current]);

    return (
        <Drawer
            title={`${current ? "Edit" : "Add"} news`}
            placement="right"
            onClose={onClose}
            open={open}
        >
            <Form form={form} name="news" layout="vertical" onFinish={onFinish}>
                <Form.Item
                    label="Image"
                    name="image"
                    rules={[
                        {
                            required: image ? false : true,
                            message: "Please choose image",
                        },
                    ]}
                >
                    <ImageUpload image={image} setImage={setImage} />
                </Form.Item>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: "Please input title",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="body"
                    rules={[
                        {
                            required: true,
                            message: "Please input description",
                        },
                    ]}
                >
                    <Input.TextArea rows={6} />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <Button type="primary" onClick={() => form.submit()} loading={isLoading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}
