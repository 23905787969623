import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}v2/admin/`;

const URL = {
  PLATFORMS: `${host}/platform/not_supported/`,
  CONTACT: `${host}/platform/contact_us`
};

const moreAppsApi = {
  GET: () => request("GET", URL.PLATFORMS),

  CONTACT: (id) => request("POST", URL.CONTACT, { id })
};

export default moreAppsApi;
