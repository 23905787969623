import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  CAST: `${host}v2/admin/cast`
};

const castApi = {
  GET: (query) => request("GET", URL.CAST, query),

  ADD: (query) => request("POST", URL.CAST, query),

  EDIT: (query) => request("PUT", URL.CAST, query),

  DELETE: (query) => request("DELETE", URL.CAST, query),

  GALERY: {
    ADD: (query) => request("POST", `${URL.CAST}/galery`, query),

    DELETE: (query) => request("DELETE", `${URL.CAST}/galery`, query)
  }
};

export default castApi;
