import { useState } from "react";

import { Image, Popconfirm, notification } from "antd";

import ButtonComponent from "../../../../_components/ButtonComponent";

import styles from "../_cast.module.scss";
import REQUESTS from "../../../../api/requests";

export default function Galery({ item, deleteGalery }) {
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = () => {
    setIsLoading(true);

    REQUESTS.VOD.CAST.GALERY.DELETE({ id: item.id })
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message
          });

          return;
        }

        notification.success({
          message: "Success",
          description: "Gallery is deleted"
        });

        deleteGalery(item.id);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles["galery"]}>
      <Image src={item?.source} className={styles["image"]} />

      <Popconfirm
        placement="topRight"
        title="Do you want to delete this gallery?"
        onConfirm={onDelete}
        okText="Delete"
        okButtonProps={{
          danger: true
        }}
        cancelText="Cancel"
      >
        <ButtonComponent title="Delete" danger={true} isLoading={isLoading} />
      </Popconfirm>
    </div>
  );
}
