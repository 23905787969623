import { notification } from "antd";
import { useState } from "react";

import REQUESTS from "../../../api/requests";

import PageComponent from "../../../_components/page/PageComponent";

import TvodPaymentDrawer from "./TvodPaymentDrawer";
import TvodPaymentTable from "./TvodPaymentTable";

export default function TvodPaymentPage() {
  const [getAgain, setGetAgain] = useState(false);

  const [editable, setEditable] = useState(null);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.PAYMENTS.TVOD_PAYMENT.GET(query)
      .then((response) => {
        onSuccess(response.message);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "delete":
        REQUESTS.PAYMENTS.TVOD_PAYMENT.DELETE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: response.message
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: error
            });
          });

      default:
        break;
    }
  };

  return (
    <PageComponent routes={["Monetization", "TVOD payment"]}>
      <TvodPaymentTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <TvodPaymentDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setEditable(null);
          setIsOpenDrawer(false);
        }}
      />
    </PageComponent>
  );
}
