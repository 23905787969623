import request from "../../../api/request";

import { getToken } from "../../../server/requests";

import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  USERS: `${host}admin/users`,

  BALANCE: `${host}payment/balance/`,

  DEVICES: `${host}admin/users/devices`,

  REMOVE_DEVICE: `${host}admin/devices/remove`
};

const userApi = {
  GET: (query) => request("GET", URL.USERS, query),

  BALANCE: (type, query) => request("POST", `${URL.BALANCE}${type}`, query),

  DEVICES: {
    GET: (query) => request("GET", URL.DEVICES, query),

    DELETE: (query) => request("POST", URL.REMOVE_DEVICE, query)
  }
};

export default userApi;
