import { useEffect, useState } from "react";
import { Form } from "antd";

import styles from "../styles/edit_video.module.scss";
import ImageUpload from "../../../../components/ImageUpload";
import Container from "./Container";

const imageStyle = {
  width: 250
};

export default function Images({ data, form }) {
  const [poster, setPoster] = useState({
    url: null,
    file: null
  });

  const [cover, setCover] = useState({
    url: null,
    file: null
  });

  const [backdrop, setBackdrop] = useState({
    url: null,
    file: null
  });

  const [tvHighlight, setTvHighlight] = useState({
    url: null,
    file: null
  });

  const [highlightMobile, setHighlightMobile] = useState({
    url: null,
    file: null
  });

  const [imageTitle, setImageTitle] = useState({
    url: null,
    file: null
  });

  useEffect(() => {
    if (data) {
      setPoster({
        url: data.poster || null,
        file: null
      });

      setBackdrop({
        url: data.backdrop || null,
        file: null
      });

      setCover({
        url: data.poster_tv || null,
        file: null
      });

      setTvHighlight({
        url: data.highlight_tv || null,
        file: null
      });

      setHighlightMobile({
        url: data.highlight_mobile || null,
        file: null
      });

      setImageTitle({
        url: data.image_title || null,
        file: null
      });
    }
  }, [data]);

  const onChangePoster = (value) => {
    setPoster(value);

    form.setFields([{ name: "poster", value: value.file }]);
  };

  const onChangeCover = (value) => {
    setCover(value);

    form.setFields([{ name: "poster_tv", value: value.file }]);
  };

  const onChangeImageTitile = (value) => {
    setImageTitle(value);

    form.setFields([{ name: "image_title", value: value.file }]);
  };

  const onChangeHighlightTv = (value) => {
    setTvHighlight(value);

    form.setFields([{ name: "highlight_tv", value: value.file }]);
  };

  const onChangeHighlightMobile = (value) => {
    setHighlightMobile(value);

    form.setFields([{ name: "highlight_mobile", value: value.file }]);
  };

  const onChangeBackdrop = (value) => {
    setBackdrop(value);

    form.setFields([{ name: "backdrop", value: value.file }]);
  };

  return (
    <Container title="Images" style={{ width: "300!important", minWidth: 300 }}>
      <div className={styles["images-section"]}>
        <Form.Item
          name="poster"
          label="Poster (1080 x 1920)"
          style={{ marginBottom: 0 }}
        >
          <ImageUpload
            image={poster}
            setImage={onChangePoster}
            style={imageStyle}
          />
        </Form.Item>

        <Form.Item
          label="Cover (1920 x 1080)"
          name="poster_tv"
          style={{ marginBottom: 0 }}
        >
          <ImageUpload
            image={cover}
            setImage={onChangeCover}
            style={imageStyle}
          />
        </Form.Item>

        <Form.Item
          label="Image title (950 x 250)"
          name="image_title"
          style={{ marginBottom: 0 }}
        >
          <ImageUpload
            image={imageTitle}
            setImage={onChangeImageTitile}
            style={imageStyle}
          />
        </Form.Item>

        <Form.Item
          label="Tv highlight (1920 x 1080)"
          name="highlight_tv"
          style={{ marginBottom: 0 }}
        >
          <ImageUpload
            image={tvHighlight}
            setImage={onChangeHighlightTv}
            style={imageStyle}
          />
        </Form.Item>

        <Form.Item
          name="highlight_mobile"
          label="Mobile highlight (1920 x 1080)"
          style={{ marginBottom: 0 }}
        >
          <ImageUpload
            image={highlightMobile}
            setImage={onChangeHighlightMobile}
            style={imageStyle}
          />
        </Form.Item>

        <Form.Item
          label="Backdrop (1920 x 1080)"
          name="backdrop"
          style={{ marginBottom: 0 }}
        >
          <ImageUpload
            image={backdrop}
            setImage={onChangeBackdrop}
            style={imageStyle}
          />
        </Form.Item>
      </div>
    </Container>
  );
}
