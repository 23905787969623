import { Divider } from "antd";
import styles from "../styles/edit_video.module.scss";

export default function Container({ children, title, style }) {
  return (
    <div className={styles["container"]} style={style}>
      {title && <Divider orientation="left">{title}</Divider>}
      {children}
    </div>
  );
}
