import { Button, Divider, notification } from "antd";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML
} from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";
import icons from "../../../config/icons";

export default function DocumentEditor({ item }) {
  const [isLoading, setIsLoading] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(item?.text || "")
    );
    return EditorState.createWithContent(contentState);
  });

  const customToolbar = {
    options: [
      "inline",
      "blockType",
      "list",
      "textAlign",
      "fontFamily",
      "fontSize",
      "remove",
      "history"
    ],
    inline: {
      options: ["bold", "italic", "underline"]
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "h4", "h5", "h6", "Blockquote"]
    }
  };

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const convertToHtml = () => {
    const contentState = editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(contentState));
    return html;
  };

  const onSave = () => {
    setIsLoading(true);
    REQUESTS.WEB_PAGE.DOCUMENTS.EDIT({
      id: item.id,
      text: convertToHtml()
    })
      .then((res) => {
        setIsLoading(false);

        if (res.error) {
          notification.error({
            message: "Error",
            description: res.message
          });
          return;
        }

        notification.success({
          message: "Success",
          description: "Document updated successfully"
        });
      })
      .catch((err) => {
        setIsLoading(false);

        notification.error({
          message: "Error",
          description: err
        });
      });
  };

  useEffect(() => {
    if (item?.text) {
      const contentState = ContentState.createFromBlockArray(
        convertFromHTML(item?.text)
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [item]);

  return (
    <div className="document-editor">
      <Divider orientation="left">{item?.name}</Divider>

      <Editor
        toolbar={customToolbar}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        editorStyle={{
          border: "1px solid #f1f1f1",
          padding: 10
        }}
      />

      <Button
        type="primary"
        icon={icons.CHECK}
        onClick={onSave}
        loading={isLoading}
        style={{ marginTop: 20 }}
      >
        Save
      </Button>
    </div>
  );
}
