import { List } from "antd";
import { useEffect, useState } from "react";

import icons from "../../../../../config/icons";

import VodDrawer from "./vod/VodDrawer";
import REQUESTS from "../../../../../api/requests";
import { vodServiceId } from "../../../../../config/statics";

export default function Content({ editable, vodService, setVodSeervice }) {
  const [isOpenVodDrawer, setIsOpenVodDrawer] = useState(false);
  const [selectedMoviesCount, setSelectedMoviesCount] = useState(0);

  const showDrawer = (key) => {
    if (key === "vod") {
      setIsOpenVodDrawer(true);
    }
  };

  useEffect(() => {
    if (editable) {
      REQUESTS.PAYMENTS.SUBSCRIPTIONS.CONTENT({
        filter: JSON.stringify(vodService),
        serviceId: vodServiceId
      })
        .then((response) => {
          if (response.message) {
            setSelectedMoviesCount(response.message?.total);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [vodService, editable]);

  return (
    <>
      <List
        dataSource={[
          {
            id: 2,
            name: "Videos",
            key: "vod",
            icon: icons.LIVE_TV,
            description: `You have ${selectedMoviesCount} selected videos`
          }
        ]}
        bordered
        renderItem={(item) => {
          if (item) {
            return (
              <List.Item
                key={item.id}
                actions={[
                  <div
                    onClick={() => showDrawer(item.key)}
                    key={`a-${item.id}`}
                    style={{ cursor: "pointer" }}
                  >
                    View list
                  </div>
                ]}
              >
                <List.Item.Meta
                  title={item.name}
                  description={item.description}
                />
              </List.Item>
            );
          }
        }}
      />
      <VodDrawer
        open={isOpenVodDrawer}
        vodService={vodService}
        setVodSeervice={setVodSeervice}
        onClose={() => setIsOpenVodDrawer(false)}
        setSelectedMoviesCount={setSelectedMoviesCount}
      />
    </>
  );
}
