import { useState } from "react";

import { Image, Popconfirm, notification } from "antd";

import REQUESTS from "../../../../../api/requests";

import ButtonComponent from "../../../../../_components/ButtonComponent";

import styles from "../../styles/gallery_drawer.module.scss";

export default function Gallery({ item, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const onDelete = () => {
    setIsLoading(true);

    REQUESTS.VOD.VIDEOS.GALLERY.DELETE({ id: item.id })
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: "Error",
            description: response.message
          });

          return;
        }

        notification.success({
          message: "Success",
          description: "Gallery is deleted"
        });

        getData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles["gallery-item"]}>
      <Image src={item?.source} className={styles["image"]} />

      <Popconfirm
        placement="topRight"
        title="Do you want to delete this icon?"
        onConfirm={onDelete}
        okText="Delete"
        okButtonProps={{
          danger: true
        }}
        cancelText="Cancel"
      >
        <ButtonComponent title="Delete" danger={true} isLoading={isLoading} />
      </Popconfirm>
    </div>
  );
}
