const PATHS = {
  // a
  ADS: "/dashboard/ads",
  ANALYTICS: "/dashboard/analytics",
  APPLICATION_BASIC: "/dashboard/basic",
  APPLICATION_BASIC_INFO: "/dashboard/basic-info/",
  APPLICATION_LANGUAGE: "/dashboard/language/",
  APPLICATION_LANGUAGES: "/dashboard/languages",
  ACCOUNT: "/dashboard/acount",

  // b
  // c
  CAST: "/dashboard/cast",
  CHAT: "/dashboard/chat",
  COLLECTION: "/dashboard/collection",
  MORE_APPS: "/dashboard/more-apps",
  CONTENT_PRIVACY: "/dashboard/content-privacy",

  // d
  DASHBOARD: "/dashboard",
  DOCUMENTS: "/dashboard/documents",

  // e
  EDIT_VIDEO: "/dashboard/edit-video/",
  // f
  // g

  // h

  // i
  INITIAL_WELCOME: "/welcome",

  // j
  // k
  // l
  LOGIN: "/login",

  // m
  MEDIA: "/dashboard/video/",

  // n
  // o
  // p
  TVOD_PAYMENT: "/dashboard/tvod-payment",
  PAYMENT_HISTORY: "/dashboard/payment-history",
  PAYMENT_SUBSCRIPTION: "/dashboard/subscription",
  PAYMENT_CONFIGS: "/dashboard/payment-configs",

  // q
  // r
  REGISTER: "/register",
  RESOLUTIONS: "/dashboard/resolutions",

  // s
  SERVERS: "/dashboard/servers",
  STORE_LINKS: "/dashboard/store-links",

  // t
  TAGS: "/dashboard/tags",

  TEAM_MEMBERS: "/dashboard/team-members",

  // u
  USERS: "/dashboard/users",
  USER: "/dashboard/user/",
  USERS_GROUP: "/dashboard/users-group",

  // v
  VIDEOS: "/dashboard/videos",

  // w
  WELCOME: "/dashboard/welcome",
  WEB_NEWS: "/dashboard/web-news",
  WEB_FAQ: "/dashboard/web-faq",
  WEB_FEATURE: "/dashboard/web-feature",
  WEB_SETTINGS: "/dashboard/web-settings",
  WEB_SLIDER: "/dashboard/web-slider",
  WEB_AVAILABLE_DEVICES: "/dashboard/web-available-devices"

  // x
  // y
  // z
};

export default PATHS;
