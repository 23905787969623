import React, { useEffect, useState } from "react";

import { Layout, Spin, notification } from "antd";

import { Outlet } from "react-router";

import REQUESTS from "../api/requests";

import Header from "./header/Header";

import Sidebar from "./sidebar/Sidebar";

export default function LayoutComponent({}) {
  const [collapsed, setCollapsed] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const getServicesNotification = () => {
    const query = {
      filter: {
        is_archive: false
      }
    };

    REQUESTS.SETTINGS.GENERAL.SERVICE_NOTIFICATION.GET({
      query: JSON.stringify(query)
    })
      .then((response) => {
        if (response.error) return;

        const notifications = response.message?.rows;

        for (let i = 0; i < notifications.length; i++) {
          openNotification(notifications[i]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openNotification = (item) => {
    api.error({
      message: item.service_name,
      description: item.message,
      placement: "bottomRight",
      duration: 0,

      onClose: () => {
        REQUESTS.SETTINGS.GENERAL.SERVICE_NOTIFICATION.CLOSE({ id: item.id });
      }
    });
  };

  useEffect(() => {
    getServicesNotification();
  }, []);

  return (
    <Layout style={{ minHeight: "100vh", overflow: "hidden" }} hasSider>
      {contextHolder}

      <Sidebar collapsed={collapsed} />

      <Layout
        className="site-layout"
        style={{
          marginLeft: collapsed ? 100 : 200,
          transition: "0.4s",
          background: "#fbfdff",
          paddingTop: 70
        }}
      >
        <Header collapsed={collapsed} setCollapsed={setCollapsed} />

        <Layout.Content
          style={{
            padding: "24px 50px",
            background: "#fbfdff",
            overflow: "auto"
          }}
        >
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
