import { useEffect, useState } from "react";

import { Drawer, Form, Input, InputNumber, notification } from "antd";

import ButtonComponent from "../../_components/ButtonComponent";
import REQUESTS from "../../api/requests";
import Roles from "./Roles";
import { scrollToTopOfDrawerBody } from "../../config/config";

export default function AdminDrawer({ onClose, open, editable, getData }) {
  const [isLoading, setIsLoading] = useState(false);

  const [checkedRoles, setCheckedRoles] = useState([]);

  const [defaultCheckedRoles, setDefaultCheckedRoles] = useState([]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    let body = {
      name: values.name,
      surname: values.surname,
      email: values.email,
      phone: values.phone,
      roles: checkedRoles.join(",")
    };

    if (values.password) {
      body.password = values.password;
    }

    if (editable) {
      body.id = editable.id;

      REQUESTS.ADMINS.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: "Error",
              description: response.message
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err
          });

          setIsLoading(false);
        });
    } else {
      REQUESTS.ADMINS.ADD(body)
        .then((response) => {
          setIsLoading(false);
          if (response.error) {
            notification.error({
              message: "Error",
              description: response.message
            });

            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err
          });

          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setCheckedRoles([]);
      setDefaultCheckedRoles([]);
      setIsLoading(false);
    }
    if (editable) {
      form.setFields([
        { name: "name", value: editable.name },
        { name: "surname", value: editable.surname },
        { name: "email", value: editable.email },
        { name: "phone", value: editable.phone }
      ]);

      const _defaultCheckedRoles = editable.roles.map((item) => item.id);

      setDefaultCheckedRoles(_defaultCheckedRoles);
    }
  }, [open, editable]);

  useEffect(() => {
    if (open) {
      scrollToTopOfDrawerBody();
    }
  }, [open]);

  return (
    <Drawer
      title={`${editable ? "Edit" : "Add"} member`}
      placement="right"
      onClose={onClose}
      open={open}
      width={700}
    >
      <Form
        form={form}
        name="admin"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
        autoComplete="false"
      >
        <div style={{ width: "100%", display: "flex", gap: 20 }}>
          <Form.Item
            label="Name"
            name="name"
            style={{ width: "50%" }}
            rules={[
              {
                required: true,
                message: "Please input name"
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Surname"
            name="surname"
            style={{ width: "50%" }}
            rules={[
              {
                required: true,
                message: "Please input surname"
              }
            ]}
          >
            <Input />
          </Form.Item>
        </div>

        <div style={{ width: "100%", display: "flex", gap: 20 }}>
          <Form.Item
            label="Email"
            name="email"
            style={{ width: "50%" }}
            rules={[
              {
                required: true,
                message: "Please input email"
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            style={{ width: "50%" }}
            rules={[
              {
                required: editable ? false : true,
                message: "Please input password"
              }
            ]}
          >
            <Input.Password
              autoComplete="false"
              autoCapitalize="new password"
            />
          </Form.Item>
        </div>

        <div style={{ width: "100%", display: "flex", gap: 20 }}>
          <Form.Item label="Phone" name="phone" style={{ width: "50%" }}>
            <InputNumber controls={false} style={{ width: "100%" }} />
          </Form.Item>

          <div style={{ width: "50%" }}></div>
        </div>

        <Roles
          open={open}
          setCheckedRoles={setCheckedRoles}
          defaultCheckedRoles={defaultCheckedRoles}
        />

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
