import { Button, Form, Input } from "antd";

import icons from "../../../config/icons";

export default function AdsUrl({ form }) {
    const onClick = () => {
        const { url } = form.getFieldsValue();

        const adsUrl = `http://ads_test.inorain.com/?tag=${url}&name=custom&type=custom`;

        window.open(adsUrl, "_blank");
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 10,
            }}
        >
            <Form.Item shouldUpdate noStyle>
                {() => {
                    return (
                        <Form.Item
                            label="Url"
                            name="url"
                            style={{ width: "100%" }}
                            rules={[
                                {
                                    required: true,
                                    message: "Please input url",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    );
                }}
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {() => {
                    const { type, url } = form.getFieldsValue();

                    return (
                        type === "IMA" && (
                            <Button
                                style={{ marginTop: 5 }}
                                type="primary"
                                icon={icons.PLAY}
                                onClick={onClick}
                                disabled={!url}
                            />
                        )
                    );
                }}
            </Form.Item>
        </div>
    );
}
