import { useEffect, useState } from "react";

import icons from "../../../config/icons";
import { getColumnSearchProps } from "../../../config/config";

import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

export default function TvodPaymentTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState({
    name: null,
    categories: null,
  });

  const columns = [
    {
      width: 60,
      title: "#",
      key: "index",
      dataIndex: `id`,
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",

      ...getColumnSearchProps(),
    },

    {
      title: "Resolution name",
      key: "resolution_name",
      dataIndex: "resolution_name",
      align: "center",
      render: (text, record, index) => {
        return (
          <>
            {record?.pricing_resolutions?.map((el) => {
              return <p>{el?.name}</p>;
            })}
          </>
        );
      },
    },

    {
      title: "Price",
      key: "resolution_price",
      dataIndex: "resolution_price",
      align: "center",
      render: (text, record, index) => {
        return (
          <>
            {record?.pricing_resolutions?.map((el) => {
              return (
                <>
                  <p>{el?.price}</p>
                </>
              );
            })}
          </>
        );
      },
    },

    {
      title: "Rent price",
      key: "resolution_rent_price",
      dataIndex: "resolution_rent_price",
      align: "center",
      render: (text, record, index) => {
        return (
          <>
            {record?.pricing_resolutions?.map((el) => {
              return <p>{el?.rent_price}</p>;
            })}
          </>
        );
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: "Edit",
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: "Delete",
              icon: icons.DELETE,
              question: `Do you want to delete this group?`,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);

    const paymentParams = {
      sort: data.sort,
      limit: data.limit,
      page: data.page,
    };

    sessionStorage.setItem("paymentParams", JSON.stringify(paymentParams));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const paymentPage = JSON.parse(sessionStorage.getItem("paymentParams"));

      const query = {
        sort: paymentPage ? paymentPage?.sort : sort,
        limit: paymentPage ? paymentPage?.limit : limit,
        page: paymentPage ? paymentPage?.page : currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name[0],
        };
      }

      function onSuccess(data) {
        if (data.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);
        setDataSource(data.rows);

        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, search, getAgain]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent icon={icons.ADD} title="Add" onClick={openDrawer} />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
}
