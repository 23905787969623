import { useState, useEffect } from "react";

import { Form, Button, notification, Divider } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import styles from "../_basic-info.module.scss";

export default function ImagesTab({ data, getData }) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState();

  const [logo, setLogo] = useState({
    url: null,
    file: null
  });

  const [mobileLogo, setMobileLogo] = useState({
    url: null,
    file: null
  });

  const [startLogo, setStartLogo] = useState({
    url: null,
    file: null
  });

  const [backgroundImage, setBackgroundImage] = useState({
    url: null,
    file: null
  });

  const onFinish = () => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append("id", data.id);

    if (logo.file) formData.append("logo", logo.file);
    if (mobileLogo.file) formData.append("mobile_logo", mobileLogo.file);
    if (startLogo.file) formData.append("start_logo", startLogo.file);
    if (backgroundImage.file)
      formData.append("background_image", backgroundImage.file);

    REQUESTS.APPLICATION.BASIC_INFO.EDIT(formData)
      .then((data) => {
        setIsLoading(false);

        if (data.error) {
          notification.error({
            message: "Error",
            description: data.message
          });

          return;
        }

        notification.success({
          message: "Success",
          description: "Application information updated successfully"
        });

        getData(true);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    form.resetFields();

    if (data) {
      setLogo({
        url: data.logo,
        file: null
      });

      setMobileLogo({
        url: data.mobile_logo,
        file: null
      });

      setStartLogo({
        url: data.start_logo,
        file: null
      });

      setBackgroundImage({
        url: data.background_image,
        file: null
      });
    }
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      logo: logo.url,
      mobile_logo: mobileLogo.url,
      start_logo: startLogo.url,
      background_image: backgroundImage.url
    });
  }, [logo, mobileLogo, startLogo, backgroundImage]);

  return (
    <div className={styles["container"]}>
      <Divider orientation="left">Images</Divider>

      <Form form={form} name="app-images" layout="vertical" onFinish={onFinish}>
        <div className={styles["images-list"]}>
          <Form.Item label="Logo" name="logo">
            <ImageUpload image={logo} setImage={setLogo} />
          </Form.Item>

          <Form.Item label="Mobile logo" name="mobile_logo">
            <ImageUpload image={mobileLogo} setImage={setMobileLogo} />
          </Form.Item>

          <Form.Item label="Start logo" name="start_logo">
            <ImageUpload image={startLogo} setImage={setStartLogo} />
          </Form.Item>

          <Form.Item label="Background image" name="background_image">
            <ImageUpload
              image={backgroundImage}
              setImage={setBackgroundImage}
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            style={{ width: "100%", maxWidth: 330 }}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
