import { useState, useEffect } from "react";
import REQUESTS from "../api/requests";

export function useAgeRestrictionOptions() {
  const list = [];

  for (let i = 0; i <= 21; i++) {
    list.push({
      value: `${i}`,
      label: `${i}`
    });
  }

  return list;
}

export function useCountriesOptions(type = "label", value = "name") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.COUNTRIES.GET().then((response) => {
      if (!response.error) {
        const data = response.message.map((item) => {
          return {
            [type]: item.name,
            value: item.id,

            ...item
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useDevicesOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.DEVICES.GET().then((response) => {
      if (!response.error) {
        const data = response.message.map((item) => {
          return {
            label: item.name,
            value: item.name,

            ...item
          };
        });

        setList([{ label: "Select all", value: "all" }, ...data]);
      }
    });
  }, []);

  return list;
}

export function useArchiveServersOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.CATCH_UP.SERVERS.GET().then((response) => {
      if (!response.error) {
        const data = response.message.rows.map((item) => {
          return {
            label: item.name,
            value: item.id,
            ...item
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useUsersOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.USERS.USERS.SEARCH().then((response) => {
      if (!response.error) {
        const data = response.message?.map((item) => {
          return {
            [type]: item.name,
            value: item.id,

            ...item
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useUsersGroupsOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.USERS.GROUPS.GET({ query: JSON.stringify({ limit: 1000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message?.rows.map((item) => {
            return {
              [type]: item.name,
              value: item.id,

              ...item
            };
          });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useCurrencyOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.CURRENCY().then((response) => {
      if (!response.error) {
        const data = response.message?.rows?.map((item) => {
          return {
            label: item.name,
            value: item.id,

            ...item
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function usePaymentsSubscriptionsOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.PAYMENTS.SUBSCRIPTIONS.GET({
      query: JSON.stringify({ limit: 1000 })
    }).then((response) => {
      if (!response.error) {
        const data = response.message?.rows?.map((item) => {
          return {
            [type]: item.name,
            value: item.id,

            ...item
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useTvodPaymentsOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.PAYMENTS.TVOD_PAYMENT.GET({
      query: JSON.stringify({ limit: 1000 })
    }).then((response) => {
      if (!response.error) {
        const data = response.message?.rows?.map((item) => {
          return {
            [type]: item.name,
            value: item.id,

            ...item
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useVodGenresOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.VOD.GENRES.GET({ query: JSON.stringify({ limit: 1000 }) }).then(
      (response) => {
        if (!response.error) {
          const data = response.message?.rows?.map((item) => {
            return {
              [type]: item.name,
              value: item.id,

              ...item
            };
          });

          setList(data);
        }
      }
    );
  }, []);

  return list;
}

export function useVideoCollectionsOptions(type = "label") {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.VOD.COLLECTIONS.GET({
      query: JSON.stringify({
        limit: 1000,
        filter: {
          default: false
        }
      })
    }).then((response) => {
      if (!response.error) {
        const data = response.message?.rows?.map((item) => {
          return {
            [type]: item.name,
            value: item.id,

            ...item
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}

export function useYearOptions() {
  const currentDate = new Date().getFullYear();

  const [list, setList] = useState([]);

  useEffect(() => {
    const _list = [];

    for (let i = 1970; i < currentDate; i++) {
      _list.push({
        label: i,
        value: i
      });
    }

    setList(_list);
  }, []);

  return list;
}

export function useLanguagesOptions() {
  const [list, setList] = useState([]);

  useEffect(() => {
    REQUESTS.APPLICATION.LANGUAGES.GET({
      query: JSON.stringify({ limit: 100000 })
    }).then((response) => {
      if (!response.error) {
        const data = response.message.rows.map((item) => {
          return {
            label: item.name,
            value: item.id,
            ...item
          };
        });

        setList(data);
      }
    });
  }, []);

  return list;
}
