import { useEffect, useState } from "react";
import request from "../../../server/requests";
import { Gauge } from "@ant-design/plots";
import { Col, Row, Divider } from "antd";
import ItemDescription from "./ItemDescription";
import like from '../assets/mdi_like.svg'
import dislike from '../assets/dislike.svg'
import eye from '../assets/eye.svg';

export default function BestMovie() {
  const [movie, setMovie] = useState({});
  const [err, setError] = useState(null);
  const [config, setConfig] = useState({});

  const getMovie = () => {
    request.statistics.top_watched(
      { limit: 1 },
      (data) => {

        if (data.length) {
          setMovie(data[0]);
          setConfig({
            percent: 0.75,
            range: {
              color: "l(0) 0:#B8E1FF 1:#1879f4"
            },
            startAngle: Math.PI,
            endAngle: 2 * Math.PI,
            indicator: null,
            statistic: {
              title: {
                offsetY: -36,
                style: {
                  fontSize: "60px",
                  color: "#1879f4",
                  fontWeight: "bold"
                },
                formatter: () => data[0].views_count
              },
              content: {
                style: {
                  fontSize: "30px",
                  lineHeight: "60px",
                  color: "#4B535E"
                },
                formatter: () => "TOTAL VIEWS"
              }
            }
          });
        } else {
          setMovie([]);
        }
      },
      (err) => {
        setError(err);
      }
    );
  };

  useEffect(() => {
    getMovie();
  }, []);

  return (
    <div>
      <ItemDescription
        content={"Current chart is displaying the most watched video"}
        title={"Top video of all time"}
      />

      <Row>

        <Col span={24}>
          {config.percent ? (
            <Gauge style={{ height: "150px" }} {...config} />
          ) : (
            ""
          )}
        </Col>
        <Divider />
        <Col span={12}>
          <div >
            <img src={movie.poster} style={{ width: "100%" }} alt={movie.name} />
          </div>
        </Col>
        <Col span={12}>
          <h5 style={{ textAlign: "center" }}>{movie.name}</h5>
          <ul className="best_movie_data">

            <li><img src={like} />{movie.like}</li>
            <li><img src={dislike} />{movie.dislike}</li>
            <li><img src={eye} />{movie.views_count}</li>
          </ul>

        </Col>

      </Row>
    </div>
  );
}
