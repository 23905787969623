
import { Button, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function ItemDescription({ title, content }) {



    return (
        <>
            <h4>
                <Popover content={content} title={title}>
                    <InfoCircleOutlined style={{
                        cursor: "pointer",
                        color: "#1878f3",
                        fontSize: "20px",
                        marginRight: "5px",
                        marginLeft: "15px"
                    }} />
                </Popover>
                {title}
            </h4>
            <hr />
        </>

    );
}

