import IMAGES from "../../config/images";
import styles from "./styles.module.scss";

function MobileModal({}) {
  return (
    <div className={styles["modal-wrapper"]}>
      <img src={IMAGES.LOGO} />

      <p>
        For better experience we are suggesting to use studio panel in the
        laptop
      </p>
    </div>
  );
}

export default MobileModal;
