import request from "../../request";
import { DEMO_API_HOST } from "../../../config/statics";

const host = `${process.env.REACT_APP_API_HOST || DEMO_API_HOST}`;

const URL = {
  COUNTRY: `${host}admin/country`,

  DEVICES: `${host}admin/devices/`,

  CURRENCY: `${host}payment/currency`,

  WIDGETS: `${host}admin/widgets`
};

export default {
  COUNTRIES: {
    GET: (query) => request("GET", URL.COUNTRY, query)
  },

  CURRENCY: () => {
    const query = {
      limit: 1000
    };

    return request("GET", URL.CURRENCY, { query: JSON.stringify(query) });
  },

  DEVICES: {
    GET: (query) => request("POST", `${URL.DEVICES}count`, query)
  },

  WIDGETS: {
    GET: (query) => request("GET", URL.WIDGETS, query),

    ENABLE: (query) => request("PUT", `${URL.WIDGETS}/enable`, query),

    DISABLE: (query) => request("PUT", `${URL.WIDGETS}/disable`, query)
  }
};
