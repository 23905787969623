import { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import defaultOpenKeys from "./defaultOpenKeys";

import pages from "./pages";
import IMAGES from "../../config/images";

import * as actions from "../../redux-store/actions";

import REQUESTS from "../../api/requests";
import { APP_VERSION } from "../../config/statics";

import style from "./sidebar.module.scss";

export default function Sidebar({ collapsed }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const [current, setCurrent] = useState(location.pathname);

  const { setPermissions } = bindActionCreators(actions, dispatch);

  const getPermissions = () => {
    REQUESTS.PROFILE.PERMISSIONS()
      .then((response) => {
        if (response.error) {
          return;
        }

        setPermissions(response.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location.pathname]);

  const onClick = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    navigate(current);
  }, [current]);

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className={style["sidebar"]}
      style={{
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0
      }}
    >
      <header style={{ height: collapsed ? 100 : 150 }}>
        <img alt="Inorain" width={collapsed ? 50 : 140} src={IMAGES.LOGO} />
      </header>

      <div className={style["sidebar-menu"]}>
        <div className={style["routes"]}>
          <Menu
            onClick={onClick}
            defaultOpenKeys={[defaultOpenKeys(location.pathname)]}
            onDoubleClick={() => window.location.reload()}
            selectedKeys={[current]}
            mode="inline"
            items={pages}
            className="antd-sidebar-menu"
          />
        </div>

        <div className={style["version"]}>
          <b>Version:</b>
          <span>{APP_VERSION}</span>
        </div>
      </div>
    </Layout.Sider>
  );
}
