import { useState } from "react";
import { message } from "antd";

import REQUESTS from "../../../api/requests";

import UsersTable from "./UsersTable";
import UserDrawer from "./UserDrawer";
import TariffDrawer from "./TariffDrawer";

import PageComponent from "../../../_components/page/PageComponent";

export default function UsersPage() {
  const [getAgain, setGetAgain] = useState(false);

  const [editable, setEditable] = useState(null);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [userForTariff, setUserForTariff] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.USERS.USERS.GET({ query: JSON.stringify(query) })
      .then((response) => {
        onSuccess(response.message);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "tariff":
        setUserForTariff(item);
        break;

      case "lock":
        REQUESTS.USERS.USERS.LOCK({ user_id: item.id })
          .then((response) => {
            if (response.error) {
              message.error(response.message);
              return;
            }

            message.success(response.message);

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            message.error(error);
          });

        break;

      case "unlock":
        REQUESTS.USERS.USERS.UNLOCK({ user_id: item.id })
          .then((response) => {
            if (response.error) {
              message.error(response.message);
              return;
            }

            message.success(response.message);

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            message.error(error.message);
          });
        break;

      case "archive":
        REQUESTS.USERS.USERS.ARCHIVE({ id: item.id })
          .then((response) => {
            if (response.error) {
              message.error(response.message);
              return;
            }

            message.success(response.message);

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            message.error(error.message);
          });

        break;

      case "restore":
        REQUESTS.USERS.USERS.RESTORE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              if (response.error) {
                message.error(response.message);
                return;
              }

              message.success(response.message);

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            message.error(error.message);
          });

        break;
      default:
        break;
    }
  };

  return (
    <PageComponent routes={["People", "People"]}>
      <UsersTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <TariffDrawer
        open={userForTariff ? true : false}
        user={userForTariff}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setUserForTariff(null);
        }}
      />

      <UserDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />
    </PageComponent>
  );
}
