import { useState } from "react";

import { Modal, Form, notification, Select } from "antd";

import REQUESTS from "../../../api/requests";

import { usePaymentsSubscriptionsOptions } from "../../../hooks/selectOptions";

import ButtonComponent from "../../../_components/ButtonComponent";

function OtherSubscriptionModal({ id, setGetAgain, destroy }) {
    const [isLoading, setIsLoading] = useState(false);

    let options = usePaymentsSubscriptionsOptions();

    options = options.filter((option) => option.value !== id);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        REQUESTS.PAYMENTS.SUBSCRIPTIONS.DELETE({ id: id, newId: values.id })
            .then((response) => {
                if (!response.error) {
                    setGetAgain((prev) => !prev);
                    destroy();
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Error",
                    description: error,
                });
            });
    };

    return (
        <Form
            form={form}
            name="delete-subscription"
            layout="vertical"
            onFinish={onFinish}
            onKeyPress={(e) => {
                if (e.key === "Enter") {
                    form.submit();
                }
            }}
        >
            <p style={{ marginBottom: 15 }}>
                Choose the subscription where will be moved all users from deleted subscription or
                leave it blank if you sure there is no user on deleted subscription
            </p>
            <Form.Item
                label="Subscriptions"
                name="id"
                rules={[
                    {
                        required: true,
                        message: "Please select tariff",
                    },
                ]}
            >
                <Select options={options} />
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
                <ButtonComponent
                    title="Save"
                    actionType="save"
                    isLoading={isLoading}
                    onClick={() => form.submit()}
                />
            </Form.Item>
        </Form>
    );
}

export default function deleteSubscription(id, setGetAgain) {
    const modal = Modal.confirm();

    modal.update({
        width: 500,
        title: "Are you sure you want to delete this subscription?",
        // icon: <ExclamationCircleFilled />,
        content: (
            <OtherSubscriptionModal
                id={id}
                setGetAgain={setGetAgain}
                destroy={() => modal.destroy()}
            />
        ),
        closable: true,
        footer: null,
    });
}
