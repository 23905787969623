import { Button, Result } from "antd";
import { useNavigate } from "react-router";

import PATHS from "../../config/paths";

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <Result
      status="403"
      title=""
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={() => navigate(PATHS.ANALYTICS)}>
          Back Home
        </Button>
      }
    />
  );
}
