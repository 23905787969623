import { useEffect, useState } from "react";

import icons from "../../../config/icons";
import { getColumnDateProps, getQueryDate, parseDate } from "../../../config/config";

import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import { Button } from "antd";

export default function PaymentsSubsriptionsTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "ASC"]);

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState({
    user: null,
  });

  const setupColumns = () => {
    const columns = [
      {
        width: 60,
        title: "#",
        key: "index",
        dataIndex: `id`,
        fixed: "left",
        align: "center",
        render: (text, record, index) => {
          return limit * (currentPage - 1) + index + 1;
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
        ellipsis: true,
        width: 200,
      },

      {
        title: "Price",
        width: 90,
        key: "price",
        dataIndex: `price`,
        align: "center",
        sorter: true,
      },

      {
        title: "Device count",
        key: "device_service",
        dataIndex: `device_service`,
        align: "center",
        width: 110,

        render: (text, record, index) => {
          let count = 0;

          if (record?.service) {
            for (let i = 0; i < record?.service.length; i++) {
              if (record?.service[i]?.serviceId == 4) {
                count = record?.service[i]?.value;
              }
            }
          }

          return <div>{count || 0}</div>;
        },
      },

      {
        title: "Payment type",
        key: "payment_type",
        dataIndex: `payment_type`,
        align: "center",
        width: 130,
      },
    ];

    columns.push(
      {
        title: "Scheduling",
        key: "scheduling_time",
        dataIndex: `scheduling_time`,
        align: "center",
        width: 90,

        render: (text, record, index) => {
          return (
            <div>
              {record.scheduling_time} {record.scheduling_type}
            </div>
          );
        },
      },

      {
        title: "Date",
        key: "createdAt",
        dataIndex: `createdAt`,
        width: 150,
        align: "center",
        render: (text, record, index) => {
          return parseDate(text);
        },

        ...getColumnDateProps(setDate),
      },

      {
        title: "",
        key: "operation",
        fixed: "right",
        width: 60,
        align: "center",

        render: (text, record, index) => (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            items={[
              {
                key: "edit",
                name: "Edit",
                icon: icons.EDIT,
              },

              {
                key: "delete",
                name: "Delete",
                icon: icons.DELETE,
                question: `Do you want to delete this subscription?`,
                onConfirm: (e) => {
                  handleMenuClick(e, record);
                },
              },
            ]}
          />
        ),
      }
    );

    return columns;
  };

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);

    const paymentParams = {
      sort: data.sort,
      limit: data.limit,
      page: data.page,
    };

    sessionStorage.setItem("paymentParams", JSON.stringify(paymentParams));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);
      const paymentPage = JSON.parse(sessionStorage.getItem("paymentParams"));
      const query = {
        sort: paymentPage ? paymentPage.sort : sort,
        limit: paymentPage ? paymentPage.limit : limit,
        page: paymentPage ? paymentPage.page : currentPage,
      };

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      function onSuccess(data) {
        if (data.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);
        setDataSource(data.rows);
        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError() {
        setLoading(false);
      }

      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, sort, search, date, getAgain]);

  return (
    <TableComponent
      header={
        <>
          <Button type="primary" icon={icons.ADD} onClick={openDrawer}>
            Add Subscription
          </Button>
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={setupColumns()}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 1400,
      }}
    />
  );
}
