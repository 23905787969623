import PATHS from "../../config/paths";

export default function defaultOpenKeys(pathname) {
  switch (pathname) {
    case PATHS.USERS_GROUP:
    case PATHS.USERS:
      return "users";

    case PATHS.TVOD_PAYMENT:
    case PATHS.PAYMENT_HISTORY:
    case PATHS.PAYMENT_SUBSCRIPTION:
    case PATHS.PAYMENT_CONFIGS:
      return "payments";

    case PATHS.ADS:
      return "ads";

    case PATHS.APPLICATION_BASIC:
    case PATHS.APPLICATION_LANGUAGES:
    case PATHS.STORE_LINKS:
    case PATHS.MORE_APPS:
      return "application";

    case PATHS.WEB_FAQ:
    case PATHS.WEB_FEATURE:
    case PATHS.WEB_NEWS:
    case PATHS.WEB_SETTINGS:
    case PATHS.WEB_SLIDER:
    case PATHS.WEB_AVAILABLE_DEVICES:
    case PATHS.DOCUMENTS:
      return "web-page";

    case PATHS.VIDEOS:
    case PATHS.TAGS:
    case PATHS.CAST:
    case PATHS.COLLECTION:
    case PATHS.CONTENT_PRIVACY:
      return "vod-manager";

    default:
      return "";
  }
}
