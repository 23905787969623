import { useEffect, useState } from "react";
import { Divider, Select, Space, Tabs } from "antd";

import REQUESTS from "../../../api/requests";

import { useCurrencyOptions } from "../../../hooks/selectOptions";

import PageComponent from "../../../_components/page/PageComponent";

import StripeConfig from "./components/StripeConfig";
import PaypalConfig from "./components/PaypalConfig";
import TelcellConfig from "./components/TelcellConfig";
import AuthorizeConfig from "./components/AuthorizeConfig";
import GoogleConfig from "./components/GoogleConfig";
import AppleConfig from "./components/AppleConfig";
import { useSelector } from "react-redux";

export default function PaymentConfigPage() {
  const { permissions } = useSelector((state) => state.globalState);

  const [data, setData] = useState(null);

  const curencyOptions = useCurrencyOptions();

  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const tabs = [
    permissions?.payments?.paypal && {
      label: "Paypal",
      key: "paypal",
      children: <PaypalConfig data={data} selectedCurrency={selectedCurrency} />
    },

    permissions?.payments?.stripe && {
      label: "Stripe",
      key: "stripe",
      children: <StripeConfig data={data} selectedCurrency={selectedCurrency} />
    },

    permissions?.payments?.authorize && {
      label: "Authorize",
      key: "authorize",
      children: (
        <AuthorizeConfig data={data} selectedCurrency={selectedCurrency} />
      )
    },

    permissions?.payments?.telcell && {
      label: "Telcell",
      key: "telcell",
      children: (
        <TelcellConfig data={data} selectedCurrency={selectedCurrency} />
      )
    },

    permissions?.payments?.google && {
      label: "Google",
      key: "google",
      children: <GoogleConfig data={data} selectedCurrency={selectedCurrency} />
    },

    permissions?.payments?.apple && {
      label: "Apple",
      key: "apple",
      children: <AppleConfig data={data} selectedCurrency={selectedCurrency} />
    }
  ];

  const getConfigs = () => {
    REQUESTS.PAYMENTS.CONFIGS.GET()
      .then((response) => {
        if (response.error) {
          return;
        }

        if (response.message) {
          setData(response.message);

          setSelectedCurrency(response.message.currency_id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getConfigs();
  }, []);

  return (
    <PageComponent routes={["Monetization", "Configs"]}>
      <Space style={{ marginBottom: 20 }}>
        <span>Currency: </span>
        <Select
          value={selectedCurrency}
          showSearch
          showArrow
          onSelect={setSelectedCurrency}
          options={curencyOptions}
          style={{ width: 250 }}
          filterOption={(input, option) =>
            (option?.label.toLowerCase() ?? "").includes(input)
          }
          filterSort={(optionA, optionB) => {
            return (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase());
          }}
        />
      </Space>

      <Divider style={{ marginTop: 0 }}></Divider>

      <Tabs tabPosition="left" items={tabs} />
    </PageComponent>
  );
}
