import { Button } from "antd";
import { PlusOutlined, CheckOutlined } from "@ant-design/icons";

export default function ButtonComponent({
    title,
    type = "primary",
    actionType,
    isLoading,
    onClick,
    disabled,
    style,
    danger,
}) {
    let icon;

    switch (actionType) {
        case "add":
            icon = <PlusOutlined />;
            break;

        case "save":
            icon = <CheckOutlined />;
            break;

        default:
            break;
    }

    return (
        <Button
            disabled={disabled}
            type={type}
            loading={isLoading}
            icon={icon}
            onClick={onClick}
            style={style}
            danger={danger}
        >
            {title}
        </Button>
    );
}
