import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Form, Select, Tabs, message, Input, Tag } from "antd";

import About from "./components/About";
import Monetization from "./components/Monetization";
import Images from "./components/Images";
import Restrictions from "./components/Restrictions";

import styles from "./styles/edit_video.module.scss";

import REQUESTS from "../../../api/requests";
import PATHS from "../../../config/paths";
import PageComponent from "../../../_components/page/PageComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import {
  useCountriesOptions,
  useVideoCollectionsOptions,
  useVodGenresOptions,
} from "../../../hooks/selectOptions";
import Container from "./components/Container";
import VideoTranscode from "../../../_components/video-transcode/VideoTranscode";
import ModalComponent from "./components/ModalComponent";
import UploadVideo from "./components/UploadVideo";
import UrlImport from "./components/UrlImport";
import { SyncOutlined } from "@ant-design/icons";
import { getToken } from "../../../server/requests";

import Player from "../../../_components/Player";

export default function EditVideoPage() {
  const params = useParams();

  const { profile } = useSelector((state) => state.globalState);

  const countriesOptions = useCountriesOptions();

  const genresOptions = useVodGenresOptions();

  const collectionsOptions = useVideoCollectionsOptions();

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState(null);

  const [videoData, setVideoData] = useState({
    type: "file",
    url: "",
  });

  const [trailerData, setTrailerData] = useState({
    type: "file",
    url: "",
  });

  const [openModal, setOpenModal] = useState({
    video: false,
    trailer: false,
  });

  const [openPlayerModal, setOpenPlayerModal] = useState({
    video: false,
    trailer: false,
  });

  const [urlImport, setUrlImport] = useState(null);

  const [form] = Form.useForm();

  const id = params.id;

  const onFinish = (values) => {
    const formData = new FormData();

    formData.append("id", id);
    formData.append("pg", 18);
    formData.append("description", values.description);
    formData.append("company_id", profile?.company_id);
    formData.append("is_location_restriction", values.is_location_restriction);

    // if (videoData.url) {
    //   formData.append("video_input", videoData.url);
    //   formData.append("video_input_type", "file");
    // }
    // if (trailerData.url) {
    //   formData.append("trailer_input", trailerData.url);
    //   formData.append("trailer_input_type", "file");
    // }

    if (values.genres) {
      formData.append("genres", values.genres.join(","));
    }

    if (values.collections) {
      formData.append("collections", values.collections.join(","));
    }

    if (values.available_countries) {
      const filterCountries = values.available_countries?.map((item) =>
        countriesOptions.find((country) => country.value == item)
      );

      formData.append(
        "available_countries",
        filterCountries?.map((item) => item.name)
      );
    }

    formData.append("content_monetization_type", values.content_monetization_type);

    if (values?.name !== data.name) {
      formData.append("name", values.name);
    }

    if (values.content_monetization_type === "svod") {
      formData.append("tariffIds", values?.tariffIds?.join(","));
    } else if (values.content_monetization_type === "tvod") {
      formData.append("pricing_id", values?.pricing_id);
    }

    if (typeof values?.poster === "object") {
      formData.append("poster", values?.poster);
    }

    if (typeof values?.poster_tv === "object") {
      formData.append("poster_tv", values?.poster_tv);
    }

    if (typeof values?.backdrop === "object") {
      formData.append("backdrop", values?.backdrop);
    }

    if (typeof values?.highlight_tv === "object") {
      formData.append("highlight_tv", values?.highlight_tv);
    }

    if (typeof values?.highlight_mobile === "object") {
      formData.append("highlight_mobile", values?.highlight_mobile);
    }

    if (typeof values?.image_title === "object") {
      formData.append("image_title", values?.image_title);
    }

    REQUESTS.VIDEO.EDIT(formData)
      .then((response) => {
        if (response.error) {
          message.error(response.message);

          getData();

          return;
        }

        message.success("Video edited successfully");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const getData = () => {
    if (!id) return;

    REQUESTS.VIDEO.GET(id)
      .then((response) => {
        if (response.error) {
          message.error(response.message);

          return;
        }

        const data = response.message;

        setData(data);
      })

      .catch((err) => {});
  };

  useEffect(() => {
    getData();
  }, [id]);

  const handleBeforeUnload = (e) => {
    e?.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getMovieTariff = (id) => {
    REQUESTS.VIDEO.GET_TARIFF(id).then((data) => {
      const selecteds = data?.message?.map((item) => item.id);

      form.setFieldsValue({
        tariffIds: selecteds,
      });
    });
  };

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue({
      // pg: data.pg,
      name: data.name,
      content_monetization_type: data.content_monetization_type,
      description: data.description,
      pricing_id: data.pricing_id,
      is_location_restriction: data.is_location_restriction || false,
    });

    if (data.available_countries) {
      const countries = data.available_countries.split(",");

      const defaultCountries = countriesOptions.filter((item) =>
        countries.includes(item.name)
      );

      form.setFieldsValue({
        available_countries: defaultCountries.map((item) => item.id),
      });
    }

    if (data.genres) {
      const genres = data.genres.map((item) => item.id);

      const defaultGenres = genresOptions.filter((item) => genres.includes(item.id));

      form.setFieldsValue({
        genres: defaultGenres.map((item) => item.id),
      });
    }

    if (data.collections) {
      const collections = data.collections.map((item) => item.id);

      const defaultCollections = collectionsOptions.filter((item) =>
        collections.includes(item.id)
      );

      form.setFieldsValue({
        collections: defaultCollections.map((item) => item.id),
      });
    }

    getMovieTariff(data.id);
  }, [data]);

  const onChangeVideo = (file) => {
    const { name } = form.getFieldsValue();

    setVideoData({
      type: "file",
      url: file.url,
    });

    const body = {
      name: name,
      company_id: profile?.company_id,
      type: "video",
      input: file.url,
      input_type: "file",
    };
    REQUESTS.VIDEO.UPDATE_VIDEO(body)
      .then((data) => {
        message.success("Video edited successfully");
        getData();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onChangeTrailer = (file) => {
    const { name } = form.getFieldsValue();
    setTrailerData({
      type: "file",
      url: file.url,
    });

    const body = {
      name: name,
      company_id: profile?.company_id,
      type: "trailer",
      input: file.url,
      input_type: "file",
    };
    REQUESTS.VIDEO.UPDATE_VIDEO(body)
      .then((data) => {
        message.success("Trailer edited successfully");

        getData();
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const renderVideoTrailer = () => {
    if (data?.trailer_transcoding_id) {
      switch (data?.trailer_transcoding_status) {
        case 0:
          return (
            <Tag icon={<SyncOutlined spin />} color="processing">
              Your content is in the process of being created
            </Tag>
          );

        case 1:
          return (
            <Tag color="red">
              Problem with processing your content. Please upload new video
            </Tag>
          );

        case 2:
          return data?.trailer_url ? (
            <ModalComponent
              open={openPlayerModal.trailer}
              title={"Watch Trailer"}
              close={() =>
                setOpenPlayerModal({
                  ...openPlayerModal,
                  trailer: false,
                })
              }
            >
              <Player
                url={`${data?.trailer_url}?token=${getToken()}`}
                autoplay={false}
                width="100%"
              />
            </ModalComponent>
          ) : (
            ""
          );

        default:
          return null;
      }
    }
  };

  const renderVideo = () => {
    if (data?.transcoding_id) {
      switch (data?.transcoding_status) {
        case 0:
          return (
            <Tag icon={<SyncOutlined spin />} color="processing">
              Your content is in the process of being created
            </Tag>
          );

        case 1:
          return (
            <Tag color="red">
              Problem with processing your content. Please upload new video
            </Tag>
          );

        case 2:
          return data?.stream_url ? (
            <ModalComponent
              title={"Watch Video"}
              open={openPlayerModal.video}
              close={() => setOpenPlayerModal({ ...openPlayerModal, video: false })}
            >
              <Player url={`${data?.stream_url}?token=${getToken()}`} autoplay={false} />
            </ModalComponent>
          ) : (
            ""
          );

        default:
          return null;
      }
    }
  };

  const handleVideoChange = (data) => {
    setUrlImport(data);
  };

  const sendUrl = (url) => {
    const body = {
      name: data?.name,
      company_id: profile?.company_id,
      content_id: data?.id,
      type: openModal.trailer ? "trailer" : "video",
      input: url,
    };

    REQUESTS.VIDEO.TRANSCODER_EDIT(body)
      .then((data) => {
        message.success(
          ` ${openModal.trailer ? "Trailer" : "Video"} has edited successfully`
        );
        getData();
        setOpenModal({
          ...openModal,
          trailer: false,
          video: false,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const videoTab = [
    {
      label: "Choose video",
      key: "choose-video",
      children: (
        <VideoTranscode
          onChange={onChangeVideo}
          defaultUrl={data?.stream_url}
          transcodingStatus={data?.transcoding_status}
          transcodingId={data?.transcoding_id}
        />
      ),
    },
    {
      label: "Import URL",
      key: "import-url",
      children: <UrlImport onChange={(file) => handleVideoChange(file)} />,
    },
  ];

  const trailerTab = [
    {
      label: "Choose video",
      key: "choose-video",
      children: (
        <>
          <VideoTranscode
            onChange={onChangeTrailer}
            defaultUrl={data?.trailer_url}
            transcodingStatus={data?.trailer_transcoding_status}
            transcodingId={data?.trailer_transcoding_id}
          />
        </>
      ),
    },
    {
      label: "Import URL",
      key: "edit-import-url",
      children: <UrlImport onChange={(file) => handleVideoChange(file)} />,
    },
  ];

  return (
    <PageComponent routes={["Content", "Edit video"]} backPath={PATHS.VIDEOS}>
      <Form
        form={form}
        name="new-video"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          // pg: 18,
          content_monetization_type: "free",
          is_location_restriction: false,
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
      >
        <div className={styles["body"]}>
          <Container>
            <About form={form} />

            <Form.Item label="Tags" name="genres">
              <Select
                mode="multiple"
                showSearch={true}
                options={genresOptions}
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item label="Collections" name="collections">
              <Select
                showSearch={true}
                mode="multiple"
                options={collectionsOptions}
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>

            <Restrictions form={form} countriesOptions={countriesOptions} />

            <Monetization form={form} />
          </Container>

          <Images form={form} data={data} />

          <Container title="Videos">
            <ButtonComponent
              title={"replace "}
              style={{ width: "100%" }}
              // type="secondary"
              onClick={() =>
                setOpenModal({
                  ...openModal,
                  video: true,
                  trailer: false,
                })
              }
            />
            <ButtonComponent
              title={"Play Video"}
              style={{ width: "50%", marginTop: 10 }}
              onClick={() => setOpenPlayerModal({ ...openPlayerModal, video: true })}
            />

            <div style={{ marginTop: 20 }}>{renderVideo()}</div>
          </Container>

          <Container title="Trailer">
            <ButtonComponent
              title={"replace"}
              style={{ width: "100%" }}
              onClick={() =>
                setOpenModal({
                  ...openModal,
                  trailer: true,
                  video: false,
                })
              }
            />
            <ButtonComponent
              title={"Play Trailer"}
              style={{ width: "50%", marginTop: 10 }}
              onClick={() => setOpenPlayerModal({ ...openPlayerModal, trailer: true })}
            />

            <div style={{ marginTop: 20, padding: 20 }}>{renderVideoTrailer()}</div>
          </Container>
        </div>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title="Save"
            actionType="save"
            isLoading={isLoading}
            style={{ width: 200, marginTop: 30 }}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
      <ModalComponent
        open={openModal.video || openModal.trailer}
        close={() => setOpenModal({ ...openModal, video: false, trailer: false })}
        title={openModal.video ? "Upload Video" : "Upload Trailer"}
        finish={() => {
          sendUrl(urlImport?.url);
          setOpenModal({ ...openModal, video: false, trailer: false });
        }}
      >
        <Tabs items={openModal.video ? videoTab : trailerTab} />
      </ModalComponent>
    </PageComponent>
  );
}
