const IMAGES = {
  LOGO: require("../img/logo.png"),
  FILE: require("../img/file.png"),
  FOLDER: require("../img/folder.png"),
  OPEN_FOLDER: require("../img/open-folder.png"),
  USER: require("../img/user.png"),
  CHECK: require("../img/check.png")
};

export default IMAGES;
