import { Table } from "antd";

export default function TableComponent({
  columns,
  dataSource,
  onChange,
  isLoading,
  pagination,
  rowKey = "id",
  header,
  ...props
}) {
  const handleTableChange = (pagination, filters, sorter) => {
    let data = {
      search: filters,
      page: pagination.current,
      limit: pagination.pageSize,
    };

    if (sorter.field) {
      data.sort = [sorter?.field, sorter?.order === "ascend" ? "ASC" : "DESC"];
    } else {
      data.sort = ["id", "DESC"];
    }

    onChange(data);
  };

  return (
    <div>
      {header && (
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: 15,
          }}
        >
          {header}
        </div>
      )}

      <Table
        rowKey={rowKey}
        size="small"
        bordered
        columns={columns}
        dataSource={dataSource}
        onChange={handleTableChange}
        loading={isLoading}
        pagination={
          pagination
            ? {
                position: ["bottomCenter"],
                showSizeChanger: true,
                current: pagination.page || 1,
                pageSize: pagination.limit || 10,
                ...pagination,
              }
            : false
        }
        {...props}
      />
    </div>
  );
}
