import Icon from "@ant-design/icons";
import ICONS from "../../config/icons";

import PATHS from "../../config/paths";
import MessagesMenu from "./MessagesMenu";

const pages = [
  {
    label: "Welcome",
    key: PATHS.WELCOME,
    icon: <Icon component={() => ICONS.WELCOME} />
  },

  {
    label: "Analytics",
    key: PATHS.ANALYTICS,
    icon: <Icon component={() => ICONS.DASHBOARD} />
  },

  {
    label: "Contents",
    key: "vod-manager",
    icon: <Icon component={() => ICONS.VOD} />,
    children: [
      {
        key: PATHS.VIDEOS,
        label: "Videos"
      },

      {
        key: PATHS.TAGS,
        label: "Tags"
      },

      {
        key: PATHS.CAST,
        label: "Cast & authors"
      },

      {
        key: PATHS.COLLECTION,
        label: "Collections"
      },

      {
        key: PATHS.CONTENT_PRIVACY,
        label: "Content Privacy"
      }
    ]
  },

  {
    label: <MessagesMenu />,
    key: PATHS.CHAT,
    icon: <Icon component={() => ICONS.INSTAGRAM} />
  },

  {
    label: "People",
    key: "users",
    icon: <Icon component={() => ICONS.USERS} />,

    children: [
      {
        key: PATHS.USERS_GROUP,
        label: "Groups"
      },
      {
        key: PATHS.USERS,
        label: "People"
      }
    ]
  },

  {
    label: "Monetization",
    key: "payments",
    icon: <Icon component={() => ICONS.PAYMENTS} />,

    children: [
      {
        key: PATHS.TVOD_PAYMENT,
        label: "TVOD payment"
      },

      {
        label: "AVOD",
        key: PATHS.ADS
      },

      {
        key: PATHS.PAYMENT_SUBSCRIPTION,
        label: "SVOD"
      },

      {
        key: PATHS.PAYMENT_CONFIGS,
        label: "Setup payments"
      },

      {
        key: PATHS.PAYMENT_HISTORY,
        label: "History"
      }
    ]
  },

  {
    label: "Web Page",
    key: "web-page",
    icon: <Icon component={() => ICONS.WEB} />,

    children: [
      {
        key: PATHS.WEB_SETTINGS,
        label: "Settings"
      },
      {
        key: PATHS.WEB_NEWS,
        label: "News"
      },

      {
        key: PATHS.WEB_FAQ,
        label: "FAQ"
      },

      {
        key: PATHS.WEB_FEATURE,
        label: "Feature"
      },

      {
        key: PATHS.WEB_SLIDER,
        label: "Slider"
      },

      {
        key: PATHS.WEB_AVAILABLE_DEVICES,
        label: "Available devices"
      },
      {
        key: PATHS.DOCUMENTS,
        label: "Documents"
      }
    ]
  },

  {
    label: "Application",
    key: "application",
    icon: <Icon component={() => ICONS.APPLICATION_SETTINGS} />,

    children: [
      {
        key: PATHS.APPLICATION_BASIC,
        label: "Branding"
      },

      {
        key: PATHS.APPLICATION_LANGUAGES,
        label: "Languages"
      },

      {
        label: "More apps",
        key: PATHS.MORE_APPS
      },

      {
        key: PATHS.STORE_LINKS,
        label: "Store Links"
      }
    ]
  }
];

export default pages;
