import { useEffect, useState } from "react";
import { Button, message } from "antd";

import REQUESTS from "../../../api/requests";

import PageComponent from "../../../_components/page/PageComponent";

import styles from "./more_apps_page.module.scss";

export default function MoreAppsPage() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    REQUESTS.MORE_APPS.GET().then((res) => {
      if (res.message) {
        setData(res.message);
      }
    });
  };

  const onClick = (id) => {
    REQUESTS.MORE_APPS.CONTACT(id).then((res) => {
      if (res.error) {
        message.error(res.message);

        return;
      }

      message.success(
        "Your request has been sent, we will contact you soon, thank you!"
      );
    });
  };

  return (
    <PageComponent routes={["Application", "More apps"]}>
      <div className={styles["more-apps-page"]}>
        <h6>Contact us for all available platforms</h6>

        <div className={styles["list"]}>
          {data.map((item) => {
            return (
              <div className={styles["block"]}>
                <div className={styles["icon"]}>
                  <img src={item.icon} alt={item.name} />
                </div>
                <p>{item.name}</p>

                <Button type="primary" onClick={() => onClick(item.id)}>
                  Contact Us
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </PageComponent>
  );
}
